import React, { useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles, Button } from "@material-ui/core";
import Header from "../../../components/header/Header";
import ProfileInput from "../../../components/ProfileInput";
import CustomButton from "../../../components/CustomButton";
import Footer from "../../../components/Footer";
import { Get_API, Put_API } from "../../../services/ApiServies";
import { Res } from "../../../resources";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_DATA } from "../../../store/actionTypes";
import baseContent from "../../../services/Content";
import {
  emailValidator,
  lastNameValidator,
  nameValidator,
  phoneValidator,
} from "../../../services/Validators";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../services/CommonLogic";
import { Link as RouterLink } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  heading: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  loginImg: {
    maxWidth: 150,
    paddingBottom: theme.spacing(2),
  },
  image: {
    width: 200,
    height: 200,
  },

  inputBox: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.customGrey.light,
    marginBottom: theme.spacing(2),
    height: 60,
  },
  PhoneInput: {
    borderColor: "white",
  },
}));

function ContactInfo() {
  const styles = makeStyle();
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [name, setName] = useState(
    global_data.profile.fname + " " + global_data.profile.lname
  );
  const [additionalEmail, setAdditionalEmail] = useState(
    global_data.profile.email
  );
  const [phone, setPhone] = useState(
    "+" + global_data.profile.callingCode + global_data.profile.mobile
  );

  const [showResults, setShowResults] = React.useState(false);
  const [changeMade, setChangeMade] = React.useState(false);
  const onClick = () => {
    setShowResults(true);
    setChangeMade(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [phone]);

  const onClickCancel = () => {
    setAdditionalEmail(global_data.profile.email);
    setName(global_data.profile.fname + " " + global_data.profile.lname);
    setPhone(
      "+" + global_data.profile.callingCode + global_data.profile.mobile
    );
    setShowResults(false);
    setChangeMade(false);
  };
  const saveProfile = () => {
    const fname = name.trim().split(" ")[0];
    const lname = name.trim().split(" ")[1] ? name.split(" ")[1] : "";
    if (changeMade && nameValidator(fname) && lastNameValidator(lname)) {
      let dataToPut = {
        email: additionalEmail,
        fname: fname,
        lname: lname,
        userType: "Parent",
      };
      if (phone) {
        if (!phoneValidator(phone)) return;
        dataToPut = { ...dataToPut, mobile: phone.substring(3) };

        let parsedNum = parsePhoneNumber(phone);
        if (parsedNum.country) {
          dataToPut = { ...dataToPut, countryCode: parsedNum.country };
        }
        if (parsedNum.countryCallingCode) {
          dataToPut = {
            ...dataToPut,
            callingCode: parsedNum.countryCallingCode,
          };
        }
      }
      if (additionalEmail) {
        if (!emailValidator(additionalEmail)) return;

        dataToPut = { ...dataToPut, email: additionalEmail };
      }
      if (additionalEmail === global_data.email) {
        CustomizeToastError(
          "Signup email and additional email can not be same. ",
          Res.variables.toastTimerDelay
        );
        return;
      }
      Put_API(
        Res.apiEndPoints.profile + "/" + global_data.profile.profileId,
        dataToPut,
        updateProfileSuccess,
        true
      );
    }

    function updateProfileSuccess(response) {
      setShowResults(false);
      if (response.data.success) {
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            profile: response.data.object,
          },
        });
        CustomizeToast(response.data.message, Res.variables.toastTimerDelay);
      } else {
        CustomizeToastError(
          response.data.message,
          Res.variables.toastTimerDelay
        );
      }
    }
  };
  useEffect(() => {
    const profileSuccess = (response) => {
      if (response.data.success) {
        var responseObject = response.data.object;
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            profile: responseObject,
          },
        });
        setName(responseObject.fname + " " + responseObject.lname);
        setAdditionalEmail(responseObject.email);
        setPhone("+" + responseObject.callingCode + responseObject.mobile);
      }
    };
    Get_API(
      Res.apiEndPoints.profileOwner + "/" + global_data.id,
      null,
      profileSuccess,
      true
    );
  }, [global_data.id, dispatch]);

  return (
    <div>
      <Header />
      <Container className={styles.root} lg>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="relative"
        >
          <Box
            pb={8}
            fontWeight="fontWeightBold"
            textAlign="center"
            color="secondary.main"
            className={styles.heading}
          >
            {baseContent.pageText.contactInfo.topHeading}
          </Box>
          <Box
            flex={2}
            pb={3}
            textAlign="right"
            position="absolute"
            top={{ xs: 40, sm: 10 }}
            right="10%"
          >
            {showResults ? null : <Button onClick={onClick}>Edit</Button>}
          </Box>
          <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                Name *
              </Box>
              <Box>
                {showResults ? (
                  <ProfileInput
                    inputIcon="email"
                    inputId="edit_username"
                    requi
                    red="true"
                    inputType="email"
                    placeholder="your name"
                    containerStyle={{ marginBottom: 0 }}
                    value={name}
                    onChange={(event) => {
                      setChangeMade(true);
                      if (
                        /^[a-z ,.'-]+$/i.test(event.target.value) ||
                        !event.target.value
                      ) {
                        setName(event.target.value);
                      }
                    }}
                  />
                ) : (
                  <Box pb={2} pt={0.6}>
                    {name}
                  </Box>
                )}
              </Box>
            </Box>

            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                Current email *
              </Box>
              {showResults ? (
                <ProfileInput
                  inputIcon="email"
                  inputId="edit_email"
                  inputType="email"
                  placeholder="example@gmail.com"
                  value={global_data.email}
                  disabled
                />
              ) : (
                <Box pb={2} pt={0.6}>
                  {global_data.email}
                </Box>
              )}
            </Box>
            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                Additional Recipient
              </Box>
              {showResults ? (
                <ProfileInput
                  inputIcon="email"
                  inputId="edit_alt_email"
                  inputType="email"
                  placeholder="example@gmail.com"
                  value={additionalEmail}
                  onChange={(event) => {
                    setChangeMade(true);
                    setAdditionalEmail(event.target.value);
                  }}
                />
              ) : (
                <Box pb={2} pt={0.6}>
                  {additionalEmail}
                </Box>
              )}
            </Box>
            {showResults ? (
              <Box>
                {/* <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                  Country
                </Box> */}
                {/* <CountrySelect
                  onChange={(e, value) => {
                    console.log(value);
                  }}
                /> */}
              </Box>
            ) : null}
            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                Phone Number
              </Box>
              {showResults ? (
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(event, value) => {
                    console.log(event);
                    setPhone(event);
                    setChangeMade(true);
                  }}
                  style={{ border: "none", outline: "none" }}
                />
              ) : (
                <Box pb={2} pt={0.6}>
                  {phone}
                </Box>
              )}
            </Box>
            {showResults ? (
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Box mt={2} mr={2} width="50%">
                  <CustomButton
                    buttonType="contained"
                    buttonColor="secondary"
                    buttonText="Cancel"
                    customStyle={{
                      borderRadius: 40,
                      width: "100%",
                      background: "white",
                      color: "rgb(1, 100, 112)",
                      fontWeight: "800",
                    }}
                    clickHandler={onClickCancel}
                  />
                </Box>
                <Box mt={2} width="50%">
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    buttonText="Save"
                    customStyle={{ width: "100%", borderRadius: 40 }}
                    clickHandler={saveProfile}
                  />
                </Box>
              </Box>
            ) : (
              <Box mt={2} width="50%" style={{ margin: "auto" }}>
                <RouterLink to="/profile" style={{ textDecoration: "none" }}>
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    buttonText="Go Back"
                    customStyle={{ width: "100%", borderRadius: 40 }}
                  />
                </RouterLink>
              </Box>
            )}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default ContactInfo;
