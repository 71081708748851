export const images = {
  logoImage: "/images/lg.png",
  userImage: "/images/user.png",
  appImage: "/images/journalApp.png",
  loginImage: "/images/log.png",
  signupImage: "/images/reg.png",
  whoWeAre: "/images/whoweareimage.svg",
  coachingImg: "/images/coaching.svg",
  inValidImg: "/images/invalid.svg",
  congImg: "/images/congo.png",
  congrats: "/images/congratulations.png",
  webinarImage: "/images/webinar.jpg",
  arrImg: "/images/Arrow.png",
  authCourses: "/images/Courses1.png",
  courseBadge: "/images/course_badge.png",
  authPodcasts: "/images/Podcasts1.png",
  authResources: "/images/QuickReads1.png",
  authClasses: "/images/resources.png",
  home: "/images/homee.png",
  coursesLogo: "/images/courseLogo.png",
  quickLogo: "/images/quickReadsLogo.png",
  podcastsLogo: "/images/podcastsLogo.png",
  journalLogo: "/images/journalLogo.png",
  communityMain: "/images/communityMain.png",
  left: "/images/Left.png",
  discover: "/images/discover.png",
  visa: "/images/visa.png",
  mastercard: "/images/mastercard.png",
  amExpress: "/images/amexpress.png",
  stripe: "/images/stripe.png",
};
