import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Header from "../../components/header/Header";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import Footer from "../../components/Footer";
import { useHistory, useLocation } from "react-router-dom";
import { Post_API } from "../../services/ApiServies";
import { Res } from "../../resources";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_DATA, GLOBAL_DATA } from "../../store/actionTypes";
import jwt_decode from "jwt-decode";

import {
  CustomizeToast,
  CustomizeToastError,
} from "../../services/CommonLogic";
import { passwordValidator } from "../../services/Validators";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    minHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  loginImg: {
    maxWidth: 150,
    paddingBottom: theme.spacing(2),
  },
}));

function SetPassword() {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [otp, setOtp] = useState("");

  const clickHandler = () => {
    if (password.trim() !== verifyPassword.trim()) {
      CustomizeToastError(
        "Passwords did not match",
        Res.variables.toastTimerDelay
      );
    } else if (otp.length < 6) {
      CustomizeToastError(
        "Security code must be 6 digit long",
        Res.variables.toastTimerDelay
      );
    } else if (passwordValidator(password)) {
      Post_API(
        Res.apiEndPoints.verify,
        {
          email: global_data.email,
          userid: global_data.id,
          password: password,
          secode: otp,
        },
        setPasswordSuccess,
        false
      );
    }
  };
  const setPasswordSuccess = (response) => {
    if (response.data.status) {
      CustomizeToast(
        response.data.msg + " You are being redirected to the Login page.",
        () => {
          history.push("/login", { toDashboard: true });
        },
        Res.variables.toastTimerDelay
      );
    } else {
      CustomizeToastError(response.data.msg);
    }
  };

  return (
    <div>
      <Header />
      <Container className={styles.root} lg>
        <Box
          display="flex"
          pb={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
            <Box
              pb={5}
              fontWeight="fontWeightBold"
              textAlign="center"
              fontSize="h4.fontSize"
              color="secondary.main"
            >
              Set Password
            </Box>
            <Box pb={5} textAlign="center" fontSize="h6.fontSize">
              Please enter the security code as received in your registered
              email:
              <br />
              <Box pt={1}>{global_data.email}</Box>
            </Box>
            <form id="set_password" onSubmit={(e) => e.preventDefault()}>
              <Box mb={3} style={{ display: "flex", justifyContent: "center" }}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    borderColor: "#CEDBEA",
                    borderWidth: 1,
                    borderRadius: 2,
                  }}
                  autocomplete="off"
                  shouldAutoFocus
                  form="set_password"
                  isInputNum="true"
                  separator={<span>-</span>}
                />
              </Box>
              <CustomInput
                inputIcon="lock"
                inputId="password"
                inputLabe=" New Password"
                inputType="password"
                autoFocus={true}
                form="set_password"
                placeholder="***************"
                value={password}
                autocomplete="off"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />

              <CustomInput
                inputIcon="lock"
                inputId="password"
                inputLabe="Confirm Password"
                inputType="password"
                form="set_password"
                placeholder="***************"
                value={verifyPassword}
                onChange={(event) => {
                  setVerifyPassword(event.target.value);
                }}
              />

              <Box mx={3} mt={4}>
                <CustomButton
                  buttonType="contained"
                  buttonColor="primary"
                  buttonText="Done"
                  type="submit"
                  form="set_password"
                  customStyle={{ borderRadius: 40 }}
                  clickHandler={clickHandler}
                />
              </Box>
            </form>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default SetPassword;
