import {
  Box,
  Button,
  Card,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import h2p from "html2plaintext";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import QueryModal from "../../../components/QueryModal";
import "../contenthub/components/carousel.css";
import useAuthoring from "../contenthub/hooks/useAuthoring";
import "./extra.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const makeStyle = makeStyles((theme) => ({
  borderclass: {
    overflow: "hidden",
  },
  root: {
    flexGrow: 1,
    minHeight: 460,
    borderRadius: theme.spacing(1),
    backgroundColor: "#F3F3F3",
    position: "relative",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  itemLogoImg: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
  gridItemBox: {
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  bookedTagBox: {
    alignSelf: "flex-end",
    // border:"1px solid gray",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    minWidth: 80,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#1297A7",
    boxShadow: theme.shadows[1],
  },
  btnStyle: {
    border: "1px solid #ADADAD",
    color: "#1297A7",
    borderRadius: 14,
    fontSize: 14,
    marginTop: 8,
  },
  link: { color: "#1297A7", textDecoration: "none" },
  itemBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    padding: theme.spacing(2),
  },
  headingText: {
    height: "3em",
    fontSize: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    fontWeight: 600,
  },
  maintxt: {
    alignSelf: "flex-start",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    height: 135,
  },
  boldText: {
    padding: theme.spacing(0.5),
    fontWeight: 600,
    alignSelf: "flex-start",
  },
  boldTextBig: {
    padding: theme.spacing(0.5),
    alignSelf: "flex-start",
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
  },
}));

const CustomCarouselClasses = (props) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [carouselCount, setCarouselCount] = useState(2);
  const { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal } =
    useAuthoring();

  useEffect(() => {
    function resize() {
      console.log("Window width****", window.innerWidth, carouselCount);
      if (window.innerWidth <= 620) {
        setCarouselCount(1);
      } else if (window.innerWidth <= 960) {
        setCarouselCount(2);
      } else if (window.innerWidth > 960) {
        setCarouselCount(3);
      }
    }

    if (window.innerWidth <= 620) {
      setCarouselCount(1);
    } else if (window.innerWidth <= 960) {
      setCarouselCount(2);
    } else if (window.innerWidth > 960) {
      setCarouselCount(3);
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [carouselCount]);

  return (
    <Box className={styles.borderclass}>
      <Box>
        <QueryModal
          modalState={deleteModalFlag}
          queryText="Are you sure you want to delete this ?"
          yesText="Yes"
          closeText="No"
          onYes={() => {
            deleteContent();
          }}
          onClose={() => {
            closeDeleteModal();
          }}
        />
        <Carousel
          responsive={responsive}
          speed={500}
          slidesToShow={carouselCount}
          slidesToScroll={1}
          infinite={true}
        >
          {props?.carouselItems?.map((item, i) => {
            console.log("carouselItems", item);
            return (
              <Box p={1} key={`Carousal_classes_${i}`}>
                <Card className={styles.root}>
                  <Box className={styles.itemBox}>
                    <Box style={{ height: "2rem", alignSelf: "flex-end" }}>
                      {item?.status > 0 && (
                        <Box className={styles.bookedTagBox}>
                          {item?.state > 0 ? "Booked" : ""}
                        </Box>
                      )}
                    </Box>
                    <Box className={styles.buttonContainer}>
                      <img src={item?.image} className={styles.itemLogoImg} />
                      {global_data.isEditable && (
                        <>
                          <IconButton
                            onClick={() => {
                              history.push(
                                `/update/class/${item?.contentId}/${item?.id}`
                              );
                            }}
                          >
                            <CreateIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              openDeleteModal(item);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Box>
                    <Typography className={styles.headingText} variant="h5">
                      {item?.heading?.length < 20
                        ? item?.heading
                        : item?.heading?.slice(0, 20) + "..."}
                    </Typography>
                    <Typography component="p" className={styles.maintxt}>
                      {h2p(item?.description)?.length > 101
                        ? h2p(item?.description)?.slice(0, 100) + "..."
                        : h2p(item?.description)}
                    </Typography>
                    <Typography variant="h6" className={styles.boldText}>
                      {item?.localWebinarDate}
                    </Typography>
                    <Typography variant="h6" className={styles.boldText}>
                      {item?.localWebinarTime}
                    </Typography>
                    <Typography variant="h6" className={styles.boldTextBig}>
                      {item?.price && item?.price !== "0"
                        ? `₤${item?.price}`
                        : "Free"}
                    </Typography>
                    <Button variant="outlined" className={styles.btnStyle}>
                      <Link className={styles.link} to={`/classes/${item?.id}`}>
                        Read More
                      </Link>
                    </Button>
                  </Box>
                </Card>
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
};

export default CustomCarouselClasses;
