import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";

const makeStyle = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 30,
  },
  bookingHeading: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    marginBottom: theme.spacing(1.25),
  },
  bannerContainer: {
    backgroundColor: theme.palette.primary.main1,
    [theme.breakpoints.down("sm")]: {
      marginTop: 35,
    },
  },
  bannerBox: {
    maxWidth: 1225,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBlock: theme.spacing(10),
    display: "flex",
    [theme.breakpoints.down("md")]: {
      paddingInline: theme.spacing(7),
    },
    [theme.breakpoints.down("sm")]: {
      paddingInline: theme.spacing(4),
      fontSize: 30,
    },
    [theme.breakpoints.down("xs")]: {
      paddingBlock: theme.spacing(6),
    },
  },
  bannerDetailTitle: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    fontWeight: theme.typography.fontWeightDarkBold,
    color: theme.palette.paper,
    fontSize: "39px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3),
      fontSize: 40,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      fontSize: 30,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3.5),
      textAlign: "center",
    },
  },
  bannerDetailTitle1: {
    fontWeight: "400",
    marginTop: 15,
    marginBottom: 15,
    color: theme.palette.paper,
    fontSize: "21px",
    lineHeight: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      paddingTop: theme.spacing(3.5),
      textAlign: "left",
    },
  },
  bannerDetailTitle2: {
    fontWeight: "400",
    marginTop: 20,
    marginBottom: 10,
    color: theme.palette.paper,
    fontSize: "22px",
    lineHeight: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3.5),
      textAlign: "center",
    },
  },
  bannerImage: {
    // maxWidth: 600,
    maxHeight: 344,
    marginTop: "-10rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3),
      maxWidth: 250,
    },
  },
  imageBox: {
    order: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      order: 1,
    },
  },
  bannerDetail: {
    order: 1,
    paddingLeft: theme.spacing(6),
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      order: 2,
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: theme.spacing(0),
    },
  },
  bannerBorder: {
    content: "",
    backgroundColor: theme.palette.paper,
    height: 10,
    width: "60%",
    display: "block",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      height: 6,
    },
  },
  buttonBox: {
    width: "40%",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
}));

const HomeBanner = ({ localHomeImageArticle, bannerId }) => {
  const styles = makeStyle();
  const clickHandler = () => {};
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);

  return (
    <Box className={styles.bannerContainer}>
      <Box className={styles.buttonContainer}>
        {global_data.isEditable && (
          <IconButton
            onClick={() => {
              history.push(`/update/pageItem/homeimagearticle/${bannerId}`);
            }}
          >
            <CreateIcon />
          </IconButton>
        )}
      </Box>
      <Grid container className={styles.bannerBox} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            className={styles.bannerDetailTitle}
            component="h3"
          >
            {localHomeImageArticle?.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} lg={6} className={styles.bannerDetail}>
          <Typography
            variant="h5"
            className={styles.bannerDetailTitle2}
            component="h5"
            dangerouslySetInnerHTML={{
              __html: localHomeImageArticle?.description,
            }}
          />

          <Box className={styles.buttonBox}>
            <RouterLink to="/signup" style={{ textDecoration: "none" }}>
              <CustomButton
                buttonColor="primary"
                buttonText="FREE sign up"
                customStyle={{
                  borderRadius: 40,
                  color: "#fff",
                  borderColor: "#fff",
                }}
                clickHandler={clickHandler}
                buttonType="outlined"
              />
            </RouterLink>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} lg={6} className={styles.imageBox}>
          <img
            className={styles.bannerImage}
            src={localHomeImageArticle?.image}
            width="100%"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeBanner;
