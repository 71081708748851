import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import HomeNoAuth from "../../../components/HomeNoAuth";
import PageBottomSection from "../../../components/PageBottomSection";
import { Res } from "../../../resources";
import useBottomSections from "../../../resources/localStorage/hooks/useBottomSections";
import useHomePageStorage from "../../../resources/localStorage/hooks/useHomePageStorage";
import { Get_API } from "../../../services/ApiServies";
import baseContent from "../../../services/Content";
import { getLearningsAction } from "../../../store/actions";
import CustomCarouselClasses from "../coaching/CustomCarousalClasses";
import AuthBanner from "./AuthBanner";
import HomeBanner from "./HomeBanner";
import HomeModules from "./HomeModules";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(8.625),
    backgroundColor: theme.palette.paper,
    webkitOverflowScrolling: "touch",
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(8) },
  },

  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  bookingHeading: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    marginBottom: theme.spacing(1.3),
  },

  cardContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
  },
  bookingContainer: {
    paddingBlock: theme.spacing(10),
    marginLeft: "16px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "10%",
    },
  },
  secondContainer: {
    // paddingLeft:theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& > $tabTitle": {
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
  },

  description: {
    [theme.breakpoints.down("xs")]: {
      "& ul li": {
        textAlign: "left !important",
      },
    },
  },
  iframeTitle: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  tabTitle: {
    marginLeft: theme.spacing(5),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      textAlign: "center",
    },
  },
  contentItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hubContent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.brightColor,
    borderRadius: theme.spacing(2.5),
    display: "flex",
    marginBottom: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  hubContentPodcast: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  frameContainer: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.only("sm")]: {
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
    },
  },
  frameStyle: {
    border: "none",
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(2.4),
    padding: theme.spacing(0.6),
    minHeight: 700,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      boxShadow: theme.shadows[0],
      marginRight: theme.spacing(0),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    minHeight: 180,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: " #9A89B5",
    marginBottom: 30,
    borderRadius: 20,
    padding: 4,
    marginTop: 40,
  },
  btnStyle: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#1297A7",
    borderRadius: 8,
    marginTop: 20,
  },
  txtStyle: {
    marginTop: 15,
    fontWeight: "400",
    color: "white",
  },
  txtStyle1: {
    marginTop: 15,
    fontWeight: "600",
    color: "white",
  },
}));

function HomePage() {
  const constantText = baseContent.pageText;
  const global_data = useSelector((state) => state.commonReducer);
  const styles = makeStyle();
  const [loggedIn] = useState(global_data.token ? true : false);
  const history = useHistory();
  const [enableEdit] = React.useState(global_data.isEditable ? true : false);
  const dispatch = useDispatch();
  const [secondColumnHeight, setSecondColumnHeight] = useState(100);
  const [classesState, setClassesState] = React.useState(
    global_data?.authClasses ? global_data?.authClasses : {}
  );
  const { home: homeBottomSection } = useBottomSections();
  const {
    localHomeArticle,
    localHomeImageArticle,
    localAuthArticle,
    localAuthHomeAricle,
    localHomeFeature,
  } = useHomePageStorage();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const iframeSrc =
    "https://forum.myfamilycoach.ml/index.php?wtbToken=" + global_data.wtbToken;

  useEffect(() => {
    if (
      loggedIn &&
      !global_data.isEditable &&
      document.getElementById("homeSecondColumn")
    )
      setSecondColumnHeight(
        document.getElementById("homeSecondColumn").clientHeight - 100
      );
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (global_data.id) {
      //  if logged in get learnings
      const getItemSuccess = (response) => {
        if (response?.data.length) {
          var responseObject = response?.data;
          getLearningsAction(dispatch, responseObject);
        }
      };
      Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
    } else {
      //  if not logged in get just classes
      const getItemSuccess = (response) => {
        if (response?.data?.length) {
          setClassesState({ classes: response.data });
        }
      };
      Get_API(Res.apiEndPoints?.classes, null, getItemSuccess, true);
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />
      {loggedIn ? (
        <>
          <AuthBanner
            bannerData={localAuthHomeAricle}
            bannerImage={
              localHomeImageArticle?.image && localHomeImageArticle?.image
            }
            article={localAuthArticle}
          />
        </>
      ) : (
        <HomeBanner
          bannerId={localHomeImageArticle?.id}
          localHomeImageArticle={localHomeImageArticle}
        />
      )}
      {loggedIn && global_data.isEditable ? (
        //  login and normal user (execpt author) view---------------
        <Box style={{ marginTop: 30 }}>
          <HomeBanner
            bannerId={localHomeImageArticle?.id}
            localHomeImageArticle={localHomeImageArticle}
          />
        </Box>
      ) : null}

      {loggedIn && !global_data.isEditable ? (
        <Container className={styles.root}>
          <Grid container>
            <Grid
              xs={12}
              sm={7}
              md={7}
              lg={7}
              item
              className={styles.frameContainer}
            >
              <Box m={2} mt={4} ml={2.2} className={styles.secondContainer}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={styles.iframeTitle}
                >
                  {constantText.homePage.tabTitle}
                </Typography>
              </Box>
              <iframe
                className={styles.frameStyle}
                title="MyFamilyCoach Community Forum"
                src={iframeSrc}
                width="100%"
                height={secondColumnHeight}
              ></iframe>
            </Grid>
            <Grid xs={12} sm={5} md={5} lg={5} item id="homeSecondColumn">
              <Box m={2} mt={5} className={styles.secondContainer}>
                <Link
                  to="/classes"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    className={styles.tabTitle}
                  >
                    My Classes
                  </Typography>
                </Link>
              </Box>
              <HomeModules contentType="authClasses" />
              <br />
              <Box m={2} mt={5} className={styles.secondContainer}>
                <Link
                  to="/contenthub"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    className={styles.tabTitle}
                  >
                    My Information
                  </Typography>
                </Link>
              </Box>
              <HomeModules contentType="allLearnings" />
              <br />
            </Grid>
          </Grid>
          {loggedIn ? (
            <PageBottomSection
              item={homeBottomSection}
              page="home"
              buttonText="Find out more"
              bgColor="#9A89B5"
            />
          ) : null}
          <Box height={50}></Box>
        </Container>
      ) : (
        // no-login or author views----------------------
        <Container className={styles.root} lg={12}>
          <Box m={2} mt={4} className={styles.secondContainer}>
            {/* ----------- below noAuthBanner article start ----------- */}
            <Box className={styles.buttonContainer}>
              <Typography
                variant="h4"
                component="h4"
                style={{ fontWeight: 600 }}
              >
                {localHomeArticle?.title && localHomeArticle?.title}
              </Typography>
              {global_data.isEditable && (
                <IconButton
                  onClick={() => {
                    history.push(
                      `/update/pageItem/homearticle/${localHomeArticle.id}`
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              )}
            </Box>
            {localHomeArticle?.description ? (
              <Typography
                variant="h6"
                component="p"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: localHomeArticle?.description,
                }}
              />
            ) : null}
            {/* ----------- below noAuthBanner article end ----------- */}

            {/* ----------- purple boxes start ----------- */}
            <HomeNoAuth
              localHomeFeature={localHomeFeature}
              enableEdit={enableEdit}
            />
            <Box height={50} />
            {/* ----------- purple boxes end ----------- */}
          </Box>

          {/* ----------- pagebottom section start ----------- */}
          {loggedIn ? (
            // only when logged in (user/author)
            <PageBottomSection
              item={homeBottomSection}
              page="home"
              buttonText="Find out more"
              bgColor="#9A89B5"
            />
          ) : null}
          {/* ----------- pagebottom section end ----------- */}

          {/* --------------class booking section start---------------- */}
          {!loggedIn &&
          // if there are classes that are not expired only
          // then show the booking section when not logged in
          classesState?.classes?.filter(
            (item) => item.status > -1 || item.status === undefined
          )?.length ? (
            <>
              <Typography
                variant="h4"
                component="h4"
                style={{ paddingBottom: 10, fontWeight: 600 }}
              >
                {homeBottomSection?.heading || "Book a class"}
              </Typography>
              <Typography
                variant="h6"
                component="p"
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html:
                    // homeBottomSection?.description ||
                    "Enjoy our expert-led sessions from the comfort of your sofa",
                }}
              />
              <Box style={{ marginBottom: 60 }}>
                <CustomCarouselClasses
                  carouselTitle="Classes"
                  carouselItems={
                    classesState?.classes?.filter(
                      (item) => item.status > -1 || item.status === undefined
                    ) || []
                  }
                />
              </Box>
            </>
          ) : null}
          {/* --------------class booking section end---------------- */}
        </Container>
      )}

      <Footer />
    </div>
  );
}

export default HomePage;
