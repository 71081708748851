import {
  Box,
  Breadcrumbs,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/header/Header";
import CarouseItem from "../../contenthub/components/ContentItem";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(10.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(16) },
  },
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  headingText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.spacing(1.5),
    },
  },
  navArrow: {
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: theme.spacing(1.5),
    },
  },
  notSubscribedText: {
    padding: theme.spacing(4),
    // boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    fontWeight: 400,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.spacing(1.5),
    },
  },
}));

export default function HomePodcasts() {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [currentContent, setCurrentContent] = React.useState([]);

  React.useEffect(() => {
    const content = global_data?.authPodcasts?.podcasts?.filter((data) => {
      return data.status > 0;
    });
    setCurrentContent(content);
  }, [global_data?.id, global_data?.authPodcasts]);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500, maxWidth: 1130 }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="large" className={styles.navArrow} />
          }
          aria-label="breadcrumb"
        >
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            <Link to="/" style={{ textDecoration: "none", color: "#0D2860" }}>
              Dashboard
            </Link>
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            Podcasts
          </Typography>
        </Breadcrumbs>
        <Box className={styles.borderclass}>
          {currentContent?.length <= 0 ? (
            <Typography
              variant="h6"
              component="h2"
              className={styles.notSubscribedText}
            >
              No Podcasts Subscribed
            </Typography>
          ) : (
            <CarouseItem
              carouselTitle="Podcasts"
              carouselSubTitle={global_data?.authPodcasts?.heading}
              carouselItems={currentContent}
            />
          )}
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
