const baseContent = {
  header: {
    navigation: [
      {
        name: "Dashboard",
        route: "/",
      },
      {
        name: "Meet the Team",
        route: "/whoweare",
      },
      {
        name: "Classes",
        route: "/coaching",
      },
      {
        name: "Information Hub",
        subMenu: [
          {
            name: "Courses",
            route: "/contenthub/courses",
          },
          {
            name: "Quick Reads",
            route: "/contenthub/resources",
          },
          {
            name: "Support Talks",
            route: "/contenthub/talks",
          },
          {
            name: "Podcasts",
            route: "/contenthub/podcasts",
          },
        ],
        route: "/contenthub",
      },
      {
        name: "Journal",
        route: "/journal",
      },
      {
        name: "Community",
        route: "/community",
      },
      {
        name: "Admin",
        route: "/admin",
      },
    ],
    profileNavigation: [
      {
        name: "Profile",
        route: "/profile",
      },
      {
        name: "Billing",
        route: "/whoweare",
      },
      {
        name: "Change Password",
        route: "/forgetpassword",
      },
    ],
  },
  pageText: {
    homePage: {
      bannerDetailHeading: "Straight forward, flexible support for parents",
      bannerImage: "bannerImg.svg",
      bannerImageAlt: "bannerImg",
      bannerSectionText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  dolor sit amet, consectetur adipiscing elit.",
      loggedInHeader: "My Learning",
      coaching: "COACHING",
      loggedInBannerText: "Talk to a behaviour expert today",
      loggedInBannerDetail:
        "Join thousands of other parents learning more about their children",
      tabTitle: "Discussions",
      secondBox: {
        heading: "Second Heading",
        paragraph:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate elementum faucibus accumsan, lobortis elementum.it amet, faucibus accumsan, lobortis elementum.",
      },
      moduleTitle: "Modules",
      bookingHeading: "Book Coaching",
    },
    footer: {
      faq: "FAQs",
      sitemap: "SiteMap",
      termOfBusiness: "Terms of Business",
      privacyPolicy: "Privacy Policy",
      privacyPolicyTrainer: "Privacy Policy for Trainers",
      cookiePolicy: "Cookie Policy",
      copyright: "Copyright @ Team Teach Ltd 2021",
      enquiries: "General Enquiries & Bookings",
      phone: "+44(0) 20 3746 0938",
      email: "info@teamteach.co.uk",
    },
    whoweare: {
      headingtop: "Who We Are",
      headingbottom: "Lorem Lipsum",
      paratop:
        "Founded in 1997 by George Matthews, a teacher with 20 years’ experience, Team Teach is on a mission to equip everyone with the tools they need to understand behaviour and manage challenging situations, at work or in daily life, in a positive and respectful manner. To do this, we provide reasoned, researched and considered positive behavioural management training solutions which are delivered by experienced and knowledgeable trainers and designed to help safely manage the behaviour of children and adults in a variety of educational, health and social care settings. Our training courses are designed for everyone, at any level, in any situation. We want to empower individuals from all walks of life to manage challenging, emotionally-charged interactions in a positive, respectful way",
      parabottom:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.",
      privacyPolicyTrainer: "Privacy Policy for Trainers",
    },
    coaching: {
      headingtop: "Coaching",
      headingbottom: "Starightforward and flexible",
      parabottom:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.",
    },
    journal: {
      headingtop: "Journal",
      headingbottom: "Lorem Ipsum, et massa pretium, dolor amet",
      journalDetail:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim.",
      appDetailHeading: "Get the app",
      appDetailParagraph: "",
    },
    community: {
      headingtop: "Community",
      detail1:
        "My Family Coach is brought to you by experienced behavioural experts who have worked with children with behaviours that challenge for over twenty years. Based on an understanding & belief that all behaviour is a form of communication and the key to better family relationships is understanding how to unlock improved communication.",
      detail2:
        "My Family Coach is part of The Empowering Learning Group, which also comprises Team Teach and Empowering Tutors as well as two teaching recruitmmnt businesses . Team Teach is an award winning Behavioural Training Company with over twenty years of working within the education & health sectors in helping individuals to understand the behaviour of children and adults and how to de-escalate challenging behaviours. From our work within schools we were often approached by parents looking for support and have launched My Family Coach as a service to parents ",
      // filtersTitle: "All Topics",
      // communityFilters: ["filter1", "filter2", "filter3", "filter4"],
    },
    faq: {
      headingtop: "FAQs",
      faqLink: "If you can’t find an answer to your question below, please ",
    },
    contentHub: {
      headingTop: "Content Hub",
      child: [
        { title: "Modules", viewMore: "View More" },
        {
          title: "Resources",
          viewMore: "View More",
          subTitle:
            "We talk to topic experts on there experience with behaviours",
        },
        {
          title: "Podcast",
          viewMore: "View More",
          subTitle: "Listen to expert podcast while you walk the dog",
        },
      ],
      detilPage: {
        buttonText: "Read The transcription",
        noticeText:
          "Your record of events and triggers.Let usprovide you with suggestion and insights into how you can recognize issues before they become crisis",
        taskText: "Complete task",
        taskQuestion:
          "Which system can be reported on with Intro to the profession",
      },
    },
    profile: {
      topHeading: "Profile",
      additionalEmail: "Additional Email",
      support: [
        {
          supportItem: "Phone",
          value: "+44(0) 20 3746 0938",
        },
        {
          supportItem: "Email",
          value: "info@teamteach.co.uk",
        },
      ],
    },
    contactInfo: {
      topHeading: "Contact Information",
    },
  },

  pageDataArray: {
    gridItems: [
      {
        id: "1",
        icon: "/images/icon.svg",
        featureTitle: "Item Title 1",
        featureDescription:
          "Lipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
      {
        id: "2",
        icon: "/images/icon.svg",
        featureTitle: "Item Title 2",
        featureDescription:
          "Lipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
      {
        id: "3",
        icon: "/images/icon.svg",
        featureTitle: "Item Title 3",
        featureDescription:
          "Lipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
      {
        id: "4",
        icon: "/images/icon.svg",
        featureTitle: "Item Title 4",
        featureDescription:
          "Lipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
    ],

    modules: [
      {
        id: "1",
        avatarColor: "#DD2979",
        cardIcon: "phone_in_talk",
        cardTitle: "First Card",
        cardDetail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "2",
        avatarColor: "#9EB934",
        cardIcon: "pie_chart",
        cardTitle: "Second Card",
        cardDetail:
          "Rfrore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "3",
        avatarColor: "#F2A61F",
        cardIcon: "flash_on",
        cardTitle: "Third Card",
        cardDetail:
          "Mnventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
    ],
    customTabContent: {
      firstPanel: [
        {
          id: "1",
          avatarColor: "#DD2979",
          userName: "Rosalin Alex",
          cardIcon: "phone_in_talk",
          cardTitle: "First Card",
          cardDetail:
            "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "2",
          avatarColor: "#9EB934",
          userName: "Rosalin Alex",
          cardIcon: "pie_chart",
          cardTitle: "Second Card",
          cardDetail:
            "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "3",
          avatarColor: "#F2A61F",
          userName: "Rosalin Alex",
          cardIcon: "flash_on",
          cardTitle: "Third Card",
          cardDetail:
            "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "4",
          avatarColor: "#0D2860",
          userName: "Rosalin Alex",
          cardIcon: "phone_in_talk",
          cardTitle: "Fourth Card",
          cardDetail:
            "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
      ],
      secondPanel: [
        {
          id: "1",
          avatarColor: "#DD2979",
          userName: "Alex Jakson",
          cardIcon: "phone_in_talk",
          cardTitle: "First Card",
          cardDetail:
            "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "2",
          avatarColor: "#9EB934",
          userName: "Alex Jakson",
          cardIcon: "pie_chart",
          cardTitle: "Second Card",
          cardDetail:
            "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "3",
          avatarColor: "#F2A61F",
          userName: "Alex Jakson",
          cardIcon: "flash_on",
          cardTitle: "Third Card",
          cardDetail:
            "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "4",
          avatarColor: "#0D2860",
          userName: "Alex Jakson",
          cardIcon: "phone_in_talk",
          cardTitle: "Fourth Card",
          cardDetail:
            "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
      ],
      thirdPanel: [
        {
          id: "1",
          avatarColor: "#DD2979",
          userName: "Boris Johnson",
          cardIcon: "phone_in_talk",
          cardTitle: "First Card",
          cardDetail:
            "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "2",
          avatarColor: "#9EB934",
          userName: "Boris Johnson",
          cardIcon: "pie_chart",
          cardTitle: "Second Card",
          cardDetail:
            "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "3",
          avatarColor: "#F2A61F",
          userName: "Boris Johnson",
          cardIcon: "flash_on",
          cardTitle: "Third Card",
          cardDetail:
            "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
        {
          id: "4",
          avatarColor: "#0D2860",
          userName: "Boris Johnson",
          cardIcon: "phone_in_talk",
          cardTitle: "Fourth Card",
          cardDetail:
            "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        },
      ],
    },
    cardItems: [
      {
        id: "1",
        avatarColor: "#DD2979",
        cardIcon: "phone_in_talk",
        cardTitle: "First Card",
        cardDetail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "2",
        avatarColor: "#9EB934",
        cardIcon: "pie_chart",
        cardTitle: "Second Card",
        cardDetail:
          "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "3",
        avatarColor: "#F2A61F",
        cardIcon: "flash_on",
        cardTitle: "Third Card",
        cardDetail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "4",
        avatarColor: "#0D2860",
        cardIcon: "phone_in_talk",
        cardTitle: "Fourth Card",
        cardDetail:
          "Maroius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
    ],

    bookingItems: [
      {
        id: "1",
        tickColor: "#C2C2C2",
        price: "200",
        cardTitle: "First Card",
        featureList: [
          "Eradius nihil",
          "inventore sint expedita",
          "a quo eveniet aspernatur",
          "excepturi aut maiores",
          "laudantium rerum nesciunt",
        ],
      },
      {
        id: "2",
        tickColor: "#F2A61F",
        price: "420",
        cardTitle: "First Card",
        featureList: [
          "Inventore sint expedita",
          "Excepturi aut maiores",
          "Laudantium rerum nesciunt",
          "Morquo eveniet aspernatur",
          "Excepturi aut maiores",
        ],
      },
      {
        id: "3",
        tickColor: "#0290A1",
        price: "360",
        cardTitle: "First Card",
        featureList: [
          "Morquo eveniet aspernatur",
          "Excepturi aut maiores",
          "Laudantium rerum nesciunt",
        ],
      },
    ],
    contentHub: [
      {
        id: "1",
        image: "/images/bro.png",
        color: "#DDE2AD",
        cardTitle: "Resources",
      },
      {
        id: "1",
        image: "/images/podcastt.svg",
        color: "#BFA8CB",
        cardTitle: "Podcast",
      },
    ],
  },
  faq: {
    faqItems: [
      {
        id: "1",
        cardTitle: "First Card",
        cardDetail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscingelit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing    elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
      },
      {
        id: "2",
        cardTitle: "Second Card",
        cardDetail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscingelit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing    elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
      },
      {
        id: "3",
        cardTitle: "third Card",
        cardDetail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscingelit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing    elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
      },
    ],
  },
  notifications: {
    notificationsItems: [
      {
        id: "1",
        notificationTitle: "First Card",
        notificationDetail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscingelit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing    elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        notificationTime: "now",
      },
      {
        id: "2",
        notificationTitle: "Second Card",
        notificationDetail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscingelit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing    elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        notificationTime: "2 hours ago",
      },
      {
        id: "3",
        notificationTitle: "third Card",
        notificationDetail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscingelit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing    elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
        notificationTime: "4 hours ago",
      },
    ],
  },
  contenthubPage: {
    contentHubModule: [
      {
        id: "1",
        count: "12 Modules",
        title: "First Module",
        title2: "My Learning",
        views: "23k",
        time: "18 hour",
        image: "/images/contentImg.jpg",
        detail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        state: "Complete",
      },
      {
        id: "2",
        count: "10 Modules",
        title: "First Course",
        title2: "My Learning",
        views: "23k",
        time: "20 hour",
        image: "/images/contentImg.jpg",
        detail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        state: "Complete",
      },
      {
        id: "3",
        count: "12 Modules",
        title: "First Module",
        title2: "My Learning",
        views: "23k",
        time: "18 hour",
        image: "/images/contentImg.jpg",
        detail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        state: "Complete",
      },
      {
        id: "4",
        count: "10 Modules",
        title: "First Course",
        title2: "My Learning",
        views: "23k",
        time: "20 hour",
        image: "/images/contentImg.jpg",
        detail:
          "Eradius nihil inventore sint expedita a quo eveniet aspernatur excepturi aut maiores, laudantium rerum nesciunt",
        state: "Complete",
      },
    ],

    resource: [
      {
        id: "1",
        views: "23k",
        title: "First Module",
        time: "1 hour",
        image: "/images/contentImg.jpg",
        detail: "9 Eradius nihil inva quo eve aut maiores, laudant",
      },
      {
        id: "2",
        views: "23k",
        title: "First Module",
        time: "2 hour",
        image: "/images/contentImg.jpg",
        detail: "9 Eradius nih aiores, laudantium rerum nesciunt",
      },
      {
        id: "3",
        views: "15k",
        title: "First Module",
        time: "3 hour",
        image: "/images/contentImg.jpg",
        detail: "5 aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "4",
        views: "11k",
        title: "First Module",
        time: "4 hour",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },

      {
        id: "5",
        views: "23k",
        title: "First Module",
        time: "1 hour",
        image: "/images/contentImg.jpg",
        detail: "9 Eradius nihil inva quo eve aut maiores, laudant",
      },
      {
        id: "6",
        views: "23k",
        title: "First Module",
        time: "2 hour",
        image: "/images/contentImg.jpg",
        detail: "9 Eradius nih aiores, laudantium rerum nesciunt",
      },
      {
        id: "7",
        views: "15k",
        title: "First Module",
        time: "3 hour",
        image: "/images/contentImg.jpg",
        detail: "5 aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "8",
        views: "11k",
        title: "First Module",
        time: "4 hour",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },

      {
        id: "9",
        views: "23k",
        title: "First Module",
        time: "1 hour",
        image: "/images/contentImg.jpg",
        detail: "9 Eradius nihil inva quo eve aut maiores, laudant",
      },
      {
        id: "10",
        views: "23k",
        title: "First Module",
        time: "2 hour",
        image: "/images/contentImg.jpg",
        detail: "9 Eradius nih aiores, laudantium rerum nesciunt",
      },
      {
        id: "11",
        views: "15k",
        title: "First Module",
        time: "3 hour",
        image: "/images/contentImg.jpg",
        detail: "5 aspernatur excepturi aut maiores, laudantium rerum nesciunt",
      },
      {
        id: "12",
        views: "11k",
        title: "First Module",
        time: "4 hour",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
    ],
    podcast: [
      {
        id: "1",
        title: "First Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "2",
        title: "Second Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "3",
        title: "Third Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "4",
        title: "Fourth Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "5",
        title: "First Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "6",
        title: "Second Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "7",
        title: "Third Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
      {
        id: "8",
        title: "Fourth Module",
        image: "/images/contentImg.jpg",
        detail: " 9 Eradius nihil inva quo eveniet aspernatur exrum nesciunt",
      },
    ],
  },
};

export default baseContent;
