import "@brainhubeu/react-carousel/lib/style.css";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import {
  if_Attended,
  if_Book,
  if_Booked,
  if_Join,
  if_Mark_Joined,
  if_NoSlots,
} from "../../../../utils/class_webinar_status";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(18) },
  },
  maincontainer: {
    padding: theme.spacing(1),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  videoContainer: {
    width: "min-content",
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    boxShadow: theme.shadows[4],
  },
  borderclassBottom: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1.25),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(4),

    "@media (max-width:800px)": {
      width: "100%",
    },
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
  },
  timeBox: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(1),
  },

  media: {
    width: "100%",
    minHeight: 220,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  courseIcon: {
    color: "#75A8F4",
    fontSize: theme.spacing(2.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(2),
    },
  },
  sectionrootinnerFont: {
    fontSize: theme.spacing(1.4),
    marginLeft: theme.spacing(1),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.11),
    },
  },

  sectionrootinnerDescription: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    width: "80%",
  },
  sectionrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    padding: theme.spacing(0.6),
    // marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  pageTitle: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0),
    color: "#0D2860",
  },
}));

const ModuleButton = ({ section, addModule, viewSection }) => {
  const styles = makeStyle();
  return section?.status === 0 ? (
    <Button
      size="small"
      className={styles.sectionrootinnerButton}
      onClick={() => addModule(section)}
    >
      Start
    </Button>
  ) : (
    <Button
      size="small"
      className={styles.sectionrootinnerButton}
      color="primary"
      style={{ backgroundColor: "#fff", color: "#2E9F92" }}
    >
      {section?.status === 3 && (
        <Box onClick={() => viewSection(section)} className={styles.iconBox}>
          <CheckCircleIcon />
          <Box style={{ marginLeft: 5 }}>Completed</Box>
        </Box>
      )}
      {section?.status === 1 && (
        <Box onClick={() => viewSection(section)} className={styles.iconBox}>
          <CheckCircleIcon />
          <Box style={{ marginLeft: 5 }}>Continue</Box>
        </Box>
      )}
    </Button>
  );
};

export default ModuleButton;
