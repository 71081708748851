import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Header from "../../../components/header/Header";
import Footer from "../../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useAllPodcasts from "../../../resources/localStorage/hooks/useAllPodcasts";
import { GLOBAL_DATA } from "../../../store/actionTypes";
import { Get_API } from "../../../services/ApiServies";
import { Res } from "../../../resources";
import ProgressLoader from "../../../components/ProgressLoader";
import { getLearningsAction } from "../../../store/actions";
const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(11),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  backButton: {
    marginTop: theme.spacing(6),
    borderRadius: 10,
    display: "flex",
    fontSize: 18,
    textDecoration: "none",
    height: 50,
    paddingLeft: theme.spacing(12),
  },
  pageBox: {
    maxWidth: 1125,
    marginRight: "auto",
    marginLeft: "auto",
    minHeight: 500,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      textAlign: "center",
    },
  },
  headingText: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(3),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  frameStyle: {
    border: "none",
    marginTop: theme.spacing(0),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(2.4),
    padding: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      boxShadow: theme.shadows[0],
    },
  },
}));

function Podcast() {
  let history = useHistory();
  const styles = makeStyle();
  const { podcastId } = useParams();
  const global_data = useSelector((state) => state.commonReducer);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [iframeSrc, setIframeSrc] = useState(
    global_data.authPodcasts?.podcasts?.find(
      (podcast) => podcast.id === podcastId
    ).linkUrl
  );
  const dispatch = useDispatch();

  const hideLoader = () => {
    setDisplayLoader(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login");
    } else if (!global_data.authPodcasts.podcasts.length) {
      const getItemSuccess = (response) => {
        if (response?.data.length) {
          var responseObject = response?.data;
          getLearningsAction(dispatch, responseObject);
          setIframeSrc(
            global_data.authPodcasts?.podcasts?.find(
              (podcast) => podcast.id === podcastId
            ).linkUrl
          );
        }
      };
      Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
    }
  }, []);

  useEffect(() => {
    if (global_data.email === "") {
      history.push("/login");
    }
  });

  if (global_data.email === "") {
    return null;
  }
  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container className={styles.pageBox}>
        <Box
          pb={5}
          fontWeight="fontWeightBold"
          fontSize="h4.fontSize"
          color="secondary.main"
        >
          {global_data?.podcastData?.heading}
        </Box>
        <Box>
          <Box
            className={styles.frameStyle}
            display={displayLoader ? "block" : "none"}
            width="100%"
            height="400px"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ProgressLoader seconds={3} hideLoader={hideLoader} />
            </Box>
          </Box>
          <iframe
            style={{ opacity: displayLoader ? 0 : 1 }}
            className={styles.frameStyle}
            title="MyFamilyCoach Podcast Forum"
            src={iframeSrc}
            width="100%"
            height={displayLoader ? "600px" : "1000px"}
          ></iframe>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Podcast;
