import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Res } from "../../../../resources";
import { Post_API } from "../../../../services/ApiServies";
import { subscribeContentPackageAction } from "../../../../store/actions";

const usePayment = ({ subscribeContent,  fromCourseModulePage = false }) => {
  const [paymentModal, setPaymentModal] = React.useState(false);
  const [directPaymentModal, setDirectPaymentModal] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [selectedPaymentCourse, setSelectedPaymentCourse] = useState("");
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const showPaymentProgress = () => {
    setPaymentInProgress(true);
  };

  const cancelPayment = () => {
    setPaymentModal(false);
    setDirectPaymentModal(false);
  };

  const paymentCall = (course) => {
    setPaymentModal(true);
    setSelectedPaymentCourse(course);
    console.log("CONSOLE LOG---->", course);
  };

  const directPaymentCall = (course) => {
    setDirectPaymentModal(true);
    setSelectedPaymentCourse(course);
    console.log("CONSOLE LOG---->", course);
  };

  const buyCourse = (paymentId) => {
    console.log("****************CARD****************");
    if (global_data.token) {
      subscribeContent(selectedPaymentCourse, {
        paymentId,
        fromCourseModulePage,
        onFinal: () => {
          setPaymentInProgress(false);
        },
      });
    } else {
      history.push("/login");
    }
  };

  const directBuyCourse = (paymentId, onSuccess) => {
    console.log(
      "****************DIRECT CARD****************",
      selectedPaymentCourse
    );
    if (global_data.token) {
      Post_API(
        Res.apiEndPoints.directPayment,
        {
          ownerId: global_data?.id,
          courseId: selectedPaymentCourse.id
            ? selectedPaymentCourse.id
            : selectedPaymentCourse.courseId,
          chargeAmount:
            (parseInt(selectedPaymentCourse?.price) +
              0.2 * parseInt(selectedPaymentCourse?.price)) *
            100,
          paymentstripeToken: paymentId,
        },
        (res) => {
          console.log("pathhhhhh   1");
          subscribeContentPackageAction(
            dispatch,
            selectedPaymentCourse,
            res,
            global_data,
            {
              justPayment: true,
            }
          );
          setPaymentInProgress(false);
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        },
        true
      );
    } else {
      history.push("/login");
    }
  };

  return {
    directBuyCourse,
    showPaymentProgress,
    cancelPayment,
    paymentCall,
    buyCourse,
    directPaymentCall,
    paymentInProgress,
    paymentModal,
    selectedPaymentCourse,
    directPaymentModal,
  };
};

export default usePayment;
