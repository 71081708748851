
import React, { useState, useEffect } from 'react'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box } from '@material-ui/core';

const ProgressLoader = (props) => {
    const [percentage, setPercentage] = useState(0);
    const timer = () => {
        setPercentage(percentage + 1)
    }

    useEffect(() => {
        if (percentage >= 100) {
            props.hideLoader();
            return;
        }
        const id = setInterval(timer, (props.seconds * 1000) / 100);
        return () => clearInterval(id);
    }, [percentage])

    return (
        <Box>
            <Box style={{ width: 200, height: 200 }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: `rgba(62, 152, 199, ${percentage / 100})`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'butt',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.25turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#d6d6d6',
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'butt',
                            // Rotate the trail
                            transform: 'rotate(0.25turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                            // Text color
                            fill: '#f88',
                            // Text size
                            fontSize: '18px',
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                            fill: '#3e98c7',
                        },
                    }}
                />
            </Box>

        </Box>
    );
}

export default ProgressLoader;


