import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const useHomePageStorage = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localHome, setLocalHome] = useSessionStorage(keys.HOME_PAGE, null);
  const history = useHistory();

  const fetchHomePage = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const localHomeImageArticle = res.data?.part1;
      const localHomeArticle = res.data?.part2;
      const localAuthHomeAricle = res.data?.authBanner;
      const localHomeFeature = res.data?.features;
      const localBooking = res.data?.booking;
      const localAuthArticle = res.data?.authArticle;
      sessionStorage.removeItem(keys.HOME_PAGE);
      setLocalHome({
        localHomeImageArticle,
        localHomeArticle,
        localAuthHomeAricle,
        localAuthArticle,
        localHomeFeature,
        localBooking,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "home",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localHome === null) {
      fetchHomePage();
    }
  }, []);

  return { ...localHome, fetchHomePage };
};

export default useHomePageStorage;
