import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Admin from "./containers/admin/Admin";
import Checkout from "./containers/auth/checkout/Checkout";
import ClassPage from "./containers/auth/coaching/ClassPage";
import Coaching from "./containers/auth/coaching/Coaching";
import Community from "./containers/auth/community/Community";
import ContentHub from "./containers/auth/contenthub/ContentHub";
import ContentHubCourses from "./containers/auth/contenthub/ContentHubCourses";
import ContenthubDetails from "./containers/auth/contenthub/ContenthubDetails";
import ContenthubModule from "./containers/auth/contenthub/ContenthubModule";
import ContenthubWebinarDetail from "./containers/auth/contenthub/ContenthubWebinarDetail";
import ContentPage from "./containers/auth/contenthub/ContentPage";
import CreateSubContent from "./containers/auth/create/CreateSubContent";
import HomeClasses from "./containers/auth/home/dedicatedLearningPages/HomeClasses";
import HomeCourses from "./containers/auth/home/dedicatedLearningPages/HomeCourses";
import HomePodcasts from "./containers/auth/home/dedicatedLearningPages/HomePodcasts";
import HomeResources from "./containers/auth/home/dedicatedLearningPages/HomeResources";
import HomePage from "./containers/auth/home/HomePage";
import Journal from "./containers/auth/journal/Journal";
import NotificationPage from "./containers/auth/notifications/NotificationPage";
import Payment from "./containers/auth/payment/Payment";
import Podcast from "./containers/auth/podcast/PodcastPage";
import ContactInfo from "./containers/auth/profile/ContactInfo";
import PaymentHistory from "./containers/auth/profile/PaymentHistory";
import Profile from "./containers/auth/profile/Profile";
import ScormPage from "./containers/auth/scorm/scormPage";
import WhoWeAre from "./containers/auth/whoweare/WhoWeAre";
import Cookie from "./containers/FooterLink/Cookie";
import Privacy from "./containers/FooterLink/Privacy";
import Terms from "./containers/FooterLink/Terms";
import ForgetPassword from "./containers/unAuth/ForgetPassword";
import Login from "./containers/unAuth/Login";
import SetPassword from "./containers/unAuth/SetPassword";
import Signup from "./containers/unAuth/Signup";
import { onMessageListener } from "./firebase";
import "./index.css";
import { Res } from "./resources";
import { Get_API_With_Token } from "./services/ApiServies";
import { CustomizeToast } from "./services/CommonLogic";
import { DELETE_DATA, GLOBAL_DATA } from "./store/actionTypes";

const stripePromise = loadStripe(
  "pk_test_51JKm3mLrSTHO5gXvX7PFZH0WyAgm8qFM0UuS2ZUIEhAal6w81VXty9D9MMnxPhDGsceP6GJ5J9siGClFA0c2pUIQ00pjgI2tJU"
);

function App() {
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const userid = global_data.id;

  useEffect(() => {
    localStorage.clear();
    console.log("localstore cleaned");
    window.addEventListener("push", function (event) {
      if (event.data) {
        console.log("NOTIFICATION: ", event.data.text());
      } else {
        console.log("This push event has no data.");
      }
    });
  }, []);

  const getNotificationSuccess = useCallback(
    (response) => {
      console.log("NOTIFICATION Response ", response);
      const allNotification = response.data.object;
      console.log("APP NOTE-----", response.data.object);
      dispatch({
        type: GLOBAL_DATA,
        payload: { notifications: allNotification },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (global_data.token) {
      let decodedToken = jwt_decode(global_data.token);
      console.log("Decoded Token", decodedToken);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        dispatch({ type: DELETE_DATA });
        localStorage.clear();
        history?.push("/login");
      } else {
        console.log("Valid token");
      }
    }
  }, [global_data.token, userid]);

  useEffect(() => {
    if (global_data.token) {
      Get_API_With_Token(
        Res.apiEndPoints.allNotification + global_data.id,
        {},
        getNotificationSuccess,
        global_data.token
      );
    }
  }, [global_data.token, dispatch, getNotificationSuccess]);

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      if (global_data.token) {
        CustomizeToast(
          payload.data.message,
          () => {},
          Res.variables.toastTimerDelay
        );
        console.log("NOTIFICATION PAYLOAD DATA--", payload.data);
        console.log("global_data?.notifications--", global_data?.notifications);
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            notifications: [...global_data?.notifications, payload.data],
            newNotificationsCount: global_data?.newNotificationsCount + 1,
          },
        });
      }
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Elements stripe={stripePromise}>
      <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
        <ThemeProvider theme={Res.theme}>
          <CssBaseline />
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/login" component={Login} />
                <Route path="/setpassword" component={SetPassword} />
                <Route path="/forgetpassword" component={ForgetPassword} />
                <Route path="/signup" component={Signup} />
                <Route
                  path="/notifications"
                  exact
                  component={NotificationPage}
                />
                <Route
                  path="/notifications/:notificationId"
                  component={NotificationPage}
                />
                <Route path="/checkout" component={Checkout} />
                <Route path="/profile" component={Profile} />
                <Route path="/paymenthistory" component={PaymentHistory} />
                <Route path="/contact" component={ContactInfo} />
                <Route path="/whoweare" component={WhoWeAre} />
                <Route path="/coaching" component={Coaching} />
                <Route path="/contenthub" exact component={ContentHub} />
                <Route
                  path="/contenthub/courses"
                  exact
                  component={ContentHubCourses}
                />
                <Route path="/contenthub/:pageType" component={ContentPage} />
                <Route
                  path="/webinar/:courseId/:subId/:sectionId"
                  component={ContenthubWebinarDetail}
                />
                <Route path="/podcasts/:podcastId" component={Podcast} />
                <Route path="/scorms/:scormId" component={ScormPage} />
                <Route
                  path="/courses/:courseId/:subId/:sectionId"
                  component={ContenthubDetails}
                  exact
                />
                <Route
                  path="/resources/:courseId/:subId/:sectionId"
                  component={ContenthubDetails}
                  exact
                />
                <Route
                  path="/talks/:courseId/:subId/:sectionId"
                  component={ContenthubDetails}
                  exact
                />
                <Route path="/classes/:classId" component={ClassPage} exact />
                <Route
                  path="/courses/:courseId/:subId"
                  component={ContenthubModule}
                  exact
                />
                <Route
                  path="/talks/:courseId/:subId"
                  component={ContenthubModule}
                  exact
                />
                <Route path="/journal" component={Journal} />
                <Route path="/community" component={Community} />
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/cookie" component={Cookie} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/cookie" component={Cookie} />
                <Route path="/courses" component={HomeCourses} />
                <Route path="/resources" component={HomeResources} />
                <Route path="/podcasts" component={HomePodcasts} />
                <Route path="/classes" component={HomeClasses} />
                <Route path="/admin" component={Admin} />
                <Route path="/payment/:subId" component={Payment} exact />
                <Route
                  path="/create/:type/:parentId"
                  component={CreateSubContent}
                  exact
                />
                <Route
                  path="/create/:type"
                  exact
                  component={CreateSubContent}
                />
                <Route
                  path="/update/:type/:parentId/:contentId"
                  component={CreateSubContent}
                  exact
                />
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </Elements>
  );
}

export default App;
