import "@brainhubeu/react-carousel/lib/style.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Res } from "../../../../resources";
import { Get_Page, Put_API } from "../../../../services/ApiServies";
import { CustomizeToastError } from "../../../../services/CommonLogic";
import { completeContentItemAction } from "../../../../store/actions";

const useSubLearnings = ({ subscribeContent } = {}) => {
  const global_data = useSelector((state) => state.commonReducer);
  const { courseId, subId, sectionId } = useParams();
  const [data, setData] = useState({});
  const [bookWebinarModal, setBookWebinarModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [section, setSection] = useState({});

  React.useEffect(() => {
    if (!subId || !courseId) {
      return;
    }
    const getDataSuccess = (response) => {
      if (response.data) {
        setData(response.data);
        if (sectionId) {
          const thisSection = response.data?.sections?.find(
            (section) => String(section.id) === String(sectionId)
          );
          setSection(thisSection);
        }
      }
    };
    let courseUrl;
    if (location.pathname?.includes("courses")) {
      courseUrl =
        subId !== "coursedetail"
          ? `${Res.apiEndPoints.course}${courseId}/${subId}`
          : `${Res.apiEndPoints.course}${courseId}`;
    } else {
      courseUrl =
        subId !== "talksdetail"
          ? `${Res.apiEndPoints.course}${courseId}/${subId}`
          : `${Res.apiEndPoints.course}${courseId}`;
    }
    Get_Page(courseUrl, {}, getDataSuccess, global_data?.token);
  }, [subId]);

  const viewSection = (card) => {
    if (data?.status === -1) {
      return CustomizeToastError(
        `Please add the ${data.type} to dashboard by pressing the heart button before continue`,
        () => {},
        3500
      );
    }
    if (card.type === "webinar") {
      if (data?.status >= 0 || data?.status === -1) {
        history.push(
          `/webinar/${courseId}/${
            data.subscriptionId ? data.subscriptionId : subId
          }/${card?.id}`
        );
      }
    } else if (card.type !== "webinar") {
      // if (data?.status === 3 || data?.status === 1) {
      history.push(
        `/${
          location.pathname?.includes("course") ? "courses" : "talks"
        }/${courseId}/${data.subscriptionId ? data.subscriptionId : subId}/${
          card?.id
        }`
      );
      // }
    }
  };

  const addModule = (card, { updateSections = false } = {}) => {
    subscribeContent(card, {
      onSuccess: () => {
        console.log("cardddddddd", card);
        if (card.type === "webinar") {
          if (updateSections) {
            let updatedSections = data?.sections?.map((sec) => {
              if (sec.id === card.id) {
                return { ...sec, status: 1, state: 1 };
              } else return sec;
            });
            let updatedData = { ...data, sections: updatedSections };
            setData(updatedData);
          }
          return setBookWebinarModal(true);
        } else viewSection(card);
      },
    });
  };

  const startCourse = () => {
    const firstModule = data.sections?.filter(
      (module) => module.type !== "webinar"
    )[0];
    if (firstModule) {
      console.log("firstModule", firstModule);
      addModule(firstModule);
    }
  };

  const closeWebinarModal = () => {
    setBookWebinarModal(false);
  };

  const completeCall = (card, updateSections) => {
    Put_API(
      Res.apiEndPoints.updateModule,
      { moduleId: card?.id, markCompleted: true },
      (res) => {
        if (updateSections) {
          let updatedSections = data?.sections?.map((sec) => {
            if (sec.id === card.id) {
              return { ...sec, status: 3 };
            } else return sec;
          });
          let updatedData = { ...data, sections: updatedSections };
          setData(updatedData);
        }
      }
    );
  };

  return {
    data,
    setData,
    section,
    viewSection,
    addModule,
    startCourse,
    closeWebinarModal,
    bookWebinarModal,
    completeCall,
  };
};

export default useSubLearnings;
