import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "./../../components/Footer";
import Header from "../../components/header/Header";
import { ADMIN } from "../../resources/server";
import ProgressLoader from "../../components/ProgressLoader";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(11),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  pageBox: {
    maxWidth: 1125,
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: theme.spacing(3),
    minHeight: "80vh",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
    },
  },

  headingText: {
    paddingTop: theme.spacing(9),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },

  frameStyle: {
    border: "none",
    marginTop: theme.spacing(4),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(2.4),
    padding: theme.spacing(1),

    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      boxShadow: theme.shadows[0],
    },
  },
  pageText: {
    // paddingRight: theme.spacing(6),
    // [theme.breakpoints.down("xs")]: {
    //   paddingRight: theme.spacing(0),
    //   textAlign: "center",
    //   marginLeft:20,
    //   marginRight:20
    // },
    color: "#0D2860",
    fontSize: 26,
    fontWeight: "600",
    marginBottom: 15,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

function Admin() {
  const styles = makeStyle();
  const history = useHistory();

  const global_data = useSelector((state) => state.commonReducer);
  const [displayLoader, setDisplayLoader] = useState(true);

  const hideLoader = () => {
    setDisplayLoader(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const token = global_data.token.split(" ").join("");
  console.log("TOKEN IS ", token);

  useEffect(() => {
    if (global_data.profile.userType != "Admin") {
      history.goBack();
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />

      {global_data.email && (
        <Box my={2} className={styles.pageBox}>
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            Admin Hub
          </Typography>

          <Box
            className={styles.frameStyle}
            display={displayLoader ? "block" : "none"}
            width="100%"
            height="400px"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ProgressLoader seconds={2} hideLoader={hideLoader} />
            </Box>
          </Box>

          <Box>
            <iframe
              className={styles.frameStyle}
              style={{ opacity: displayLoader ? 0 : 1 }}
              height={displayLoader ? "600px" : "780px"}
              title="MyFamilyCoach Community Forum"
              src={ADMIN + "/token=" + global_data.token}
              width="100%"
            ></iframe>
          </Box>
        </Box>
      )}

      <Footer />
    </div>
  );
}

export default Admin;
