import firebase from "firebase/app";
import "firebase/messaging";
import { Res } from "./resources";
import { Post_API } from "./services/ApiServies";
import { GLOBAL_DATA } from "./store/actionTypes";
import store from "./store/store";
import { firebaseConfig } from "./resources/firebase-main";

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase?.messaging?.isSupported()) {
  messaging = firebase.messaging();
} else {
  console.log("Push notification is not supported,SCROLL!!!");
}
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/firebase-messaging-sw.js");
}
export const getToken = (userid) => {
  return messaging
    ?.getToken({
      vapidKey: "2fSB9UzwaU9js7jhx-BhMpRpY3tOB42H6BKtspW5zrw",
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);

        store.dispatch({
          type: GLOBAL_DATA,
          payload: { clientToken: currentToken },
        });

        Post_API(Res.apiEndPoints.saveNotificationToken, {
          userid: userid,
          token: currentToken,
        });
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload) => {
      resolve(payload);
    });
  });

// export const onBackgroundMessageListener = () =>
//   new Promise((resolve) => {
//     messaging?.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

export default firebase;
