import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Res } from "../../../../resources";
import { Get_Page } from "../../../../services/ApiServies";
import { GLOBAL_DATA } from "../../../../store/actionTypes";

const useAllTalks = ({ fetchNeeded = true } = {}) => {
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [allTalks, setAllTalks] = useState(global_data?.allTalks);

  useEffect(() => {
    if (fetchNeeded) {
      const getTalksSuccess = (response) => {
        if (response?.data?.talks) {
          setAllTalks(response.data.talks);
          dispatch({
            type: GLOBAL_DATA,
            payload: { allTalks: response.data.talks },
          });
        }
      };
      Get_Page(Res.apiEndPoints.talks, {}, getTalksSuccess, global_data?.token);
    }
  }, []);

  useEffect(() => {
    setAllTalks(global_data.allTalks);
  }, [global_data.allTalks]);

  return [allTalks, setAllTalks];
};

export default useAllTalks;
