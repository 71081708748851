import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomButton from "./CustomButton";
import h2p from "html2plaintext";

const makeStyle = makeStyles((theme) => ({
  container: {
    minHeight: 155,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
    borderRadius: 20,
  },
  btnStyle: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#1297A7",
    borderRadius: 8,
    marginTop: 20,
  },
  txtStyle: {
    marginTop: 15,
    fontWeight: "400",
  },
  txtStyle1: {
    marginTop: 15,
    fontWeight: "600",
  },
}));

export default function PageBottomSection({ item, page, bgColor, ...props }) {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();
  // return null;

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={styles.container}
      style={{
        backgroundColor: bgColor ? bgColor : "#F3F3F3",
        color: bgColor ? "#fff" : "000",
      }}
    >
      <Typography variant={"h5"} align="center" className={styles.txtStyle1}>
        {item?.title}
        {global_data.isEditable ? (
          <IconButton
            onClick={() => {
              history.push(
                `/update/pageItem/bottomSection_${page || ""}/${item?.id}`
              );
            }}
          >
            <CreateIcon />
          </IconButton>
        ) : null}
      </Typography>
      <Typography variant={"h6"} align="center" className={styles.txtStyle}>
        {h2p(item?.description)}
      </Typography>
      <CustomButton
        buttonType="contained"
        buttonColor="primary"
        clickHandler={() => {
          history.push("/coaching");
        }}
        buttonText={props.buttonText}
        customStyle={{
          maxWidth: 150,
          marginTop: 10,
          borderRadius: 40,
        }}
        color="white"
      />
    </Grid>
  );
}
