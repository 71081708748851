const getCardRoute = (card) =>
  card?.type === "talks"
    ? `/talks/${card.id}/${
        card.isMyLearning ? card.subscriptionId : "talksdetail"
      }`
    : card?.type === "resource"
    ? `/resources/resource/subscriptons/${card.id}`
    : `/podcasts/${card.id}`;

export default getCardRoute;
