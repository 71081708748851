import {
  Box,
  Button,
  Container,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import h2p from "html2plaintext";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ClassSection from "../../../components/ClassSection";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import { Res } from "../../../resources";
import useCoachingPageStorage from "../../../resources/localStorage/hooks/useCoachingPageStorage";
import { Get_API } from "../../../services/ApiServies";
import { getLearningsAction } from "../../../store/actions";
import CustomCarouselClasses from "./CustomCarousalClasses";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  subHeading: {
    paddingBottom: theme.spacing(1.25),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },

  textBox: {
    display: "flex",
    "@media (max-width:800px)": {
      flexDirection: "column",
    },
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  imagewhowearebig: {
    maxHeight: 400,
    width: "auto",
    "@media (max-width:800px)": {
      width: "90%",
    },
  },
  maincontainer: {
    maxWidth: 1090,
    marginLeft: "auto",
    marginRight: "auto",
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
  },
  bookingContainer: {
    paddingBlock: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      // paddingLeft: "15%",
      // paddingRight: "15%",
    },
  },
  des: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
}));

function Coaching() {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [enableEdit] = React.useState(global_data.isEditable ? true : false);
  const [classesState, setClassesState] = React.useState(
    global_data?.authClasses
  );
  const dispatch = useDispatch();
  const {
    localCoachingImageArticle,
    localCoachingArticle,
    localCoachingSteps,
  } = useCoachingPageStorage();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  useEffect(() => {
    if (global_data.id) {
      const getItemSuccess = (response) => {
        if (response?.data.length) {
          var responseObject = response?.data;

          getLearningsAction(dispatch, responseObject);

          setClassesState(
            responseObject.filter((data) => {
              return data.type === "classes";
            })[0]
          );
        }
      };
      Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
    } else {
      const getItemSuccess = (response) => {
        console.log(response);
        if (response?.data?.length) {
          setClassesState({ classes: response.data });
        }
      };
      Get_API(Res.apiEndPoints?.classes, null, getItemSuccess, true);
    }
  }, [global_data?.id]);

  useEffect(() => {
    if (global_data.id) {
      setClassesState(global_data.authClasses);
    }
  }, [global_data.authClasses]);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500 }}>
        <Box className={styles.maincontainer}>
          <Box className={styles.textBox}>
            <Box>
              <Box className={styles.buttonContainer}>
                <Typography
                  variant="h4"
                  component="h2"
                  className={styles.heading}
                >
                  {(localCoachingImageArticle &&
                    localCoachingImageArticle?.heading) ||
                    "Coaching"}
                </Typography>
                {global_data.isEditable && (
                  <IconButton
                    onClick={() => {
                      history.push(
                        `/update/pageItem/coachingimagearticle/${localCoachingImageArticle.id}`
                      );
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                )}
              </Box>
              <Typography
                variant="h4"
                component="h2"
                className={styles.subHeading}
              >
                {localCoachingImageArticle && localCoachingImageArticle?.title}
              </Typography>
              <Typography
                variant="h6"
                component="p"
                style={{ marginBottom: 50 }}
              >
                {h2p(localCoachingImageArticle?.description)}
              </Typography>
            </Box>
            <Box style={{ textAlign: "center" }}>
              <img
                className={styles.imagewhowearebig}
                src={
                  localCoachingImageArticle?.image &&
                  localCoachingImageArticle?.image
                }
                alt={localCoachingImageArticle?.title}
              />
            </Box>
          </Box>
          {classesState?.classes?.filter(
            (item) =>
              (item.state > -1 && item.state < 3 && !item.isOver) ||
              item.state === undefined
          )?.length ? (
            <Typography
              variant="h4"
              component="h2"
              className={styles.subHeading}
              style={{ textAlign: "left" }}
            >
              Booking Now
            </Typography>
          ) : null}
          {global_data.isEditable ? (
            <>
              <Box display="flex" py={1} justifyContent="flex-end">
                <Button
                  style={{ padding: 5, textTransform: "none" }}
                  onClick={() => history.push("/create/class/allclasses")}
                >
                  <AddCircleIcon color="primary" size="large" />
                  <Typography variant="caption">Add class</Typography>
                </Button>
              </Box>

              <CustomCarouselClasses
                carouselTitle="Classes"
                carouselItems={classesState?.classes || []}
                // link="/contenthub/podcasts"
              />
            </>
          ) : classesState?.classes?.length > 0 ? (
            <CustomCarouselClasses
              carouselTitle="Classes"
              carouselItems={
                classesState?.classes?.filter(
                  (item) =>
                    (item.state > -1 && item.state < 3 && !item.isOver) ||
                    item.state === undefined
                ) || []
              }
              // link="/contenthub/podcasts"
            />
          ) : (
            <Typography variant="h5">No Class Available</Typography>
          )}

          <ClassSection
            enableEdit={enableEdit}
            localCoachingArticle={localCoachingArticle}
            localCoachingSteps={localCoachingSteps}
          />
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Coaching;
