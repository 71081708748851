import { GLOBAL_DATA } from "../../actionTypes";

const getLearningsAction = (dispatch, responseObject) => {
  dispatch({
    type: GLOBAL_DATA,
    payload: {
      subscribedCourses: responseObject.filter(
        (data) =>
          data.type !== "podcasts" &&
          data.type !== "resources" &&
          data.type !== "classes"
      ),
      authResources: responseObject.filter((data) => {
        return data.type === "resources";
      })[0],
      authPodcasts: responseObject.filter((data) => {
        return data.type === "podcasts";
      })[0],
      authClasses: responseObject.filter((data) => {
        return data.type === "classes";
      })[0],
    },
  });
};

export default getLearningsAction;
