import { Box, Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containerStetch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  boxContainer: {
    minWidth: 80,
    backgroundColor: "#9A89B5",
    borderRadius: 10,
    marginBottom: 50,
    margin: "0 45px",
    padding: theme.spacing(10),
    wordBreak: "break-word",
    minHeight: 250,
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  boldText: {
    textAlign: "center",
    color: "black",
    fontWeight: 600,
    marginBottom: 15,
  },
  subHeading: {
    paddingBottom: theme.spacing(1.25),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function HomeNoAuth({ enableEdit, localHomeFeature }) {
  const styles = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={styles.containerStetch}></Box>
      <Box>
        <Grid container spacing={5}>
          {localHomeFeature?.map((feature, i) => (
            <Grid
              item
              xs={12}
              md={3}
              sm={12}
              lg={3}
              className={styles.boxContainer}
              key={`home_box_${i}`}
            >
              <Box
                className={styles.buttonContainer}
                style={{ marginBottom: -10 }}
              >
                <Typography
                  style={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontWeight: 600,
                    padding: "0 .5em",
                  }}
                >
                  {feature?.title}
                </Typography>
                {enableEdit && (
                  <CreateIcon
                    onClick={() => {
                      history.push(
                        `/update/pageItem/homefeature/${feature?.id}`
                      );
                    }}
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
              <br />
              <Typography
                style={{ color: "#FFFFFF", textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: feature?.description,
                }}
              />
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    (i === 0 && "/contenthub") ||
                    (i === 1 && "/coaching") ||
                    (i === 2 && "/journal")
                  }
                >
                  <Button
                    style={{
                      backgroundColor: "#9A89B5",
                      color: "#FFFFFF",
                      border: "1px solid white",
                      textAlign: "center",
                      paddingRight: "1em",
                      paddingLeft: "1em",
                      borderRadius: 30,
                      fontSize: ".9rem",
                      margin: "1em",
                    }}
                  >
                    Take a look
                  </Button>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
