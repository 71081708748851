import {
  Box,
  Card,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import PaymentModal from "../../../components/PaymentModal";
import { Res } from "../../../resources";
import { Get_API, Post_API } from "../../../services/ApiServies";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../services/CommonLogic";
import { GLOBAL_DATA } from "../../../store/actionTypes";

const useStyle = makeStyles((theme) => ({
  modulesContainerWrapper: {
    padding: theme.spacing(3),
    backgroundColor: "#F3F3F3",
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(2.25),
    boxShadow: "5px 5px 10px #F3F3F3, -5px 5px 10px #F3F3F3",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
  modulesContainer: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0290A1",
      outline: "1px solid slategrey",
    },
    maxHeight: 500,
    overflowY: "scroll",
    paddingRight: theme.spacing(2),
    backgroundColor: "#F3F3F3",
  },
  viewMoreContainer: {
    marginBottom: theme.spacing(3),
  },
  cardDetail: {
    color: theme.palette.customGrey.dark,
    fontSize: theme.spacing(1.6),
    fontSize: theme.spacing(1.6),
    paddingRight: theme.spacing(2),
  },
  cardContent: {
    paddingBottom: theme.spacing(2),
  },
}));

const HomeModules = (props) => {
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyle();
  const [currentContent, setCurrentContent] = React.useState([]);
  const [paymentModal, setPaymentModal] = React.useState(false);
  const [paymentInProgress, setPaymentInProgress] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState({});

  React.useEffect(() => {
    if (global_data.id) {
      if (props.contentType === "allLearnings") {
        const courses = global_data.subscribedCourses || [];
        const resources =
          global_data.authResources?.resources?.filter(
            (cls) => cls.status > 0
          ) || [];
        const podcasts =
          global_data.authPodcasts?.podcasts?.filter((cls) => cls.status > 0) ||
          [];
        const talks =
          global_data.allTalks?.filter((cls) => cls.status > -1) || [];
        setCurrentContent([...courses, ...resources, ...podcasts, ...talks]);
        console.log([...courses, ...resources, ...podcasts, ...talks]);
      } else {
        const classes = global_data.authClasses?.classes?.filter(
          (cls) => cls.state > 0 && cls.state < 3
        );
        setCurrentContent(classes);
      }
    }
  }, [
    global_data.subscribedCourses,
    global_data.authResources,
    global_data.authPodcasts,
    global_data.authClasses,
    global_data.allTalks,
  ]);

  const viewCourse = (course) => {
    if (course.type === "class") {
      history.push(`/classes/${course.id}`, { hello: "hello world" });
    }
    if (course.type === "course") {
      history.push(`/courses/${course.id}/${course.subscriptionId}`);
    }
    if (course.type === "talks") {
      history.push(`/talks/${course.id}/${course.subscriptionId}`);
    }
    if (course.type === "podcast") {
      history.push(`/podcasts/${course.id}`);
    } else if (course.type === "resource") {
      history.push(`/resources/resource/subscriptons/${course.id}`);
    }
  };

  const showPaymentProgress = () => {
    setPaymentInProgress(true);
  };

  const cancelPayment = () => {
    setPaymentModal(false);
  };

  const paymentCall = (course) => {
    setPaymentModal(true);
    setSelectedCourse(course);
    console.log("CONSOLE LOG---->", course);
  };

  const buyCourse = (paymentId) => {
    const card = selectedCourse;
    if (global_data.token) {
      const buyCourseSuccess = (response) => {
        console.log("PAYMENT RESPONSE ", response);
        if (response?.data?.success) {
          const getItemSuccess = (response) => {
            if (response?.data.length) {
              var responseObject = response?.data;
              const courses =
                responseObject.filter(
                  (data) =>
                    data.type !== "podcasts" &&
                    data.type !== "resources" &&
                    data.type !== "classes"
                ) || [];
              const resources =
                responseObject.filter((data) => {
                  return data.type === "resources";
                })[0] || [];
              const podcasts =
                responseObject.filter((data) => {
                  return data.type === "podcasts";
                })[0] || [];
              const classes = responseObject.filter((data) => {
                return data.type === "classes";
              })[0];
              const talks = responseObject.filter((data) => {
                return data.type === "talks";
              });

              dispatch({
                type: GLOBAL_DATA,
                payload: {
                  subscribedCourses: courses,
                  authResources: resources,
                  authPodcasts: podcasts,
                  authClasses: classes,
                },
              });
              if (props.contentType === "allLearnings") {
                const resourcesList = resources?.resources;
                const podcastsList = podcasts?.podcasts;
                console.log("talks", talks);
                const content = [
                  ...courses,
                  ...resourcesList,
                  ...podcastsList,
                  ...talks,
                ].filter((data) =>
                  data.type === "course" ? data.status > -1 : data.status > 0
                );

                console.log("Content", content);
                setCurrentContent(content);
              } else {
                const classes = responseObject.filter((data) => {
                  return data.type === "classes";
                })[0];

                const authClasses = classes?.classes;
                console.log("authClasses", authClasses);
                const content = authClasses?.filter((data) => {
                  return data.status > 0;
                });
                setCurrentContent(content);
              }
            }
          };
          console.log("PAYMENT RESPONSE ", response);
          Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
          CustomizeToast(
            response?.data?.message,
            Res.variables.toastTimerDelay
          );
          setPaymentInProgress(false);
        } else {
          CustomizeToastError(
            response?.data?.message,
            Res.variables.toastTimerDelay
          );
          setPaymentInProgress(false);
        }
      };

      Post_API(
        Res.apiEndPoints.directPayment,
        {
          chargeAmount:
            (parseInt(card?.price) + 0.2 * parseInt(card?.price)) * 100,
          courseId: card?.id,
          ownerId: global_data?.id,
          paymentstripeToken: paymentId,
        },
        buyCourseSuccess,
        global_data?.token
      );
    } else {
      history.push("/login");
    }
  };

  return (
    <Box className={styles.modulesContainerWrapper}>
      <Box className={styles.modulesContainer}>
        {currentContent?.length > 0 ? (
          currentContent?.map((course, i) => {
            return (
              <Grid
                key={i}
                container
                className={styles.cardContent}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    component="h4"
                    style={{ fontWeight: 600 }}
                  >
                    {course?.heading}
                  </Typography>

                  {props.contentType === "authClasses" ? (
                    <>
                      <Typography variant="h6" className={styles.boldText}>
                        {course?.localWebinarDate}
                      </Typography>
                      <Typography variant="h6" className={styles.boldText}>
                        {course?.localWebinarTime}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      className={styles.boldText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {course?.type === "resource"
                        ? "Quick Reads"
                        : course?.type === "talks"
                        ? "Video"
                        : course?.type}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  {paymentInProgress && selectedCourse.id == course.id && (
                    <Box py={1.5}>
                      <CircularProgress
                        style={{ color: "#75A8F4", height: 21, width: 21 }}
                      />{" "}
                    </Box>
                  )}
                  {course?.contentType == "course" && course?.needToBuy ? (
                    <CustomButton
                      buttonText="Buy"
                      clickHandler={() => paymentCall(course)}
                      customStyle={{ marginTop: 10, maxWidth: 130 }}
                      buttonType="contained"
                      buttonColor="primary"
                    />
                  ) : (
                    <CustomButton
                      buttonText="View"
                      clickHandler={() => viewCourse(course)}
                      customStyle={{
                        marginTop: 5,
                        maxWidth: 130,
                        fontWeight: 600,
                        borderRadius: 15,
                        padding: "1.4rem 1rem",
                      }}
                      buttonType="contained"
                      buttonColor="primary"
                    />
                  )}
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Card>
            <img
              src={Res.images?.authClasses}
              alt={Res.images?.[props.contentType]}
              style={{ marginLeft: 60 }}
              height="60%"
              width="80%"
            />
          </Card>
        )}
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <Link
            to={`/${
              props.contentType === "authClasses" ? "coaching" : "contenthub"
            }`}
            style={{ textDecoration: "none" }}
          >
            <CustomButton
              buttonText="Explore more"
              customStyle={{
                maxWidth: 155,
                fontWeight: 600,
                padding: "1.4rem 1rem",
                borderRadius: 40,
              }}
              buttonType="contained"
              buttonColor="primary"
            />
          </Link>
        </Grid>
        <PaymentModal
          modalState={paymentModal}
          subscribeMethod={buyCourse}
          onClose={cancelPayment}
          showPaymentProgress={showPaymentProgress}
          selectedDate={selectedCourse}
        />
      </Box>
    </Box>
  );
};

export default HomeModules;
