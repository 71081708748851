import axios from "axios";
import { Res } from "../resources";
const ls = localStorage.getItem("persist:parentvillage");
var authToken =
  ls == null ? "" : JSON.parse(JSON.parse(ls).commonReducer).token;

export const handleRes = (
  method,
  url,
  data,
  callback = () => {},
  tokenRequired,
  formDataFlag,
  tokenfromParent,
  token
) => {
  if (tokenfromParent) {
    authToken = token;
  }

  const formData = new FormData();
  for (const i in data) {
    formData.append(i, data[i]);
  }
  axios({
    method: method,
    url: url,
    data: formDataFlag ? formData : data,
    headers: {
      Accept: "application/json",
      "Content-Type": formDataFlag ? "multipart/form-data" : "application/json",
      Authorization: tokenRequired ? `${authToken}` : null,
    },
  })
    .then(function (response) {
      if (response.status === 200) {
        return url === Res?.apiEndPoints?.login
          ? callback({ data: response })
          : callback({ data: response.data });
      }
    })
    .catch((error) => {
      return url === Res?.apiEndPoints.login
        ? callback({ data: { data: { status: false, message: "" + error } } })
        : callback({ data: { success: false, message: "" + error } });
    });
};
