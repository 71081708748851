import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page, Get_Page_Alt } from "../../../services/ApiServies";
import errorAction from "../../../store/actions/errorAction";
import keys from "../constantKeys";

const useWhoWeArePageStorage = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localWhoWeAre, setLocalWhoWeAre] = useSessionStorage(
    keys.WHOWEARE_PAGE,
    null
  );
  const history = useHistory();

  const fetchWhowearePage = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const localWhoWeAreArticle = res.data.part1;
      const localWhoWeAreImageArticle = res.data.part2;
      const localTeam = res.data.members;
      sessionStorage.removeItem(keys.WHOWEARE_PAGE);

      setLocalWhoWeAre({
        localWhoWeAreArticle,
        localWhoWeAreImageArticle,
        localTeam: { members: localTeam },
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "whoweare",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localWhoWeAre === null) {
      fetchWhowearePage();
    }
  }, []);

  return { ...localWhoWeAre, fetchWhowearePage };
};

export default useWhoWeArePageStorage;
