import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Header from "../../../components/header/Header";
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useAllPodcasts from "../../../resources/localStorage/hooks/useAllPodcasts";
import useAllCourses from "../../../resources/localStorage/hooks/useAllCourses";
import ProgressLoader from "../../../components/ProgressLoader";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(11),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  backButton: {
    marginTop: theme.spacing(6),
    borderRadius: 10,
    display: "flex",
    fontSize: 18,
    textDecoration: "none",
    height: 50,
    paddingLeft: theme.spacing(12),
  },
  pageBox: {
    maxWidth: 1125,
    marginRight: "auto",
    marginLeft: "auto",
    minHeight: 500,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      textAlign: "center",
    },
  },
  headingText: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  frameStyle: {
    border: "none",
    marginTop: theme.spacing(0),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(2.4),
    padding: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      boxShadow: theme.shadows[0],
    },
  },
  maincontainer: {
    background: "white",
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(12),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  pageTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    color: "#0D2860",
    marginBottom: theme.spacing(0),
  },
}));

function ScormPage() {
  let history = useHistory();
  const styles = makeStyle();
  const { scormId } = useParams();
  const global_data = useSelector((state) => state.commonReducer);
  const [displayLoader, setDisplayLoader] = useState(true);

  const hideLoader = () => {
    setDisplayLoader(false);
  };

  // const iframeSrc = allPodcasts.podcasts[+podcastId - 1].linkUrl;
  const scorm = global_data?.allCourses?.find(
    (course) => course.id === scormId
  );
  const iframeSrc = global_data?.allCourses?.find(
    (course) => course.id === scormId
  )?.scormLink;

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (global_data.email === "") {
      history.push("/login");
    }
  });
  console.log(scorm);
  if (global_data.email === "") {
    return null;
  }
  return (
    <div className={styles.pageContainer}>
      <Header />
      <Box className={styles.maincontainer}>
        <Typography variant="h5" component="h2" className={styles.pageTitle}>
          {scorm?.heading}
        </Typography>
      </Box>
      <Container className={styles.pageBox}>
        <Box
          className={styles.frameStyle}
          display={displayLoader ? "block" : "none"}
          width="100%"
          height="400px"
        >
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ProgressLoader seconds={15} hideLoader={hideLoader} />
          </Box>
        </Box>
        <Box>
          <iframe
            className={styles.frameStyle}
            style={{ opacity: displayLoader ? 0 : 1 }}
            height={displayLoader ? "600px" : "640px"}
            title="MyFamilyCoach Podcast Forum"
            src={iframeSrc}
            width="100%"
          ></iframe>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default ScormPage;
