import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  withStyles,
  MenuItem,
  MenuList,
  Badge,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SearchItem from "./SearchItem";
import CustomButton from "../CustomButton";
import { Icon } from "@material-ui/core";
import MobileSearch from "./MobileSearch";
import { Res } from "../../resources";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import baseContent from "../../services/Content";
import { useLocation } from "react-router-dom";
import NotificationNav from "./NotificationNav";
import NavProfileAvatar from "./NavProfileAvatar";

const useStyle = makeStyles((theme) => ({
  navContainer: {
    position: "fixed",
    backgroundColor: "white",
    zIndex: 900,
    width: "100%",
    minHeight: 100,
    top: 0,
    boxShadow: theme.shadows[4],
  },
  root: {
    maxWidth: 1390,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 900,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: 14,
    [theme.breakpoints.only("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(1),
    },
  },
  menuButton: {
    color: "#4D4C51",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    alignSelf: "flex-end",
  },
  menuIcon: {
    fontSize: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(3.8),
    },
  },
  logoBox: {
    marginTop: theme.spacing(0.15),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.5),
    },
  },

  logoImg: {
    width: theme.spacing(19),
    height: theme.spacing(6),
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(12),
      height: theme.spacing(4),
      paddingLeft: theme.spacing(0.5),
    },
  },
  searchBox: {
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dropdownBox: {
    position: "absolute",
    bottom: -"100%",
    width: "100%",
    boxSizing: "border-box",
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      boxShadow: theme.shadows[0],
    },
  },
  //Mobile Responsive Design
  drawerMenu: {
    zIndex: theme.zIndex.mobileStepper,
    backgroundColor: theme.palette.paper,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100vh",
      position: "absolute",
      top: 20,
      marginTop: -15,
      width: "70%",
      borderRight: "1px solid teal",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  dropdownItem: {
    fontSize: theme.spacing(1.8),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(5),
      color: theme.palette.lightText,
      fontSize: theme.spacing(1.8),
    },
  },
  menuItem: {
    zIndex: theme.zIndex.mobileStepper,
    textTransform: "unset",
    fontSize: theme.typography.body1.fontSize,
    backgroundColor: theme.palette.paper,
    borderRadius: 0,
    color: theme.palette.customGrey.light,
    "&:hover": {
      color: "black",
      backgroundColor: theme.palette.paper,
    },
    [theme.breakpoints.only("md")]: {
      padding: 5,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.8),
      paddingTop: theme.spacing(1.8),
      paddingBottom: theme.spacing(1.8),
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(4),
      width: "100%",
    },
  },
  noNotification: {
    zIndex: theme.zIndex.mobileStepper,
    textTransform: "unset",
    fontSize: theme.typography.body1.fontSize,
    width: "100%",
    color: theme.palette.secondary.main,
  },

  authMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
    top: 1,
  },

  profileMenu: {
    zIndex: theme.zIndex.mobileStepper,
    position: "absolute",
    bottom: -"100%",
    boxSizing: "border-box",
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.paper,
    width: 160,
    right: 0,
  },
  loginButton: {
    textTransform: "unset",
    color: theme.palette.primary.main,
    padding: 0,
    "& :hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.paper,
    },
  },
  profileLink: {
    width: "100%",
    fontSize: theme.typography.caption.fontSize,
    "&:hover": {
      textDecoration: "none",
    },
  },

  mobileArrow: {
    display: "none",
    height: 35,
    width: 35,
    padding: 5,
    position: "relative",
    top: 10,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopArrow: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Header = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [dropdownState, setDropdownState] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const global_data = useSelector((state) => state.commonReducer);

  const { pathname } = useLocation();
  const loggedIn = global_data.token ? true : false;

  const mobileStyle = makeStyles((theme) => ({
    menuDisplay: {
      [theme.breakpoints.down("sm")]: {
        top: 0,
        left: mobileMenuOpen ? "0%" : "100%",
      },
    },
  }));

  const mobile = mobileStyle();
  const styles = useStyle();
  const searchOpenFunc = () => {
    setSearchState(!searchState);
  };
  const handleClose = () => {
    setSearchState(null);
  };

  // const handelDropMenu = (event) => {
  //   setDropdownState(!dropdownState);
  //   event.stopPropagation();
  //   console.log("CLICKED!", dropdownState);
  // };
  const openDropDown = () => {
    if (!dropdownState) setDropdownState(true);
  };

  const closeDropDown = () => {
    if (dropdownState) setDropdownState(false);
  };
  const handleDropMenuClose = () => {
    setDropdownState(false);
    // console.log("handleDropMenuClose!", !dropdownState);
  };
  const handleClickAway = () => {
    if (window.innerWidth > 960) {
      setDropdownState(false);
      // console.log("handleClickAway!", !dropdownState);
    }
  };

  const showMobileMenu = () => {
    setMobileMenuOpen(true);
  };
  const hideMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  if (global_data.loggedIn) {
    return null;
  }
  return (
    <div className={styles.navContainer}>
      <ClickAwayListener onClickAway={hideMobileMenu}>
        <Grid className={styles.root} container>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                onClick={showMobileMenu}
                className={styles.menuButton}
              >
                <Icon className={styles.menuIcon}>menu</Icon>
              </IconButton>
              <RouterLink to="/" className={styles.logoBox}>
                <img
                  className={styles.logoImg}
                  src={Res.images.logoImage}
                  alt={Res.images.logoImage}
                />
              </RouterLink>
            </Box>
          </Grid>
          <Grid item md={8} lg={8}>
            <Box className={`${styles.drawerMenu} ${mobile.menuDisplay}`}>
              {!props.hideMenu &&
                baseContent.header.navigation.map((item, i) => {
                  return (
                    <span key={i.toString()}>
                      {item.subMenu ? (
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Box position="relative">
                            <Box
                              style={{
                                textDecoration: "none",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <RouterLink
                                to={
                                  global_data?.loginState
                                    ? `/${pathname}`
                                    : item.route
                                }
                                style={{
                                  textDecoration: "none",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Button
                                  onFocus={openDropDown}
                                  style={
                                    (pathname && item.route === pathname) ||
                                    pathname === "/contenthub/courses" ||
                                    pathname === "/contenthub/resources" ||
                                    pathname === "/contenthub/podcasts"
                                      ? { paddingRight: 4, color: "#0D2860" }
                                      : { paddingRight: 4 }
                                  }
                                  className={styles.menuItem}
                                  onMouseOver={openDropDown}
                                  onMouseOut={closeDropDown}
                                  endIcon={
                                    <Icon
                                      className={styles.desktopArrow}
                                      style={{ position: "relative", left: -8 }}
                                    >
                                      expand_more
                                    </Icon>
                                  }
                                >
                                  {item.name}
                                </Button>
                              </RouterLink>

                              <IconButton
                                onClick={openDropDown}
                                className={styles.mobileArrow}
                              >
                                <Icon style={{ fontSize: 26 }}>
                                  expand_more
                                </Icon>
                              </IconButton>
                            </Box>

                            <MenuList
                              className={styles.dropdownBox}
                              onMouseOver={openDropDown}
                              onMouseOut={closeDropDown}
                              style={{
                                marginTop: "-10px",
                                display: dropdownState ? "block" : "none",
                              }}
                            >
                              {item.subMenu.map((subitem, i) => {
                                return (
                                  <RouterLink
                                    to={subitem.route}
                                    style={
                                      pathname && subitem.route === pathname
                                        ? {
                                            textDecoration: "none",
                                            color: "#0D2860",
                                          }
                                        : {
                                            textDecoration: "none",
                                            color: "#505C6D",
                                          }
                                    }
                                    key={i.toString()}
                                  >
                                    <MenuItem
                                      className={styles.dropdownItem}
                                      onClick={handleDropMenuClose}
                                    >
                                      {subitem.name}
                                    </MenuItem>
                                  </RouterLink>
                                );
                              })}
                            </MenuList>
                          </Box>
                        </ClickAwayListener>
                      ) : item.name !== "Admin" ? (
                        <RouterLink
                          to={
                            global_data?.loginState
                              ? `/${pathname}`
                              : item.route
                          }
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            key={i.toString()}
                            className={styles.menuItem}
                            style={
                              pathname && item.route === pathname
                                ? { paddingRight: 8, color: "#0D2860" }
                                : { paddingRight: 8 }
                            }
                          >
                            {item.name === "Dashboard" && !loggedIn
                              ? "Welcome"
                              : item.name}
                          </Button>
                        </RouterLink>
                      ) : (
                        global_data.profile.userType === "Admin" && (
                          <RouterLink
                            to={
                              global_data?.loginState
                                ? `/${pathname}`
                                : item.route
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              key={i.toString()}
                              className={styles.menuItem}
                              style={
                                pathname && item.route === pathname
                                  ? { paddingRight: 8, color: "#0D2860" }
                                  : { paddingRight: 8 }
                              }
                            >
                              {item.name === "Dashboard" && !loggedIn
                                ? "Welcome"
                                : item.name}
                            </Button>
                          </RouterLink>
                        )
                      )}
                    </span>
                  );
                })}
              {/* <Box className={styles.searchBox}>
                <SearchItem
                  placeholder="Search"
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  searchState={searchState}
                  handleClose={handleClose}
                  clickHandler={searchOpenFunc}
                />
              </Box> */}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={2} lg={global_data.loggedIn ? 1 : 2}>
            <Box className={styles.authMenu}>
              {!loggedIn ? (
                <React.Fragment>
                  <Box>
                    <RouterLink to="/login" style={{ textDecoration: "none" }}>
                      <Button className={styles.loginButton}>Login</Button>
                    </RouterLink>
                  </Box>
                  <Box>
                    <RouterLink to="/signup" style={{ textDecoration: "none" }}>
                      <CustomButton
                        buttonType="contained"
                        buttonColor="primary"
                        buttonText="Register"
                        customStyle={{ borderRadius: 40 }}
                      />
                    </RouterLink>
                  </Box>
                </React.Fragment>
              ) : (
                <>
                  {global_data?.loginState ? null : <NotificationNav />}
                  <Box position="relative">
                    <RouterLink
                      to={global_data?.loginState ? `/${pathname}` : "/profile"}
                    >
                      <NavProfileAvatar />
                    </RouterLink>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <MobileSearch
              placeholder="Search"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          </Grid> */}
        </Grid>
      </ClickAwayListener>
      <ToastContainer />
    </div>
  );
};

export default Header;
