import { toast } from "react-toastify";
import { Res } from "../resources";
import "react-toastify/dist/ReactToastify.css";

export const CustomizeToast = (
  message,
  callback,
  timer = Res.variables.toastTimer
) => {
  return toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: timer,
    onClose: callback ? callback : null,
  });
};
export const CustomizeToastError = (
  message,
  callback,
  timer = Res.variables.toastTimerDelay
) => {
  return toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: timer,
    onClose: callback ? callback : null,
  });
};
