import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import PaymentModal from "../../../components/PaymentModal";
import QueryModal from "../../../components/QueryModal";
import CourseItem from "./components/CourseItem";
import useAllCourses from "./hooks/useAllCourses";
import useAuthoring from "./hooks/useAuthoring";
import useLearning from "./hooks/useLearning";
import usePayment from "./hooks/usePayment";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(10.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(16) },
  },
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  gridContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  headingText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    fontSize: theme.spacing(3),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.spacing(1.5),
    },
  },
  navArrow: {
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: theme.spacing(2.5),
    },
  },
}));

export default function ContentHubCourses() {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [allCourses, setAllCourses] = useAllCourses();

  const {
    subscribeContent,
    unSubscribeContent,
    onLearningModalClose,
    clickCourseLikeButton,
    courseOpen,
    subscribeModal,
    unSubscribeModal,
    selectedCourse,
  } = useLearning();

  const {
    directBuyCourse,
    showPaymentProgress,
    cancelPayment,
    paymentCall,
    buyCourse,
    directPaymentCall,
    paymentInProgress,
    paymentModal,
    directPaymentModal,
selectedPaymentCourse

  } = usePayment({ subscribeContent });

  const { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal } =
    useAuthoring();

  return (
    <div className={styles.pageContainer}>
      <Header />
      <div>
        <Container style={{ minHeight: 500, maxWidth: 1130 }}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon fontSize="large" className={styles.navArrow} />
            }
          >
            <Typography
              variant="h4"
              component="h2"
              className={styles.headingText}
            >
              <Link
                to="/contenthub"
                style={{ textDecoration: "none", color: "#0D2860" }}
              >
                Information Hub
              </Link>
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              className={styles.headingText}
            >
              Courses
            </Typography>
          </Breadcrumbs>
          {global_data.isEditable && (
            <Box display="flex" py={1} justifyContent="flex-end">
              <Button
                style={{ padding: 5, textTransform: "none" }}
                onClick={() => history.push("/create/course")}
              >
                <AddCircleIcon color="primary" size="large" />
                <Typography variant="caption">Add Course</Typography>
              </Button>
            </Box>
          )}
          <Box className={styles.borderclass}>
            <Grid container className={styles.gridContainer}>
              {allCourses?.map((course, i) => (
                <CourseItem
                  key={`course${i}`}
                  course={course}
                  courseOpen={courseOpen}
                  clickCourseLikeButton={clickCourseLikeButton}
                  paymentInProgress={paymentInProgress}
                  selectedCourse={selectedCourse}
                  openDeleteModal={openDeleteModal}
                  paymentCall={paymentCall}
                  directPaymentCall={directPaymentCall}
                />
              ))}
            </Grid>
          </Box>
          <QueryModal
            modalState={subscribeModal}
            yesText="Save to My Information"
            closeText="Explore More"
            onYes={() => subscribeContent(selectedCourse, setAllCourses)}
            onClose={onLearningModalClose}
          />
          <QueryModal
            modalState={unSubscribeModal}
            yesText="Unsubscribe!"
            closeText="Explore More"
            queryText="Are you sure you want to unsubscribe ?"
            onYes={() => unSubscribeContent(selectedCourse)}
            onClose={onLearningModalClose}
          />
          <PaymentModal
            modalState={paymentModal}
            yesText="Save to My Information"
            subscribeMethod={buyCourse}
            onClose={cancelPayment}
            showPaymentProgress={showPaymentProgress}
            selectedDate={selectedPaymentCourse}
            />
          <PaymentModal
            modalState={directPaymentModal}
            yesText="Save to My Information"
            subscribeMethod={directBuyCourse}
            onClose={cancelPayment}
            showPaymentProgress={showPaymentProgress}
            selectedDate={selectedPaymentCourse}
          />
          <QueryModal
            modalState={deleteModalFlag}
            queryText="Are you sure you want to delete this ?"
            yesText="Yes"
            closeText="No"
            onYes={() => {
              deleteContent();
            }}
            onClose={() => {
              closeDeleteModal();
            }}
          />
        </Container>
        <Footer />
      </div>
    </div>
  );
}
