import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Header from "../../components/header/Header";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import Footer from "../../components/Footer";
import { useHistory, useLocation } from "react-router-dom";
import { Post_API } from "../../services/ApiServies";
import { Res } from "../../resources";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_DATA } from "../../store/actionTypes";

import {
  CustomizeToast,
  CustomizeToastError,
} from "../../services/CommonLogic";
import { emailValidator } from "../../services/Validators";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  loginImg: {
    maxWidth: 150,
    paddingBottom: theme.spacing(2),
  },
  buttonBox: {
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

function ForgetPassword() {
  const styles = makeStyle();
  const history = useHistory();
  const location = useLocation();
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  location.pathname == "/forgetpassword" &&
    global_data.token &&
    history.goBack();

  const forgotHandler = () => {
    if (emailValidator(email)) {
      Post_API(
        Res.apiEndPoints.userRegenerate,
        { email: email.trim() },
        forgotSuccess,
        false
      );
    }
  };
  const forgotSuccess = (response) => {
    if (response?.data?.status) {
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          email: response.data.data.email,
          id: response.data.data.userid,
        },
      });

      CustomizeToast(
        response.data.msg,
        () => history.push("setpassword"),
        Res.variables.toastTimerDelay
      );
    } else {
      CustomizeToastError("Please enter a valid email id");
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Container className={styles.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img
            src="/images/forget.svg"
            alt="Login"
            className={styles.loginImg}
          />
          <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
            <Box
              pb={5}
              fontWeight="fontWeightBold"
              textAlign="center"
              fontSize="h4.fontSize"
            >
              Forget Password
            </Box>
            <form id="forget_password" onSubmit={(e) => e.preventDefault()}>
              <CustomInput
                inputIcon="email"
                inputId="name"
                inputLabe="Email"
                autoFocus={true}
                inputType="email"
                form="forget_password"
                placeholder="example@gmail.com"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Box mt={4} mr={2} width="50%">
                  <RouterLink
                    to={{ pathname: "/login", state: { toDashboard: true } }}
                    style={{ textDecoration: "none" }}
                  >
                    <CustomButton
                      buttonType="contained"
                      buttonColor="secondary"
                      buttonText="Cancel"
                      customStyle={{
                        borderRadius: 40,
                        width: "100%",
                        background: "white",
                        color: "rgb(1, 100, 112)",
                        fontWeight: "800",
                      }}
                    />
                  </RouterLink>
                </Box>
                <Box mt={4} className={styles.buttonBox}>
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    type="submit"
                    form="forget_password"
                    buttonText="Resend Password"
                    customStyle={{ width: "100%", borderRadius: 40 }}
                    clickHandler={forgotHandler}
                  />
                </Box>
              </Box>
            </form>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default ForgetPassword;
