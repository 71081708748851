import { makeStyles } from "@material-ui/core";

const makeStyle = makeStyles((theme) => ({
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  media1: {
    height: 140,
    borderRadius: theme.spacing(1.25),
  },
  cardroot1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: theme.shadows[0],
    flex: 1,
  },
  cardOptions: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(2),
    marginTop: 5,
    color: theme.palette.textSecondary,
    fontSize: theme.spacing(1.6),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
    },
  },
  headingHeight: {
    paddingLeft: theme.spacing(0.7),
    paddingRight: theme.spacing(0.7),
    height: 35,
    color: "#505050",
    [theme.breakpoints.down("md")]: {
      height: 30,
    },
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  description: {
    height: 30,
    paddingLeft: theme.spacing(0.2),
    paddingRight: theme.spacing(0.2),
    [theme.breakpoints.down("md")]: {
      height: 40,
    },
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  carouselButton: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.paper,
    position: "relative",
    zIndex: 800,
    height: 30,
    width: 30,
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
      opacity: 1,
    },
  },
  carousel: {
    position: "relative",
    overflow: "visible",
    opacity: 1,
  },
  viewMore: {
    color: theme.palette.green.main,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 30,
    textTransform: "unset",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  titleBox: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      width: "70%",
    },
  },
}));

export default makeStyle;
