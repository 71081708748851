import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import AuthValidationError from "../../components/AuthValidationError";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import Footer from "../../components/Footer";
import Header from "../../components/header/Header";
import { Res } from "../../resources";
import { Post_API } from "../../services/ApiServies";
import { CustomizeToast } from "../../services/CommonLogic";
import { emailValidator, nameValidator } from "../../services/Validators";
import { GLOBAL_DATA } from "../../store/actionTypes";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    minHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  loginImg: {
    maxWidth: 150,
    paddingBottom: theme.spacing(2),
  },
}));

function Signup() {
  const styles = makeStyle();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const [validationErr, setValidationErr] = useState("");

  const history = useHistory();
  const location = useLocation();
  const global_data = useSelector((state) => state.commonReducer);

  const dispatch = useDispatch();
  React.useEffect(() => {
    global_data.token && history.replace("/");
    window.scrollTo(0, 0);
  }, []);

  const clickHandler = () => {
    setValidationErr("");
    if (
      nameValidator(firstName, "First", setValidationErr) &&
      nameValidator(lastName, "Last", setValidationErr) &&
      emailValidator(email, setValidationErr)
    ) {
      setLoaderState(true);
      Post_API(
        Res.apiEndPoints.signup,
        {
          email: email.trim(),
          fname: firstName.trim(),
          lname: lastName.trim(),
          roles: ["PARENT"],
        },
        signupSuccess,
        false
      );
    }
  };
  const signupSuccess = (response) => {
    if (response.data.status) {
      dispatch({
        type: GLOBAL_DATA,
        payload: { id: response.data.userid, email: email.trim() },
      });
      CustomizeToast(
        response.data.msg,
        () => history.push("setpassword"),
        Res.variables.toastTimerDelay
      );
    } else {
      setValidationErr(response.data.msg);
    }
    setTimeout(() => setLoaderState(false), Res.variables.toastTimerDelay - 50);
  };

  return (
    <div>
      <Header />
      <Container className={styles.root}>
        <form>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {validationErr ? (
              <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
                <AuthValidationError>{validationErr}</AuthValidationError>
              </Grid>
            ) : (
              <img
                src={Res.images.signupImage}
                alt={Res.images.signupImage}
                className={styles.loginImg}
              />
            )}

            <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
              <Box
                pb={5}
                fontWeight="fontWeightBold"
                textAlign="center"
                fontSize="h4.fontSize"
                color="secondary.main"
              >
                Register
              </Box>
              <form id="signup_form" onSubmit={(e) => e.preventDefault()}>
                <CustomInput
                  inputIcon="email"
                  inputId="first_name"
                  inputLabe="First name"
                  form="signup_form"
                  inputType="text"
                  placeholder="John"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value.trim());
                  }}
                />
                <CustomInput
                  inputIcon="email"
                  inputId="last_name"
                  form="signup_form"
                  inputLabe="Last name"
                  inputType="text"
                  placeholder="Smith"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value.trim());
                  }}
                />
                <CustomInput
                  inputIcon="email"
                  inputId="email"
                  form="signup_form"
                  inputLabe="Email"
                  inputType="email"
                  placeholder="example@gmail.com"
                  autoFocus={true}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value.trim());
                  }}
                />
                <Box mx={3} mt={4}>
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    buttonText="Register"
                    customStyle={{ borderRadius: 40 }}
                    type="submit"
                    form="signup_form"
                    clickHandler={clickHandler}
                    ajaxLoader={loaderState}
                    dynamicText
                  />
                </Box>
              </form>
              <Box
                pt={5}
                fontWeight="fontWeightMedium"
                textAlign="center"
                fontSize="body1.fontSize"
              >
                <Box color="dark.main" component="span">
                  Already have an account?
                  <RouterLink to="/login" style={{ textDecoration: "none" }}>
                    <Box color="brand.main" component="span">
                      Login
                    </Box>
                  </RouterLink>
                </Box>
              </Box>
            </Grid>
          </Box>
        </form>
      </Container>
      <Footer />
    </div>
  );
}

export default Signup;
