import React, { useEffect, useState } from "react";
import { Avatar, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
const useStyle = makeStyles((theme) => ({
  authItem: {
    marginRight: theme.spacing(1),
  },
}));
const NavProfileAvatar = () => {
  const styles = useStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const [profileImage, setProfileImage] = useState(
    global_data?.profile?.profileImage
  );

  useEffect(() => {
    if (global_data.profile.profileImage !== "") {
      setProfileImage(global_data.profile.profileImage);
    }
  }, [global_data.profile.profileImage]);

  return <Avatar className={styles.authItem} alt="User" src={profileImage} />;
};

export default NavProfileAvatar;
