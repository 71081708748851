import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GetAppIcon from "@material-ui/icons/GetApp";
import AudioPlayer from "material-ui-audio-player";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ReactPlayer from "react-player";
// import ReactPlayer from 'react-player'
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-use";
import VideoPlayer from "react-video-js-player";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import { Res } from "../../../resources";
import { Get_Page } from "../../../services/ApiServies";
import "./video.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(8.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  maincontainer: {
    padding: theme.spacing(5),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  videoContainer: {
    width: "min-content",
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    boxShadow: theme.shadows[4],
    paddingBottom: theme.spacing(8.625),
  },
  borderclassBottom: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1.25),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(4),

    "@media (max-width:800px)": {
      width: "100%",
    },
  },
  pdfStyle: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  pdfPageStyle: {
    width: "100%",
    boxShadow: theme.shadows[3],
  },
  pdfButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  pdfButtonBox: {
    boxShadow: theme.shadows[2],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    backgroundColor: theme.palette.paper,
    top: -55,
    minWidth: "30%",
  },
  pdfButton: {
    flex: 1,
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  media: {
    height: 180,
    width: 250,
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(1.25),
    "@media (max-width:800px)": {
      height: 170,
    },
  },

  cardrootinnerFont: {
    fontSize: theme.spacing(1.8),
    margin: theme.spacing(3),
    marginLeft: 0,
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.11),
    },
  },

  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    color: theme.palette.paper,
    marginBottom: theme.spacing(2),
    padding: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  pageTitle: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0),
    // marginLeft: theme.spacing(4),
    color: "#0D2860",
  },
  videoContainer: {
    width: "100%",
  },
  video: {
    width: "85%",
    height: 380,
    marginBottom: 100,
  },
  navHeadingText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  topicHeadingText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2.5),
  },
  navArrow: {
    // paddingTop: theme.spacing(5),
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: theme.spacing(2.5),
    },
  },
  description: {
    padding: "1em 0",
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  linkVideo: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function ContenthubDetails() {
  const global_data = useSelector((state) => state.commonReducer);
  const styles = makeStyle();
  const history = useHistory();
  const { courseId, subId, sectionId } = useParams();
  const [completeButton, setCompleteButton] = useState(null);
  const onVideoEnd = (res) => {
    console.log(res);
  };
  const [section, setSection] = useState({});
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileType, setFileType] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const location = useLocation();
  const [poster, setPoster] = useState("");

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    console.log("All pages", nextNumPages);
  }

  function nextPage() {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  }
  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login");
    }
  }, []);
  React.useEffect(() => {
    const getDataSuccess = (response) => {
      console.log("RES", response.data);
      let thisSection = response.data?.sections?.find(
        (section) => section.moduleId == sectionId
      );
      if (thisSection) {
        setSection(thisSection);
        setCompleteButton(thisSection.status);
        setFile(thisSection.file);
        setFileType(thisSection.fileType);
        setLinkUrl(thisSection.linkUrl);
        setPoster(thisSection.image);
      }
    };
    if (courseId === "resource") {
      let thisResource = global_data.authResources?.resources?.find(
        (reso) => reso.id === sectionId
      );
      console.log("thisResource thisResource", thisResource);
      setSection(thisResource);
      setCompleteButton(thisResource.status);
      setFile(thisResource.file);
      setFileType(thisResource.fileType);
      return;
    }
    if (courseId === "podcast") {
      let thisPodcast = global_data.authPodcast?.podcasts?.find(
        (pod) => pod.id === sectionId
      );
      setSection(thisPodcast);
      setCompleteButton(thisPodcast.status);
      setFile(thisPodcast.file);
      setFileType(thisPodcast.fileType);
      return;
    }
    Get_Page(
      `${Res.apiEndPoints.course}${courseId}/${subId}`,
      {},
      getDataSuccess,
      global_data?.token
    );
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500, maxWidth: 1130 }}>
        <Breadcrumbs
          style={{ marginTop: 50, marginBottom: 10 }}
          separator={
            // <NavigateNextIcon fontSize="large" className={styles.navArrow} />
            ""
          }
        >
          <Typography
            variant="h4"
            component="h2"
            className={styles.navHeadingText}
          >
            <Link
              to={`/courses/${courseId}/${subId}`}
              style={{ textDecoration: "none", color: "#0D2860" }}
            >
              {
                global_data.allCourses.find((course) => course.id === courseId)
                  ?.heading
              }
            </Link>
          </Typography>
        </Breadcrumbs>
        <Typography
          variant="h4"
          component="h2"
          className={styles.topicHeadingText}
        >
          {section?.heading}
        </Typography>
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box display="flex" flexDirection="column">
                <Typography
                  component="p"
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: `${section?.description || ""}`,
                  }}
                ></Typography>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "flex-end",
                    // alignItems: "flex-end",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                      justifyContent: "flex-end",
                    }}
                  >
                    {completeButton == 3 && (
                      <Button style={{ marginLeft: 30 }}>
                        <Typography style={{ color: "#2E9F92" }}>
                          Completed
                        </Typography>
                      </Button>
                    )}
                    {!linkUrl ? (
                      <>
                        <a download href={file}>
                          <GetAppIcon
                            style={{
                              color: "black",
                              position: "relative",
                              top: 5,
                            }}
                          />
                        </a>
                        {/* {(completeButton === -1 ||
                          completeButton === 0 ||
                          completeButton === 1 ||
                          completeButton === 2) && (
                          <Button
                            style={{ marginLeft: 20 }}
                            onClick={() => completedPressed()}
                            variant="outlined"
                          >
                            <Typography
                              style={{ color: "#2E9F92", fontSize: 14 }}
                            >
                              Mark as Complete
                            </Typography>
                          </Button>
                        )} */}
                      </>
                    ) : null}
                  </Box>

                  {fileType === "audio" && (
                    <Box my={3} pl={3} width="100%">
                      <AudioPlayer src={file} />
                    </Box>
                  )}
                  {((fileType === "video" &&
                    linkUrl &&
                    linkUrl !== "undefined") ||
                    location.pathname?.includes("talks")) && (
                    <Box className={styles.linkVideo}>
                      {console.log("videoooooooooooooooo")}
                      <ReactPlayer
                        width="100%"
                        height="450px"
                        controls={true}
                        style={{
                          marginBottom: "100px",
                          alignSelf: "flex-start",
                        }}
                        url={linkUrl}
                      />
                    </Box>
                  )}
                  {!linkUrl && fileType === "video" && file && (
                    <Box className={styles.videoContainer}>
                      <VideoPlayer
                        controls={true}
                        src={file}
                        onEnd={onVideoEnd()}
                        poster={section?.image}
                        className={styles.video}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              {fileType === "pdf" && (
                <Box style={{ maxWidth: "100%", minHeight: "10%" }}>
                  <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                    className={styles.pdfStyle}
                  >
                    <Page
                      className={styles.pdfPageStyle}
                      onRenderSuccess={() => {
                        window.scrollTo(0, 200);
                      }}
                      pageNumber={currentPage}
                    />
                  </Document>

                  <Box className={styles.pdfButtonContainer}>
                    <Box className={styles.pdfButtonBox}>
                      <IconButton
                        className={styles.pdfButton}
                        onClick={prevPage}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <Box p={1} flex={5} textAlign="center">
                        {currentPage} of {numPages}
                      </Box>
                      <IconButton
                        className={styles.pdfButton}
                        onClick={nextPage}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default ContenthubDetails;
