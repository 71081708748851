import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import { Res } from "../../../resources";
import { Get_API, Post_API } from "../../../services/ApiServies";
import { GLOBAL_DATA } from "../../../store/actionTypes";
import Notification from "../../../components/Notification";
import { useParams } from "react-router-dom";
import { CustomizeToast } from "../../../services/CommonLogic";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  pageBox: {
    maxWidth: 1140,
    marginRight: "auto",
    marginLeft: "auto",
    minHeight: "80vh",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {},
    [theme.breakpoints.only("xs")]: { padding: theme.spacing(1) },
  },
  emptyNotification: {
    textAlign: "center",
    color: theme.palette.secondary.main,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4),
  },
  headingText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  navigationBox: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(1.2),
    padding: theme.spacing(4),
  },
}));

function NotificationPage() {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const { notificationId } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  useEffect(() => {
    if (notificationId) {
      let element = document.getElementById(`notification_${notificationId}`);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - element.offsetHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);

  const getNotificationSuccess = useCallback(
    (response) => {
      if (response.data.success) {
        const allNotification = response.data.object;
        console.log("NOTIFICATION Response ", response);
        console.log("NOTIFICATION-----", response.data.object);
        dispatch({
          type: GLOBAL_DATA,
          payload: { notifications: allNotification },
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    Get_API(
      Res.apiEndPoints.allNotification + global_data.id,
      {},
      getNotificationSuccess,
      true
    );
  }, [global_data.id, dispatch, getNotificationSuccess]);

  const watchNotification = (notificationId) => {
    console.log("Nid---------------------", notificationId);
    const watchNotificationSuccess = (response) => {
      console.log("NOTIFICATION-----", response);
      let filtertedNotifications = global_data?.notifications?.filter(
        (item) => item?.notification?.notificationId != notificationId
      );
      dispatch({
        type: GLOBAL_DATA,
        payload: { notifications: filtertedNotifications },
      });
      CustomizeToast(
        "Notification deleted",
        () => {},
        Res.variables.toastTimerDelay
      );
    };
    Post_API(
      Res.apiEndPoints.watchNotification,
      { userid: global_data.id, notificationId: notificationId },
      watchNotificationSuccess
    );
  };

  return (
    <div>
      <Header />
      <div className={styles.pageContainer}>
        <Container className={styles.pageBox}>
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            Notifications
          </Typography>
          {global_data.notifications && global_data.notifications?.length ? (
            <Box className={styles.navigationBox}>
              {global_data?.notifications
                ?.reverse()
                ?.filter((item) => !item.watched)
                ?.map((item, i) => {
                  return (
                    <Notification
                      key={item?.notification?.notificationId}
                      notificationTitle={item?.notification?.message}
                      notificationDetail={item?.notification?.message}
                      notificationTime={item?.notification?.sendTime}
                      imgUrl={item?.imageurl}
                      nid={item?.notification?.notificationId}
                      notificationClickHandel={watchNotification}
                      expanded={
                        notificationId &&
                        notificationId === item?.notification?.notificationId
                      }
                    />
                  );
                })}
            </Box>
          ) : (
            <Box className={styles.emptyNotification}>No Notifications</Box>
          )}
        </Container>
        <Footer />
      </div>
    </div>
  );
}

export default NotificationPage;
