// const useMobileData = () => {
//   const [localMobileSupport, setlocalMobileSupport] = useState({});
//   const [localMobileHelp, setlocalMobileHelp] = useState({});

//   useEffect(() => {

//     let promises = [];

//     Get_Page(Res.apiEndPoints.pagesUrl + "support", {}, (res) => {
//       if (res.data) {
//         setlocalMobileSupport(res.data);
//       }
//     });
//     Get_Page(Res.apiEndPoints.pagesUrl + "help", {}, (res) => {
//       if (res.data) {
//         setlocalMobileHelp(res.data);
//       }
//     });
//   }, []);

//   return { localMobileSupport, localMobileHelp };
// };

// export default useMobileData;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const useMobileData = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localMobileSupport, setlocalMobileSupport] = useSessionStorage(
    keys.MOBILE_SUPPORT,
    null
  );
  const [localMobileHelp, setlocalMobileHelp] = useSessionStorage(
    keys.MOBILE_HELP,
    null
  );
  const history = useHistory();

  const fetchSupport = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const mobileSupport = res.data;
      sessionStorage.removeItem(keys.MOBILE_SUPPORT);
      setlocalMobileSupport({
        mobileSupport,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "support",
      {},
      getPageSuccess,
      global_data?.token
    );
  };
  const fetchHelp = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const mobileHelp = res.data;
      sessionStorage.removeItem(keys.MOBILE_HELP);
      setlocalMobileHelp({
        mobileHelp,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "help",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localMobileSupport === null) {
      fetchSupport();
    }
    if (localMobileHelp === null) {
      fetchHelp();
    }
  }, []);

  return { ...localMobileSupport, ...localMobileHelp, fetchSupport, fetchHelp };
};

export default useMobileData;
