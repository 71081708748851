import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import Header from "../../../components/header/Header";
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useCommunityPage from "../../../resources/localStorage/hooks/useCommunityPage";
import CreateIcon from "@material-ui/icons/Create";
import useBottomSections from "../../../resources/localStorage/hooks/useBottomSections";
import CommunityNoAuth from "../../unAuth/CommunityNoAuth";
import PageBottomSection from "../../../components/PageBottomSection";
import ProgressLoader from "../../../components/ProgressLoader";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(11),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  pageBox: {
    maxWidth: 1125,
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: theme.spacing(3),
    minHeight: "80vh",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageBox1: {
    marginTop: 80,
    maxWidth: 1125,
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: theme.spacing(3),
    minHeight: "80vh",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
    },
  },
  headingText: {
    paddingTop: theme.spacing(9),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },

  frameStyle: {
    border: "none",
    marginTop: theme.spacing(4),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(2.4),
    padding: theme.spacing(1),
    minHeight: 720,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      boxShadow: theme.shadows[0],
    },
  },
  pageText: {
    // paddingRight: theme.spacing(6),
    // [theme.breakpoints.down("xs")]: {
    //   paddingRight: theme.spacing(0),
    //   textAlign: "center",
    //   marginLeft:20,
    //   marginRight:20
    // },
    color: "#0D2860",
    fontSize: 26,
    fontWeight: "600",
    marginBottom: 15,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  pageText1: {
    color: "#3B3A3C",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  textContainer: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
      marginRight: 20,
      justifyContent: "center",
    },
  },
}));

function Community() {
  const styles = makeStyle();
  const history = useHistory();

  const global_data = useSelector((state) => state.commonReducer);
  const { community: communityBottomSection } = useBottomSections();
  const [displayLoader, setDisplayLoader] = useState(true);

  const { communityArticle } = useCommunityPage();

  const hideLoader = () => {
    setDisplayLoader(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  const iframeSrc =
    "https://forum.myfamilycoach.ml/index.php?wtbToken=" + global_data.wtbToken;
  // useEffect(() => {
  //   if (global_data.email === "") {
  //     history.push("/login");
  //   }
  // }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />
      {!global_data.email && (
        <Container className={styles.pageBox1}>
          <CommunityNoAuth />
        </Container>
      )}

      {global_data.email && (
        <Container className={styles.pageBox}>
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            My Community
          </Typography>

          <Box
            className={styles.frameStyle}
            display={displayLoader ? "block" : "none"}
            width="100%"
            height="400px"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ProgressLoader seconds={2} hideLoader={hideLoader} />
            </Box>
          </Box>

          <Box>
            <iframe
              style={{ opacity: displayLoader ? 0 : 1 }}
              height={displayLoader ? "600px" : "1000px"}
              className={styles.frameStyle}
              title="MyFamilyCoach Community Forum"
              src={iframeSrc}
              width="100%"
            ></iframe>
          </Box>
          <Box>
            <Box className={styles.buttonContainer}>
              <Typography className={styles.pageText}>
                {communityArticle?.title}
              </Typography>
              {global_data.isEditable && (
                <IconButton
                  onClick={() => {
                    history.push(
                      `/update/pageItem/communityArticle/${communityArticle?.id}`
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              )}
            </Box>
            <Box className={styles.textContainer}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: `${communityArticle?.description || ""}
                `,
                }}
              ></Typography>
            </Box>
          </Box>
          <Grid>
            <PageBottomSection
              page="community"
              item={communityBottomSection}
              buttonText="Book"
              // title="Need parenting advice?" des="Enjoy our expert-led classes from the comfort of your sofa" btnName="Book"
            />
          </Grid>
        </Container>
      )}
      {global_data.isEditable && (
        <Container className={styles.pageBox1}>
          <CommunityNoAuth />
        </Container>
      )}
      <Footer />
    </div>
  );
}

export default Community;
