import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  makeStyles,
} from "@material-ui/core";
import { Res } from "../resources";

const makeStyle = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  modalBox: {
    width: "100%",
  },
  dialogBox: {
    overflow: "hidden",
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  queryButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    textTransform: "unset",
    color: theme.palette.paper,
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  congImg: {
    height: 400,
  },
  modalText: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  }
}));

const QueryModal = (props) => {
  const styles = makeStyle();
  return (
    <Dialog
      className={styles.modalBox}
      open={props.modalState}
      onClose={props.onClose}
    >
      <DialogContent className={styles.dialogBox}>
        {props.queryText ? (
          <Box className={styles.modalText}>{props.queryText}</Box>
        ) : (
          <Box className={styles.modalImage}>
            <img
              className={styles.congImg}
              src={Res.images.congImg}
              alt={Res.images.congImg}
              width="100%"
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions className={styles.btnContainer}>
        <Button
          startIcon={<Icon>timer</Icon>}
          onClick={props.onYes}
          className={styles.queryButton}
        >
          {props.yesText}
        </Button>
        {props.closeText ? (
          <Button
            startIcon={<Icon>people_outline</Icon>}
            onClick={props.onClose}
            className={styles.queryButton}
          >
            {props.closeText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default QueryModal;
