import "@brainhubeu/react-carousel/lib/style.css";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import {
  if_Attended,
  if_Book,
  if_Booked,
  if_Join,
  if_Mark_Joined,
  if_NoSlots,
} from "../../../../utils/class_webinar_status";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(18) },
  },
  maincontainer: {
    padding: theme.spacing(1),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  videoContainer: {
    width: "min-content",
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    boxShadow: theme.shadows[4],
  },
  borderclassBottom: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1.25),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(4),

    "@media (max-width:800px)": {
      width: "100%",
    },
  },
  timeBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },

  media: {
    width: "100%",
    minHeight: 220,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  courseIcon: {
    color: "#75A8F4",
    fontSize: theme.spacing(2.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(2),
    },
  },
  sectionrootinnerFont: {
    fontSize: theme.spacing(1.4),
    marginLeft: theme.spacing(1),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.11),
    },
  },

  sectionrootinnerDescription: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    width: "80%",
  },
  sectionrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    padding: theme.spacing(0.6),
    // marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  pageTitle: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0),
    color: "#0D2860",
  },
}));

const WebinarButton = ({
  fromCourseModulePage,
  section,
  addModule,
  completeCall,
}) => {
  const styles = makeStyle();
  return (
    <Box>
      {section?.state === 4 && section?.webinar && (
        <Box mt={2} display="flex" alignItems="center">
          <CancelIcon style={{ color: "#C5C4C4", margin: 2 }} />
          <Typography variant="h5">Expired</Typography>
        </Box>
      )}
      {/* </Box> */}

      {/* <Box py={4}> */}
      {section?.state === -1 && (
        <Box className={styles.timeBox} style={{ marginBottom: 30 }}>
          <CancelIcon
            // className={styles.courseIcon}
            style={{ color: "#C5C4C4" }}
          />
          <Box style={{ marginLeft: 5 }}>Not Attended</Box>
        </Box>
      )}

      {if_NoSlots(section) && (
        <Button
          size="small"
          className={styles.sectionrootinnerButton}
          style={{
            backgroundColor: "#C5C4C4",
            cursor: "auto",
          }}
        >
          No Slots Available
        </Button>
      )}

      {/* IF BOOK */}
      {if_Book(section) && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems={fromCourseModulePage ? "flex-end" : "flex-start"}
          alignSelf="flex-end"
        >
          <Button
            size="small"
            className={styles.sectionrootinnerButton}
            style={{ backgroundColor: "#10265F" }}
            onClick={() =>
              addModule(section, { updateSections: fromCourseModulePage })
            }
          >
            Book
          </Button>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="baseline"
            py={fromCourseModulePage ? 0.5 : 2}
          >
            <Box>
              {section?.numBooked
                ? section?.numSlots - section?.numBooked
                : section?.numSlots}{" "}
            </Box>
            <Box pl={0.2}>
              out of {section?.numSlots ? section?.numSlots : 0} Slots Available
            </Box>
          </Box>
        </Box>
      )}

      {/*  FOR BOOKED */}
      {if_Booked(section) && (
        <Button
          size="small"
          className={styles.sectionrootinnerButton}
          style={{ backgroundColor: "#C5C4C4" }}
        >
          Booked
        </Button>
      )}

      {/*  FOR JOIN */}
      {if_Join(section) && (
        <Button
          size="small"
          className={styles.sectionrootinnerButton}
          style={{ backgroundColor: "#10265F", margin: 0 }}
          onClick={() => {
            completeCall(section);
          }}
        >
          <a
            target="_blank"
            style={{
              color: "#ffF",
              textDecoration: "none",
            }}
            href={section?.webinarLink}
          >
            Join
          </a>
        </Button>
      )}

      {/* IF ATTENDED */}
      {if_Attended(section) && (
        <Button color="primary">
          <Box className={styles.timeBox} style={{ marginBottom: 30 }}>
            <CheckCircleIcon />
            <Box style={{ marginLeft: 5 }}>Attended</Box>
          </Box>
        </Button>
      )}
      {/* {section?.status === 3 && section?.state !== 2 && (
                  <Box
                    className={styles.iconBox}
                    style={{
                      marginBottom: 20,
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleIcon color="primary" />
                    <Box style={{ marginLeft: 5 }}>Attended</Box>
                  </Box>
                )} */}

      {if_Mark_Joined(section) && (
        <Button
          size="small"
          className={styles.sectionrootinnerButton}
          style={{ backgroundColor: "#10265F" }}
          onClick={() => {
            completeCall(section);
          }}
        >
          Mark as Joined
        </Button>
      )}
    </Box>
  );
};

export default WebinarButton;
