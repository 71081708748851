import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const makeStyle = makeStyles((theme) => ({
  appDetailBox: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
  },
  appDetailTitle: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightDarkBold,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      fontSize: theme.typography.h4.fontSize,
    },
  },
  firstAppImg: {
    marginRight: theme.spacing(2),
    maxWidth: 130,
    maxHeight: 40,
    "@media (max-width:800px)": {
      marginRight: theme.spacing(1),
    },
  },
}));

const HomeBanner = (props) => {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();

  return (
    <Grid container className={styles.appDetailBox}>
      <Grid item xs={12} className={styles.appDetail}>
        <Typography className={styles.appDetailTitle} component="h4">
          {props.appData.appDetailHeading}
        </Typography>
        <Box py={2} pr={2} fontSize={{ xs: 16, sm: 20 }}>
          {props.appData.appDetailParagraph}
        </Box>
        <Box>
          {global_data.isEditable && (
            <CreateIcon
              onClick={() => {
                history.push(
                  `/update/pageItem/playStoreLink/${props.journalId}`
                );
              }}
              style={{ fontSize: "1.3em", cursor: "pointer" }}
            />
          )}
          <a target="_blank" href={props.playStoreLink}>
            <img
              alt="Get from App Store"
              className={styles.firstAppImg}
              src="/images/AppleStore.png"
              width="90%"
            />
          </a>
          {global_data.isEditable && (
            <CreateIcon
              onClick={() => {
                history.push(
                  `/update/pageItem/appleStoreLink/${props.journalId}`
                );
              }}
              style={{ fontSize: "1.3em", cursor: "pointer" }}
            />
          )}
          <a target="_blank" href={props.appleStoreLink}>
            <img
              alt="Get from Google Play"
              className={styles.firstAppImg}
              src="/images/AndroidStore.png"
              width="90%"
            />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomeBanner;
