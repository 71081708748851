import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import h2p from "html2plaintext";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import QueryModal from "../../../../components/QueryModal";
import getCardRoute from "../../../../utils/getCardRoute";
import useAuthoring from "../hooks/useAuthoring";
import useLearning from "../hooks/useLearning";

const makeStyle = makeStyles((theme) => ({
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  media1: {
    height: 140,
    borderRadius: theme.spacing(1.25),
  },
  containerHeader: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  cardroot1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    boxShadow: theme.shadows[0],
    height: "100%",
    flex: 1,
  },
  cardOptions: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(2),
    color: theme.palette.textSecondary,
    fontSize: theme.spacing(1.6),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
    },
  },
  headingHeight: {
    paddingLeft: theme.spacing(0.7),
    paddingRight: theme.spacing(0.7),
    height: 35,
    color: "#505050",
    [theme.breakpoints.down("md")]: {
      height: 30,
    },
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  description: {
    height: 30,
    paddingLeft: theme.spacing(0.2),
    paddingRight: theme.spacing(0.2),
    [theme.breakpoints.down("md")]: {
      height: 40,
    },
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  carouselButton: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.paper,
    position: "relative",
    zIndex: theme.zIndex.modal,
    height: 30,
    width: 30,
    top: -50,
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
      opacity: 1,
    },
  },
  carousel: {
    position: "relative",
    overflow: "visible",
    opacity: 1,
  },
  viewMore: {
    color: theme.palette.green.main,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 30,
    textTransform: "unset",
  },
  cardrootinnerIcon: {
    fontSize: theme.spacing(1.3),
    textAlign: "center",
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.5),
    },
  },
}));

const ContentItem = (props) => {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();
  const { subscribeContent, unSubscribeContent } = useLearning();
  const { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal } =
    useAuthoring();

  return (
    <Box className={styles.borderclass}>
      <Box
        display="flex"
        className={styles.containerHeader}
        justifyContent="space-between"
        mx={0.6}
      >
        <Box fontSize={18} pb={0.5} fontWeight="fontWeightBold">
          {props.carouselTitle}
        </Box>
        <Box fontSize={15} pl={2} mb={2}>
          {props.carouselSubTitle}
        </Box>
      </Box>
      <Box className={styles.cardroot1Box}>
        <Box className={styles.carousel}>
          <QueryModal
            modalState={deleteModalFlag}
            queryText="Are you sure you want to delete this ?"
            yesText="Yes"
            closeText="No"
            onYes={() => {
              deleteContent();
            }}
            onClose={() => {
              closeDeleteModal();
            }}
          />
          <Grid container>
            {props?.carouselItems?.map((card, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={card.type === "class" ? 4 : 3}
                  key={i}
                >
                  <Card className={styles.cardroot1}>
                    <Box display="flex" justifyContent="flex-end">
                      {global_data.isEditable && (
                        <>
                          <IconButton
                            onClick={() => {
                              history.push(
                                `/update/${card.type}/${card.contentId}/${card.id}`
                              );
                            }}
                          >
                            <CreateIcon style={{ fontSize: 21 }} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              openDeleteModal(card);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: 21 }} />
                          </IconButton>
                        </>
                      )}
                    </Box>
                    <CardActionArea>
                      <RouterLink
                        style={{ textDecoration: "none" }}
                        to={global_data.id ? getCardRoute(card) : `/login`}
                      >
                        <Box
                          fontSize={{ xs: 12, md: 15 }}
                          lineHeight={1.1}
                          className={styles.headingHeight}
                          fontWeight={600}
                          fontFamily="poppins"
                          overflow="hidden"
                          mb={1}
                          mt={1}
                        >
                          {card?.heading}
                        </Box>
                        <CardMedia
                          className={styles.media1}
                          image={card.image}
                          title={card?.heading}
                        />
                      </RouterLink>

                      <CardContent style={{ padding: "5px", cursor: "auto" }}>
                        <Box
                          lineHeight={1.1}
                          color="customGrey.dark"
                          fontFamily="poppins"
                          height="5vw"
                          className={styles.description}
                          mb={1}
                          dangerouslySetInnerHTML={{
                            __html: `${
                              card?.description?.length > 83
                                ? h2p(card?.description)?.slice(0, 82) + "..."
                                : card?.description || ""
                            }`,
                          }}
                        />
                      </CardContent>
                    </CardActionArea>

                    <CardActions>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        flexDirection="row"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          textAlign="center"
                          fontSize={10}
                          pt={1}
                        >
                          {card.durationHours && card.durationHours !== "0" ? (
                            <Box pr={2}>{card.durationHours} hours</Box>
                          ) : null}
                          {card.durationMinutes && card.durationMinutes !== "0"
                            ? card.durationMinutes + " minutes"
                            : null}
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          textAlign="center"
                          fontSize={10}
                        >
                          {card?.localWebinarDate ? card?.localWebinarDate : ""}
                          {card?.localWebinarTime ? card?.localWebinarTime : ""}
                        </Box>
                        {card.type === "webinar" || card.type === "class" ? (
                          <Box
                            display="flex"
                            flexDirection="row"
                            textAlign="center"
                            fontSize={10}
                          >
                            <Box pl={0.3} py={2}>
                              {card?.numBooked && card?.numSlots
                                ? Number(card?.numSlots) -
                                  Number(card?.numBooked)
                                : card?.numSlots}{" "}
                              out of <br />
                              {card?.numSlots ? card?.numSlots : 0} Slots
                              Available
                            </Box>
                          </Box>
                        ) : null}
                        {global_data.id ? (
                          (props.carouselTitle === "Support Talks" &&
                            card?.isMyLearning) ||
                          (props.carouselTitle !== "Support Talks" &&
                            card?.status > 0) ? (
                            <IconButton
                              style={{ padding: 5 }}
                              onClick={() => unSubscribeContent(card)}
                            >
                              <FavoriteIcon
                                style={{ color: "#DD2979", fontSize: 16 }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => subscribeContent(card)}
                              style={{ padding: 5 }}
                            >
                              <FavoriteBorderIcon
                                style={{ fontSize: 16, color: "#75A8F4" }}
                              />
                            </IconButton>
                          )
                        ) : null}
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentItem;
