import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import useMobileData from "../resources/localStorage/hooks/useMobileData";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(7),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },

  iconClass: {
    fontSize: 18,
    color: "#000",
  },
  mainContainer: {
    borderTop: "2px solid #ccc",
    padding: theme.spacing(8),
    paddingTop: theme.spacing(2),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
      textAlign: "center",
    },
  },
  boxStyles: {
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.7),
  },
  supportText: {
    fontSize: theme.spacing(3),
  },
  bigGrid: {
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  headingText: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const ModileData = () => {
  const { mobileSupport, mobileHelp } = useMobileData();
  const history = useHistory();
  const styles = makeStyle();

  return (
    <Grid container className={styles.mainContainer}>
      <Box>
        <Typography variant="h5">MyFamilyJournal Content</Typography>

        <Box className={styles.boxStyles}>
          <Box className={styles?.buttonContainer}>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Support
            </Typography>
            <IconButton
              onClick={() => {
                history.push(
                  `/update/appPolicies/policies/${mobileSupport?.id}`
                );
              }}
            >
              <CreateIcon className={styles.iconClass} />
            </IconButton>
          </Box>
          <Box className={styles?.buttonContainer}>
            <Typography variant="h6">
              Email:{" " + mobileSupport?.email}
            </Typography>
          </Box>
          <Box className={styles?.buttonContainer}>
            <Typography variant="h6">
              Phone Number:{" " + mobileSupport?.phoneNumber}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.boxStyles}>
          <Box className={styles?.buttonContainer}>
            <Typography variant="h6">
              {mobileSupport?.terms_conditions?.title}
            </Typography>
            <IconButton
              onClick={() => {
                history.push(
                  `/update/pageItem/terms_conditions/${mobileSupport?.terms_conditions?.id}`
                );
              }}
            >
              <CreateIcon className={styles.iconClass} />
            </IconButton>
          </Box>
        </Box>
        <Box className={styles.boxStyles}>
          <Box className={styles?.buttonContainer}>
            <Typography variant="h6">
              {mobileSupport?.privacy_policies?.title}
            </Typography>
            <IconButton
              onClick={() => {
                history.push(
                  `/update/pageItem/privacy_policies/${mobileSupport?.privacy_policies?.id}`
                );
              }}
            >
              <CreateIcon className={styles.iconClass} />
            </IconButton>
          </Box>
        </Box>
        <Box className={styles.boxStyles}>
          <Box className={styles?.buttonContainer}>
            <Typography variant="h6">{mobileHelp?.title}</Typography>
            <IconButton
              onClick={() => {
                history.push(`/update/pageItem/help/${mobileHelp?.id}`);
              }}
            >
              <CreateIcon className={styles.iconClass} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ModileData;
