const keys = {
  WHOWEARE_PAGE: "WHOWEARE_PAGE",
  HOME_PAGE: "HOME_PAGE",
  BOOKING: "BOOKING",
  TEAM: "TEAM",
  COACHING_PAGE: "COACHING_PAGE",
  CONTENT_HUB_PAGE: "CONTENT_HUB_PAGE",
  FAQ_PAGE: "FAQ_PAGE",
  JOURNAL_PAGE: "JOURNAL_PAGE",
  COMMUNITY_PAGE: "COMMUNITY_PAGE",
  COURSES: "COURSES",
  RESOURCES: "RESOURCES",
  PODCASTS: "PODCASTS",
  CLASSES: "CLASSES",
  TERMS_OF_BUSINESS: "TERMS_OF_BUSINESS",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  COOKIE_POLICY: "COOKIE_POLICY",
  BOTTOMSECTIONS: "BOTTOMSECTIONS",
  FOOTER: "FOOTER",
  MOBILE_SUPPORT: "MOBILE_SUPPORT",
  MOBILE_HELP: "MOBILE_HELP",
};
export default keys;
