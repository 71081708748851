import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BookIcon from "@material-ui/icons/Book";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaymentModal from "../../../components/PaymentModal";
import QueryModal from "../../../components/QueryModal";
import { Res } from "../../../resources";
import { Post_API } from "../../../services/ApiServies";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../services/CommonLogic";
import { GLOBAL_DATA } from "../../../store/actionTypes";
import "./components/carousel.css";
import useAllCourses from "./hooks/useAllCourses";
import useAuthoring from "./hooks/useAuthoring";
import useLearning from "./hooks/useLearning";
import usePayment from "./hooks/usePayment";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const makeStyle = makeStyles((theme) => ({
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0.7),
      paddingRight: theme.spacing(0.7),
    },
  },
  media1: {
    height: 240,
    borderRadius: theme.spacing(1.25),
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
  badge: {
    width: "100%",
  },
  cardroot1: {
    boxShadow: theme.shadows[2],
    height: "100%",
    flex: 1,
  },
  cardOptions: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(2),
    color: theme.palette.textSecondary,
    fontSize: theme.spacing(1.6),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1),
    },
  },

  carouselButton: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.paper,
    position: "relative",
    zIndex: 800,
    height: 30,
    width: 30,
    top: -30,
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
      opacity: 1,
    },
  },
  carousel: {
    position: "relative",
    overflow: "visible",
    opacity: 1,
  },

  cardrootinnerFont: {
    textTransform: "none",
    fontSize: theme.spacing(1.5),
    "@media (max-width:800px)": {
      fontSize: 9,
    },
  },
  cardrootinnerButton1: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "0.8rem",
    textAlign: "center",
    color: theme.palette.paper,
    padding: theme.spacing(1),
    paddingLeft: "1em",
    paddingRight: "1em",
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
      marginTop: theme.spacing(1),
    },
  },
  noCursor: {
    cursor: "auto",
  },
  cardrootinnerButton: {
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(2),
    color: theme.palette.paper,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "uppercase",
    marginLeft: 5,
    marginBottom: theme.spacing(1),
    padding: "1em",
    [theme.breakpoints.down("md")]: {
      maxWidth: 60,
      fontSize: ".6rem",
    },
  },
  viewMore: {
    color: theme.palette.green.main,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 30,
    textTransform: "unset",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13.5,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  titleBox: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      width: "70%",
    },
  },
  cardroot1Box: {
    position: "relative",
  },
  cardrootinnerFontheading: {
    fontSize: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.75),
    },
  },
}));

const CustomCarouselCourses = (props) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [carouselCount, setCarouselCount] = useState(
    window.innerWidth <= 760 ? 1 : 2
  );
  const [allCourses, setAllCourses] = useAllCourses();

  const {
    subscribeContent,
    unSubscribeContent,
    onLearningModalClose,
    clickCourseLikeButton,
    courseOpen,
    subscribeModal,
    unSubscribeModal,
    selectedCourse,
  } = useLearning();

  const {
    directBuyCourse,
    showPaymentProgress,
    cancelPayment,
    paymentCall,
    buyCourse,
    directPaymentCall,
    paymentInProgress,
    paymentModal,
    directPaymentModal,
    selectedPaymentCourse
  } = usePayment({ subscribeContent });
  const { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal } =
    useAuthoring();

  const linkChange = () => {
    history.push(props.link);
  };

  useEffect(() => {
    function resize() {
      console.log("Window width****", window.innerWidth, carouselCount);
      if (window.innerWidth <= 660) {
        setCarouselCount(1);
      } else if (window.innerWidth > 660) {
        setCarouselCount(2);
      }
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [carouselCount]);

  return (
    <Box className={styles.borderclass}>
      {global_data.isEditable && (
        <Box display="flex" py={1} justifyContent="flex-end">
          <Button
            style={{ padding: 5, textTransform: "none" }}
            onClick={() => history.push("/create/course")}
          >
            <AddCircleIcon color="primary" size="large" />
            <Typography variant="caption">Add Course</Typography>
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mx={0.6}>
        <Box className={styles.titleBox}>
          <Box fontSize={18} pb={0.5} fontWeight="fontWeightBold">
            {props.carouselTitle}
          </Box>
          <Box fontSize={13} mb={2}>
            {props.shortText}
          </Box>
        </Box>
        <Button
          className={styles.viewMore}
          size="small"
          onClick={() => linkChange()}
        >
          View More
        </Button>
      </Box>
      <Box className={styles.cardroot1Box}>
        <Carousel
          responsive={responsive}
          speed={500}
          slidesToShow={carouselCount}
          slidesToScroll={1}
          infinite={true}
        >
          {allCourses?.map((card, i) => (
            <Box p={1}>
              <Card
                className={styles.cardroot1}
                style={{ cursor: "auto" }}
                key={i}
              >
                <CardActionArea>
                  <CardMedia
                    className={styles.media1}
                    image={card.image}
                    title={card?.heading}
                    onClick={() => courseOpen(card)}
                  />
                  <CardContent className={styles.noCursor}>
                    <Box className={styles.cardrootinner}>
                      <Grid container style={{ height: 60 }}>
                        <Grid item xs={9}>
                          <Typography>
                            {card?.title ? card?.title : card?.heading}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            color="primary"
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                            className={styles.cardrootinnerButton}
                          >
                            {!card?.price || card?.price === "0"
                              ? "Free"
                              : "₤ " + card?.price}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="flex-start">
                            <Grid container style={{ marginTop: "1em" }}>
                              {card.totalModules ? (
                                <Grid item xs={4}>
                                  <IconButton
                                    title="modules"
                                    style={{ paddingLeft: 0 }}
                                  >
                                    <Box display="flex">
                                      <BookIcon
                                        style={{
                                          fontSize: 22,
                                          color: "#75A8F4",
                                        }}
                                      />
                                      <Typography
                                        variant="body2"
                                        color="textPrimary"
                                        component="p"
                                        style={{
                                          padding: "0 1em",
                                          paddingTop: "3px",
                                        }}
                                        className={styles.cardrootinnerFont}
                                      >
                                        {card.totalModules
                                          ? card.totalModules +
                                            ` Module${
                                              card.totalModules === 1 ? "" : "s"
                                            }`
                                          : ""}
                                      </Typography>
                                    </Box>
                                  </IconButton>
                                </Grid>
                              ) : null}
                              <Grid item xs={4}>
                                <IconButton
                                  style={{ marginLeft: "0.2rem" }}
                                  onClick={() => clickCourseLikeButton(card)}
                                >
                                  {global_data.token && (
                                    <Icon
                                      style={{
                                        fontSize: 22,
                                        color: card.isMyLearning
                                          ? "#d656a1"
                                          : "#75A8F4",
                                      }}
                                    >
                                      {card.isMyLearning
                                        ? "favorite"
                                        : "favorite_border"}
                                    </Icon>
                                  )}
                                  {!global_data.token && (
                                    <Icon
                                      style={{
                                        fontSize: 22,
                                        color: card.isMyLearning
                                          ? "#d656a1"
                                          : "#75A8F4",
                                      }}
                                    >
                                      favorite_border
                                    </Icon>
                                  )}
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    component="p"
                                    style={{ padding: "0 1em" }}
                                    className={styles.cardrootinnerFont}
                                  >
                                    My Learning
                                  </Typography>
                                </IconButton>
                                {paymentInProgress &&
                                  selectedCourse.id == card.id && (
                                    <Box py={1.5}>
                                      <CircularProgress
                                        style={{
                                          color: "#75A8F4",
                                          height: 21,
                                          width: 21,
                                        }}
                                      />
                                    </Box>
                                  )}
                              </Grid>
                              {card.totalModules ? null : <Grid item xs={3} />}

                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {card?.needToBuy && (
                                  <Button
                                    onClick={() => {
                                      if (!global_data.id) {
                                        return history.push("/login");
                                      }
                                      card.isMyLearning
                                        ? directPaymentCall(card)
                                        : paymentCall(card);
                                    }}
                                    className={styles.cardrootinnerButton1}
                                  >
                                    Buy Course
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Box display="flex" py={1}>
                    {global_data.isEditable && (
                      <>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/update/${card.type}/courses/${card.id}`
                            );
                          }}
                        >
                          <CreateIcon style={{ fontSize: 21 }} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            openDeleteModal(card);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: 21 }} />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
          ))}
        </Carousel>
      </Box>
      <QueryModal
        modalState={subscribeModal}
        yesText="Save to My Information"
        onYes={() => subscribeContent(selectedCourse, setAllCourses)}
        onClose={onLearningModalClose}
      />
      <QueryModal
        modalState={unSubscribeModal}
        yesText="Unsubscribe!"
        closeText="Explore More"
        queryText="Are you sure you want to unsubscribe ?"
        onYes={() => unSubscribeContent(selectedCourse)}
        onClose={onLearningModalClose}
      />
      <PaymentModal
        modalState={paymentModal}
        yesText="Save to My Information"
        subscribeMethod={buyCourse}
        onClose={cancelPayment}
        showPaymentProgress={showPaymentProgress}
        selectedDate={selectedPaymentCourse}
      />
      <PaymentModal
        modalState={directPaymentModal}
        yesText="Save to My Information"
        subscribeMethod={directBuyCourse}
        onClose={cancelPayment}
        showPaymentProgress={showPaymentProgress}
        selectedDate={selectedPaymentCourse}
      />
      <QueryModal
        modalState={deleteModalFlag}
        queryText="Are you sure you want to delete this ?"
        yesText="Yes"
        closeText="No"
        onYes={() => {
          deleteContent();
        }}
        onClose={() => {
          closeDeleteModal();
        }}
      />
    </Box>
  );
};

export default CustomCarouselCourses;
