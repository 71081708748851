import { Box, Button, IconButton, Typography } from "@material-ui/core";
import React from "react";
import baseContent from "../../services/Content";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Res } from "../../resources";
import { useHistory } from "react-router";
import CreateIcon from "@material-ui/icons/Create";
import { useSelector } from "react-redux";
import useCommunityPage from "../../resources/localStorage/hooks/useCommunityPage";
import CustomButton from "../../components/CustomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pageText: {
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
      textAlign: "left",
      marginLeft: 20,
      marginRight: 20,
    },
  },
  pageText1: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      alignSelf: "center",
    },
  },
  pageTextMain: {
    color: "#0D2860",
    fontSize: 26,
    fontWeight: "600",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  imgStyle: {
    [theme.breakpoints.down("xs")]: {
      maxHeight: 300,
      maxWidth: 300,
      alignSelf: "center",
      justifyContent: "center",
      marginLeft: 25,
      marginRight: 25,
    },
  },

  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(2),
    fontSize: "1rem",
    color: theme.palette.paper,
    marginTop: theme.spacing(4),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
    marginBottom: theme.spacing(1.5),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

export default function CommunityNoAuth() {
  const classes = useStyles();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const { unAuthCommunityArticle } = useCommunityPage();

  const loginClick = () => {
    history.push("/login");
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.buttonContainer}>
            <Box className={classes.pageTextMain}>
              {unAuthCommunityArticle?.title}
            </Box>
            {global_data.isEditable && (
              <IconButton
                onClick={() => {
                  history.push(
                    `/update/pageItem/unAuthCommunityArticle/${unAuthCommunityArticle?.id}`
                  );
                }}
              >
                <CreateIcon />
              </IconButton>
            )}
          </Box>
          <Box mb={6}>
            <Typography
              className={classes.pageText}
              dangerouslySetInnerHTML={{
                __html: `${unAuthCommunityArticle?.description || ""}
                `,
              }}
            ></Typography>
            {/* <br />

            <Box className={classes.pageText}>
              {baseContent.pageText.community.detail2}
            </Box> */}
            <Button
              onClick={() => {
                loginClick();
              }}
              size="small"
              className={classes.cardrootinnerButton}
            >
              Join
            </Button>
          </Box>
        </Grid>
        <Grid item xs={10} sm={10} md={5} lg={5}>
          <Box>
            <img src={Res.images.communityMain} className={classes.imgStyle} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
