import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import PageBottomSection from "../../../components/PageBottomSection";
import useAllPodcasts from "../../../resources/localStorage/hooks/useAllPodcasts";
import useAllResources from "../../../resources/localStorage/hooks/useAllResources";
import useBottomSections from "../../../resources/localStorage/hooks/useBottomSections";
import CustomCarousel from "./components/CustomCarousal";
import CustomCarouselCourses from "./CustomCarousalCourses";
import useAllCourses from "./hooks/useAllCourses";
import useAllLearnings from "./hooks/useAllLearnings";
import useAllTalks from "./hooks/useAllTalks";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(6.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },

  maincontainer: {
    background: "white",
    padding: "4rem",
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  topHeading: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
  },

  image: {
    width: theme.spacing(30),
    height: theme.spacing(26),
    margin: theme.spacing(2),
  },

  cardOptions: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(2),
    color: theme.palette.textSecondary,
    fontSize: theme.spacing(1.6),
  },
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardroot: {
    borderRadius: theme.spacing(1.25),
    boxShadow: theme.shadows[1],
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2.5),
    "&:nth-child(odd)": {
      marginRight: theme.spacing(2.5),
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 45,
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 45,
      marginRight: theme.spacing(1),
    },
  },
  media: {
    height: 180,
    "@media (max-width:800px)": {
      height: 120,
    },
  },
  crousalclass: {
    width: 800,
    margin: "auto",
    padding: theme.spacing(6),
  },
  cardrootinner: {
    display: "grid",
    gridTemplateColumns: "80% 20%",
  },
  cardrootinnerFont: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(1.5),
    "@media (max-width:800px)": {
      fontSize: 9,
    },
  },
  cardrootinnerBadge: {
    width: "90%",
    height: "80%",
    "@media (max-width:800px)": {
      height: "41%",
    },
  },

  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    color: theme.palette.paper,
    marginLeft: 5,
    padding: 1,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  cardrootinnerFontheading: {
    "@media (max-width:800px)": {
      FontSize: 1,
    },
  },
}));

function ContentHub() {
  const global_data = useSelector((state) => state.commonReducer);
  const allResources = useAllResources();
  const allPodcasts = useAllPodcasts();
  const styles = makeStyle();
  const location = useLocation();

  useAllTalks();
  useAllLearnings();

  const { contenthub: contenthubBottonSection } = useBottomSections();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Header />
      <div className={styles.pageContainer}>
        <Container style={{ minHeight: 500 }}>
          <Box className={styles.maincontainer}>
            <Typography
              variant="h4"
              component="h2"
              className={styles.topHeading}
            >
              Information Hub
            </Typography>
            <Box display="flex" flexDirection="column">
              <CustomCarouselCourses
                carouselTitle="Courses"
                shortText="Learn more with our self-guided courses"
                link="/contenthub/courses"
              />
              {global_data.id ? (
                <>
                  <CustomCarousel
                    carouselTitle="Quick Reads"
                    carouselSubTitle="Practical articles, useful tips and the latest in parenting news"
                    carouselItems={global_data?.authResources?.resources || []}
                    type="resource"
                    link="/contenthub/resources"
                  />
                  <CustomCarousel
                    carouselTitle="Support Talks"
                    shortText="Short video talks for expert parenting advice on the go "
                    link="/contenthub/talks"
                    type="talks"
                    carouselItems={global_data?.allTalks || []}
                  />
                  <CustomCarousel
                    carouselTitle="Podcasts"
                    carouselSubTitle="Insightful conversations about behaviour in handy bite-sized episodes"
                    carouselItems={global_data?.authPodcasts?.podcasts || []}
                    type="podcast"
                    link="/contenthub/podcasts"
                  />
                </>
              ) : (
                <>
                  <CustomCarousel
                    carouselTitle="Quick Reads"
                    carouselSubTitle={allResources?.heading}
                    carouselItems={allResources?.resources || []}
                    type="resource"
                    link="/contenthub/resources"
                  />
                  <CustomCarousel
                    carouselTitle="Support Talks"
                    shortText="Short video talks for expert parenting advice on the go "
                    carouselItems={global_data?.allTalks || []}
                    link="/contenthub/talks"
                    type="talks"
                  />
                  <CustomCarousel
                    carouselTitle="Podcasts"
                    carouselSubTitle={allPodcasts?.heading}
                    carouselItems={allPodcasts?.podcasts || []}
                    type="podcast"
                    link="/contenthub/podcasts"
                  />
                </>
              )}
            </Box>
          </Box>
          <Grid></Grid>
          <PageBottomSection
            page="contenthub"
            item={contenthubBottonSection}
            buttonText="Book"
            //  title="Take the next step..." des="Enjoy our expert-led classes from the comfort of your sofa" btnName="Book"
          />
        </Container>
        <Footer />
      </div>
    </div>
  );
}

export default ContentHub;
