import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Input,
  makeStyles,
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Res } from "../resources";
import CustomButton from "./CustomButton";

const makeStyle = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modalBox: {
    width: "100%",
  },
  modalContain: {
    backgroundColor: "rgba(243,243,243,255)",
    width: "100%",
    padding: theme.spacing(0.8),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0),
    minWidth: 600,
    [theme.breakpoints.down("sm")]: {
      minWidth: 500,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
      minWidth: 320,
    },
  },
  dialogBox: {
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  paymentTitle: {
    backgroundColor: theme.palette.secondary.dark,
    color: "#FFF",
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2.25),
    display: "flex",
    alignItems: "center",
  },

  billContainer: {
    backgroundColor: "#fff",
    padding: theme.spacing(1.2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  labelStyle: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1.5),
    fontSize: theme.spacing(1.7),
    fontWeight: theme.typography.fontWeightMedium,
  },
  inputBox: {
    width: "100%",
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 1.5,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(5),
  },
  congImg: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  nameField: {
    width: "100%",
    fontSize: "14px",
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 1.5,
    color: theme.palette.customGrey.light,
    marginTop: 4,
    padding: theme.spacing(0.2),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.spacing(5),
    paddingLeft: theme.spacing(1.5),
    "&::placeholder": {
      color: "#aab7c4",
    },
  },
  paymentTypes: {
    paddingTop: theme.spacing(1),
  },
  paymentImageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  poweredImage: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end !important",
    alignItems: "center",
    paddingRight: theme.spacing(2),
  },
  courseDescription: {
    "& p": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  errorString: {
    textAlign: "center",
    color: "red",
    fontWeight: 600,
  },
}));

const PaymentModal = (props) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [loaderState, setLoaderState] = useState(false);
  const [errorString, setErrorString] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const inputSytle = {
    base: {
      fontSize: "14px",
      color: "#10265F",
      "::placeholder": { color: "#aab7c4" },
    },
    invalid: { color: "#9e2146" },
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (error) {
      console.log("[error]", error);
      // CustomizeToastError(error?.message, Res.variables.toastTimerDelay);
      setErrorString(error?.message);
    } else {
      props.subscribeMethod(paymentMethod.id);
      props.showPaymentProgress();
      console.log("[PaymentMethod]", paymentMethod);
      setErrorString("");

      props.onClose();
    }
  };

  // console.log("selectedDate", props.selectedDate)

  return (
    <Dialog
      className={styles.modalBox}
      open={props.modalState}
      onClose={() => {
        setErrorString("");
        props.onClose();
      }}
    >
      <Box className={styles.modalContain}>
        <Box className={styles.paymentTitle}>
          <IconButton
            style={{ color: "#FFF", padding: 4 }}
            onClick={() => props.onClose()}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Box> Payment Methods</Box>
        </Box>
        <DialogContent className={styles.dialogBox}>
          <Box className={styles.billContainer}>
            <Box fontSize={15} fontWeight={600}>
              {props.selectedDate?.heading
                ? props.selectedDate?.heading
                : props.selectedDate?.title}
            </Box>

            <Box
              fontSize={8}
              fontWeight={500}
              className={styles.courseDescription}
              dangerouslySetInnerHTML={{
                __html: props.selectedDate && props.selectedDate?.description,
              }}
            />

            <Box fontSize={13} maxWidth={360}>
              <Box fontWeight={600}>SUMMARY</Box>
              <Box>
                <Box>
                  <Box display="flex" justifyContent="space-between">
                    <Box>ORIGINAL PRICE</Box>
                    <Box>&#163; {props.selectedDate?.price} </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Box>VAT CHARGE</Box>
                    <Box>
                      &#163;{" "}
                      {(0.2 * parseInt(props.selectedDate?.price)).toFixed(2)}
                    </Box>
                  </Box>
                  {/* 
                                    <Box display='flex' justifyContent='space-between'>
                                        <Box>Coupon Discount</Box>
                                        <Box>&#163; -19</Box>
                                    </Box>
                                     */}
                </Box>
                <Box
                  fontWeight={600}
                  mt={0.5}
                  pt={0.5}
                  borderTop="2px solid red"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box>TOTAL:</Box>
                  <Box>
                    {" "}
                    &#163;
                    {0.2 * parseInt(props.selectedDate?.price) +
                      parseInt(props.selectedDate?.price)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {errorString.length > 0 && (
            <Box className={styles.errorString}>{errorString}</Box>
          )}

          <Box>
            <Box component="label" className={styles.labelStyle}>
              CARD NUMBER
            </Box>

            <CardNumberElement
              className={styles.inputBox}
              options={{ style: inputSytle }}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </Box>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 4 }}>
              <Box component="label" className={styles.labelStyle}>
                EXPIRATION DATE
              </Box>

              <CardExpiryElement
                className={styles.inputBox}
                options={{ style: inputSytle }}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 4 }}>
              <Box component="label" className={styles.labelStyle}>
                CVC
              </Box>

              <CardCvcElement
                className={styles.inputBox}
                options={{ style: inputSytle }}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <Box component="label" className={styles.labelStyle}>
              CARD HOLDER'S NAME
            </Box>
            <Input
              className={styles.nameField}
              placeholder="YOUR NAME"
              type="text"
            />
          </Box>
        </DialogContent>
        <DialogActions className={styles.btnContainer}>
          <Box display="flex" width="100%" justifyContent="space-around">
            <Box ml={3} width="35%" component="div">
              <CustomButton
                buttonType="outlined"
                buttonColor="primary"
                buttonText="CANCEL"
                customStyle={{
                  borderRadius: 40,
                  fontSize: 18,
                  fontWeight: 500,
                }}
                clickHandler={() => {
                  setErrorString("");
                  props.onClose();
                }}
                ajaxLoader={loaderState}
                dynamicText
              />
            </Box>
            <Box mr={3} width="35%" component="div">
              <CustomButton
                buttonType="contained"
                buttonColor="secondary"
                buttonText="PAY"
                customStyle={{
                  borderRadius: 40,
                  fontSize: 18,
                  fontWeight: 500,
                }}
                clickHandler={handleSubmit}
                ajaxLoader={loaderState}
                dynamicText
              />
            </Box>
          </Box>
          <Box component="div" bgcolor="white" mt={1.5}>
            <Grid container className={styles.paymentTypes}>
              <Grid item xs={3} className={styles.paymentImageBox}>
                <img
                  className={styles.congImg}
                  src={Res.images.visa}
                  alt={Res.images.visa}
                  width="60%"
                />
              </Grid>
              <Grid item xs={3} className={styles.paymentImageBox}>
                <img
                  className={styles.congImg}
                  src={Res.images.mastercard}
                  alt={Res.images.mastercard}
                  width="60%"
                />
              </Grid>
              <Grid item xs={3} className={styles.paymentImageBox}>
                <img
                  className={styles.congImg}
                  src={Res.images.discover}
                  alt={Res.images.discover}
                  width="60%"
                />
              </Grid>
              <Grid item xs={3} className={styles.paymentImageBox}>
                <img
                  className={styles.congImg}
                  src={Res.images.amExpress}
                  alt={Res.images.amExpress}
                  width="50%"
                />
              </Grid>
            </Grid>
            <Box className={styles.poweredImage}>
              <img
                src={Res.images.stripe}
                alt={Res.images.stripe}
                width="100px"
                height="auto"
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PaymentModal;
