import { Box, IconButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { useHistory } from "react-router";
import h2p from "html2plaintext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containerStetch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  boxContainer: {
    minWidth: 80,
    backgroundColor: "#B23970",
    borderRadius: 10,
    marginBottom: 50,
    margin: "0 45px",
    padding: theme.spacing(10),
    wordBreak: "break-word",
    minHeight: 250,
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  boldText: {
    textAlign: "center",
    color: "black",
    fontWeight: 600,
    marginBottom: 15,
  },
  subHeading: {
    paddingBottom: theme.spacing(1.25),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function ClassSection({
  enableEdit,
  localCoachingArticle,
  localCoachingSteps,
}) {
  const styles = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={styles.containerStetch}>
        <Typography
          variant="h4"
          component="h2"
          className={styles.subHeading}
          style={{ textAlign: "left", marginTop: 30 }}
        >
          {localCoachingArticle?.heading}
        </Typography>
        {enableEdit && (
          <IconButton
            onClick={() => {
              history.push(
                `/update/pageItem/coachingarticle/${localCoachingArticle.id}`
              );
            }}
          >
            <CreateIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        <Typography>{h2p(localCoachingArticle?.description)}</Typography>
        <br />

        <Box className={styles.buttonContainer}>
          <Typography variant="h5" className={styles.boldText}>
            {localCoachingArticle?.title}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {localCoachingSteps?.map((step) => (
            <Grid
              item
              xs={12}
              md={3}
              sm={12}
              lg={3}
              className={styles.boxContainer}
            >
              <Box
                className={styles.buttonContainer}
                style={{ marginBottom: -10 }}
              >
                <Typography
                  style={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontWeight: 600,
                    padding: "0 .5em",
                  }}
                >
                  {step?.title}
                </Typography>
                {enableEdit && (
                  <CreateIcon
                    onClick={() => {
                      history.push(`/update/pageItem/step/${step?.id}`);
                    }}
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>

              <br />
              <Typography
                style={{ color: "#FFFFFF", textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: step?.description,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
