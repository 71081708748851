import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const useFooter = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localFooter, setLocalFooter] = useSessionStorage(keys.FOOTER, null);
  const history = useHistory();

  const fetchFooter = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const footer = res.data;
      sessionStorage.removeItem(keys.FOOTER);
      setLocalFooter({
        footer,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "footer",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localFooter === null) {
      fetchFooter();
    }
  }, []);

  return { ...localFooter, fetchFooter };
};

export default useFooter;
