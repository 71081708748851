import {
  Box,
  ClickAwayListener,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { Icon } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  customInputBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.brand.text,
    padding: 0,
    paddingLeft: theme.spacing(2.4),
    borderRadius: theme.spacing(5),
    margin: theme.spacing(0.8),
    marginRight: 0,
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  closeButton: {
    position: "relative",
    top: 0,
  },
  customInput: {
    padding: theme.spacing(1),
    width: '100%',
    border: "none",
    fontSize: theme.spacing(2),
    flex: 1,
    textAlignVertical: "top",
    fontFamily: 'Poppins',
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: theme.palette.customGrey.light,
    },
  },
}));

const MobileSearch = (props) => {
  const styles = useStyle();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={props.handleClose ? props.handleClose : () => { }}
    >
      <Box component="span">
        <Box>
          <Box className={styles.customInputBox}>
            <input
              className={styles.customInput}
              type="text"
              id={props.inputId}
              {...props}
              placeholder={isFocused ? "" : props.placeholder}
              onBlur={() => {
                setIsFocused(false);
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
            />
            <Box container="span" className={styles.closeButton}>
              <IconButton
                style={{ borderRadius: 60, padding: 6 }}
                onClick={props.handleClose}
              >
                <Icon>search</Icon>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default MobileSearch;
