import { Box, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Icon } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  customInputBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.customGrey.main,
    backgroundColor: theme.palette.paper,
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.3),
  },
  customInputLabel: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.dark.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  logoStyle: {
    padding: theme.spacing(0.6),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.brand.main,
    position: "relative",
    top: 2,
  },
  customInput: {
    padding: theme.spacing(1.4),
    border: "none",
    borderLeft: "1px solid",
    borderLeftColor: theme.palette.customGrey.main,
    flex: 1,
    textAlignVertical: "top",
    fontFamily: 'Poppins',
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: theme.palette.customGrey.main,
    },
  },
  focused: {
    borderColor: theme.palette.secondary.main,
  },
  blurred: {
    borderColor: theme.palette.customGrey.main,
  },
  closeButton: {
    width: 60,
    top: -2,
    alignSelf: "flex-end",
    alignItems: "center",
    paddingVertical: 3,
    borderRadius: 2,
  },
  eye: {
    color: "#CEDBEA",
    height: 29,
    width: 31,
  },
}));

const CustomInput = ({
  inputIcon,
  inputId,
  inputLabe,
  inputType,
  ...props
}) => {
  const styles = useStyle();
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box pb={0.25}>
      <label htmlFor={props.inputId} className={styles.customInputLabel}>
        {inputLabe}
      </label>
      <Box className={styles.customInputBox}>
        <Box container="span" className={styles.logoStyle}>
          <Icon fontSize="small">{inputIcon}</Icon>
        </Box>
        <input
          className={styles.customInput}
          type={inputType === "password" && showPassword ? "text" : inputType}
          id={inputId}
          {...props}
          placeholder={isFocused ? "" : props.placeholder}
          onBlur={() => {
            setIsFocused(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
        />
        {inputType === "password" && (
          <IconButton onClick={handlePassword} className={styles.eye}>
            <Icon style={{ fontSize: 16 }}>
              {showPassword ? "visibility" : "visibility_off"}
            </Icon>
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default CustomInput;
