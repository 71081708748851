import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const useBottomSections = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localBottomSections, setLocalBottomSections] = useSessionStorage(
    keys.BOTTOMSECTIONS,
    null
  );
  const history = useHistory();

  const fetchBottomSections = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const bottomSections = res.data;
      sessionStorage.removeItem(keys.BOTTOMSECTIONS);
      if (typeof bottomSections === "object")
        setLocalBottomSections({
          ...bottomSections,
        });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "bottomSections",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localBottomSections === null) {
      fetchBottomSections();
    }
  }, []);

  return { ...localBottomSections, fetchBottomSections };
};

export default useBottomSections;
