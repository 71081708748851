import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const makeStyle = makeStyles((theme) => ({
  authBannerContainer: {
    maxWidth: 1225,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("xs")]: {
      backgroundColor: theme.palette.bright,
    },
  },
  authTitle: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.only("md")]: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
      textAlign: "center",
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(4),
    },
  },
  description: {
    padding: theme.spacing(1),
  },
  authTitle1: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
      textAlign: "center",
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(4),
    },
  },
  bookingHeading: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    marginBottom: 10,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  bannerBox: {
    borderRadius: theme.spacing(2.5),
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.primary.main1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: "flex",
    marginTop: 50,
    minHeight: "315px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
      borderRadius: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0),
      boxShadow: theme.shadows[0],
      marginTop: 20,
    },
  },
  bannerDetailTitle: {
    fontWeight: theme.typography.fontWeightDarkBold,
    color: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      textAlign: "center",
    },
  },
  coachingTitle: {
    color: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
  },
  coachingDetail: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  bannerImage: {
    minHeight: 280,
    minWidth: 364,
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      paddingTop: theme.spacing(2),
      maxWidth: 300,
    },
  },
  imageBox: {
    order: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      order: 1,
      justifyContent: "center",
    },
  },
  bannerDetail: {
    order: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(7),
    justifyContent: "center",
    alignItems: "left",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      order: 2,
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: theme.spacing(0),
      width: "100%",
    },
  },
  buttonBox: {
    width: "40%",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  headingBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  imgContainer: {
    marginTop: 65,
    marginLeft: 10,
  },
}));

const AuthBanner = ({ article, ...props }) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);

  return (
    <div className={styles.authBannerContainer}>
      <Grid container className={styles.bannerBox}>
        <Grid item xs={12} sm={6} className={styles.bannerDetail}>
          <Typography
            variant="h4"
            className={styles.bannerDetailTitle}
            component="h4"
          >
            {props.bannerData?.heading}
          </Typography>
          <Typography
            variant="subtitle2"
            className={styles.coachingDetail}
            component="h6"
            dangerouslySetInnerHTML={{
              __html: `${props.bannerData?.description || ""}`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={styles.imageBox}>
          <img
            className={styles.bannerImage}
            alt={props.bannerData?.bannerImageAlt}
            src={props.bannerData?.image}
            width="100%"
          />
          {global_data.isEditable && (
            <IconButton
              style={{ marginTop: "-2.5rem", alignSelf: "flex-start" }}
              onClick={() => {
                history.push(
                  `/update/pageItem/mylearning/${props.bannerData?.id}`
                );
              }}
            >
              <CreateIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Box className={styles?.buttonContainer}>
        {global_data.isEditable && (
          <IconButton
            style={{ marginTop: "1rem", alignSelf: "flex-end" }}
            onClick={() => {
              history.push(`/update/pageItem/homeAuthArticle/${article?.id}`);
            }}
          >
            <CreateIcon />
          </IconButton>
        )}
        <Box m={2} mt={4} ml={2.2} className={styles.secondContainer}>
          <Typography
            variant="h4"
            component="h4"
            className={styles.iframeTitle}
            style={{ paddingBottom: 10, fontWeight: 600 }}
          >
            {article?.title}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: article?.description,
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default AuthBanner;
