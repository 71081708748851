import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import MobileData from "../../../components/ModileData";
import PageBottomSection from "../../../components/PageBottomSection";
import UnAuthSection from "../../../components/UnAuthSection";
import useBottomSections from "../../../resources/localStorage/hooks/useBottomSections";
import useJournalPageStorage from "../../../resources/localStorage/hooks/useJournalPageStorage";
import baseContent from "../../../services/Content";
import AppDetail from "./AppDetail";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(7),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },

  mainContainer: {
    padding: theme.spacing(8),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
      textAlign: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  bigGrid: {
    paddingRight: theme.spacing(4),
    // paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  headingText: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  journalDetail: {
    lineHeight: 1.8,
    textAlign: "left",

    paddingBottom: theme.spacing(2.5),
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  journalApp: {
    paddingTop: theme.spacing(10),
    display: "block",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  journalAppMobile: {
    display: "none",
    [theme.breakpoints.only("xs")]: {
      display: "block",
      paddingTop: theme.spacing(2),
    },
  },
  bookingContainer: {
    marginLeft: "16px",

    [theme.breakpoints.down("xs")]: {
      paddingRight: "10%",
    },
  },
}));

function Journal(props) {
  const constantText = baseContent.pageText;
  const styles = makeStyle();
  const {
    localJournalImageArticle,
    localJournalFeatures,
    localJournalFeatures2,
  } = useJournalPageStorage();
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();
  const { journal: journalBottonSection } = useBottomSections();
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: "80vh" }}>
        <Grid container className={styles.mainContainer}>
          <Grid item xs={12} sm={6} className={styles.bigGrid}>
            <Box className={styles.buttonContainer}>
              <Typography
                variant="h4"
                component="h2"
                className={styles.headingText}
              >
                {(localJournalImageArticle &&
                  localJournalImageArticle?.heading) ||
                  "Journal"}
              </Typography>
              {global_data.isEditable && (
                <IconButton
                  onClick={() => {
                    history.push(
                      `/update/pageItem/journalimagearticle/${localJournalImageArticle?.id}`
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              )}
            </Box>
            <Box
              variant="h4"
              component="h1"
              fontWeight="fontWeightMedium"
              color="secondary.main"
            >
              {localJournalImageArticle?.title}
            </Box>
            <Typography
              variant="body1"
              component="p"
              className={styles.journalDetail}
              dangerouslySetInnerHTML={{
                __html: localJournalImageArticle?.description,
              }}
            />
            <Grid item xs={12} className={styles.journalAppMobile}>
              <img
                width="100%"
                src={localJournalImageArticle?.image}
                alt="Journal"
              />
            </Grid>
            <AppDetail
              journalId={localJournalImageArticle?.id}
              playStoreLink={localJournalImageArticle?.playStoreLink}
              appleStoreLink={localJournalImageArticle?.appleStoreLink}
              appData={constantText.journal}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.journalApp}>
            <img
              width="100%"
              src={localJournalImageArticle?.image}
              alt="Journal"
            />
          </Grid>
        </Grid>
        <Grid container className={styles.bookingContainer}>
          <UnAuthSection items={localJournalFeatures2} />
        </Grid>
        <Box height={50} />
        {global_data.isEditable && <MobileData />}
        <Grid>
          <PageBottomSection
            page="journal"
            item={journalBottonSection}
            buttonText="Book"
          />
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

export default Journal;
