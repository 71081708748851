import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page_Alt } from "../../../services/ApiServies";
import errorAction from "../../../store/actions/errorAction";
import keys from "../constantKeys";

const useAllPodcasts = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [localContentHub, setLocalContentHub] = useSessionStorage(
    keys.PODCASTS,
    null
  );

  useEffect(() => {
    let promises = [];
    if (localContentHub === null || !localContentHub.podcasts) {
      promises.push(
        Get_Page_Alt(
          Res.apiEndPoints.podcasts,
          {},
          "podcasts",
          global_data?.token
        )
      );
    }

    /*  if any other data fetch needed insert here  */

    if (promises.length) {
      Promise.allSettled(promises).then((responses) => {
        let data = responses.reduce((acc, res) => {
          if (res.status === "rejected") {
            errorAction(dispatch, res?.reason?.response?.data?.message);
          } else {
            if (typeof acc === "object")
              acc = {
                ...res.value?.data,
                podcasts: res.value?.data?.podcasts?.map((item) => ({
                  ...item,
                  image: item.image,
                })),
              };
          }
          return acc;
        }, {});
        setLocalContentHub((storedData = {}) => ({ ...storedData, ...data }));
      });
    }
  }, [dispatch, global_data?.token, setLocalContentHub]);

  return { ...localContentHub };
};

export default useAllPodcasts;
