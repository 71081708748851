import { SET_PROFILE } from "../actionTypes";

const intialState = {
  fname: "",
  lname: "",
  mobile: "",
  email: "",
  profileImage: "",
  timezone: null,
  userType: "",
  vat_percent: 0,
};

const profileReducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
export default profileReducer;
