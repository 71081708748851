import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Avatar,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Header from "../../../components/header/Header";
import Footer from "../../../components/Footer";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: 60,
    background: "#F1F1F1",
    [theme.breakpoints.down("sm")]: { paddingTop: 90 },
    minHeight: '100vh',
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  classFlex: {
    display: "grid",
    gridTemplateColumns: "70% 30%",
    gridColumnGap: theme.spacing(6),
  },
  classFlexinner: {
    background: "white",
    padding: theme.spacing(4),
  },
  imagewhowearebig: {
    "@media (max-width:800px)": {
      width: "90%",
    },
  },
  maincontainer: {
    background: "#F1F1F1",
    padding: theme.spacing(8),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  iconclass: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    "@media (max-width:800px)": {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(8),
    },
  },
  iconclassinner: {
    padding: theme.spacing(1),
    border: "solid",
    margin: theme.spacing(2),
    paddingRight: theme.spacing(16),
    "@media (max-width:800px)": {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(6),
    },
  },
  image: {
    width: theme.spacing(30),
    height: theme.spacing(26),
    margin: theme.spacing(2),
  },
}));

function Checkout() {
  const styles = makeStyle();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: '80vh', }}>
        <Box className={styles.maincontainer}>
          <Typography
            variant="h4"
            component="h2"
            style={{ paddingBottom: 10, fontWeight: 600, marginBottom: "3rem" }}
          >
            Checkout
          </Typography>
          <Box className={styles.classFlex}>
            <Box className={styles.classFlexinner}>
              <Typography
                variant="h4"
                component="h2"
                style={{ fontWeight: 500 }}
              >
                Subject
              </Typography>
              <Typography variant="h10" component="p">
                Choose your subject
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1">
                  <Box className={styles.iconclass}>
                    <Box className={styles.iconclassinner}>
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                    </Box>
                    <Box className={styles.iconclassinner}>
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                    </Box>
                    <Box className={styles.iconclassinner}>
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </Box>
                    <Box className={styles.iconclassinner}>
                      <FormControlLabel
                        value="otherr"
                        control={<Radio />}
                        label="Other"
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box className={styles.classFlexinner}>
              <Avatar
                alt="Remy Sharp"
                src="/images/usersvg.svg"
                className={styles.image}
              />
              <Typography
                variant="h6"
                component="h2"
                style={{ fontWeight: 600, textAlign: "center" }}
              >
                John
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{ fontWeight: 500, textAlign: "center" }}
              >
                United Kingdom
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Checkout;
