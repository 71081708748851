import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFirstMountState } from "react-use";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import useAllPodcasts from "../../../resources/localStorage/hooks/useAllPodcasts";
import useAllResources from "../../../resources/localStorage/hooks/useAllResources";
import getCreateRoute from "../../../utils/getCreateRoute";
import ContentItem from "./components/ContentItem";
import useAllTalks from "./hooks/useAllTalks";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(10.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(16) },
  },
  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxShadow: theme.shadows[0],
    },
  },
  headingText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.spacing(2.5),
    },
  },
  navArrow: {
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: theme.spacing(2.5),
    },
  },
}));

export default function ContentPage() {
  const global_data = useSelector((state) => state.commonReducer);
  const allPodcasts = useAllPodcasts();
  const allResources = useAllResources();
  const history = useHistory();
  const [resourcesState, setResourcesState] = React.useState(
    global_data?.authResources ? global_data?.authResources : allResources
  );
  const [podcastsState, setPodcastsState] = useState({});
  const styles = makeStyle();
  const dispatch = useDispatch();
  const isFirstMount = useFirstMountState();
  const { pageType } = useParams();
  const [allTalks] = useAllTalks({ fetchNeeded: pageType === "talks" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (global_data?.id && pageType !== "talks") {
  //     if (isFirstMount) {
  //       const getItemSuccess = (response) => {
  //         if (response?.data.length) {
  //           var responseObject = response?.data;
  //           getLearningsAction(dispatch, responseObject);
  //         }
  //       };
  //       Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
  //     }
  //     setResourcesState(global_data.authResources);
  //     setPodcastsState(global_data.authPodcasts);
  //   } else {
  //     setPodcastsState(allPodcasts);
  //     setResourcesState(allResources);
  //   }
  // }, [global_data?.authResources, global_data?.authPodcasts]);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500, maxWidth: 1130 }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="large" className={styles.navArrow} />
          }
          aria-label="breadcrumb"
        >
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            <Link
              to="/contenthub"
              style={{ textDecoration: "none", color: "#0D2860" }}
            >
              Information Hub
            </Link>
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            className={styles.headingText}
          >
            {pageType === "resources" && "Resources"}
            {pageType === "podcasts" && "Podcasts"}
            {pageType === "talks" && "Talks"}
          </Typography>
        </Breadcrumbs>
        {global_data.isEditable && (
          <Box display="flex" py={1} justifyContent="flex-end">
            <Button
              style={{ padding: 5, textTransform: "none" }}
              onClick={() => history.push(getCreateRoute({ type: pageType }))}
            >
              <AddCircleIcon color="primary" size="large" />
              <Typography variant="caption">Add {pageType}</Typography>
            </Button>
          </Box>
        )}
        <Box>
          {pageType === "podcasts" ? (
            global_data?.id ? (
              <ContentItem
                carouselTitle="Podcasts"
                // carouselSubTitle={podcastsState?.heading}
                carouselItems={global_data?.authPodcasts?.podcasts}
              />
            ) : (
              <ContentItem
                carouselTitle="Podcasts"
                // carouselSubTitle={allPodcasts?.heading}
                carouselItems={allPodcasts?.podcasts}
              />
            )
          ) : null}
          {pageType === "resources" ? (
            global_data?.id ? (
              <ContentItem
                carouselTitle="Resources"
                carouselItems={global_data?.authResources?.resources}
                // carouselSubTitle={podcastsState?.heading}
              />
            ) : (
              <ContentItem
                carouselTitle="Resources"
                carouselItems={allResources?.resources}
                // carouselSubTitle={allPodcasts?.heading}
              />
            )
          ) : null}
          {pageType === "talks" ? (
            <ContentItem
              carouselTitle="Support Talks"
              carouselItems={allTalks}
              // carouselSubTitle={podcastsState?.heading}
            />
          ) : null}
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
