import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CreateIcon from "@material-ui/icons/Create";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import ClassSection from "../../../components/ClassSection";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import PaymentModal from "../../../components/PaymentModal";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { Res } from "../../../resources";
import useCoachingPageStorage from "../../../resources/localStorage/hooks/useCoachingPageStorage";
import { Get_API } from "../../../services/ApiServies";
import { getLearningsAction } from "../../../store/actions";
import {
  if_Attended,
  if_Book,
  if_Booked,
  if_Join,
  if_Mark_Joined,
  if_NoSlots,
} from "../../../utils/class_webinar_status";
import useLearning from "../contenthub/hooks/useLearning";
import usePayment from "../contenthub/hooks/usePayment";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },

  textBox: {
    display: "flex",
    "@media (max-width:800px)": {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  imagewhowearebig: {
    maxHeight: 400,
    width: "auto",
    "@media (max-width:800px)": {
      width: "90%",
    },
  },
  maincontainer: {
    maxWidth: 1090,
    marginLeft: "auto",
    marginRight: "auto",
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
  },
  bookingContainer: {
    paddingBlock: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      // paddingLeft: "15%",
      // paddingRight: "15%",
    },
  },
  des: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  boldText: {
    fontWeight: 600,
    fontSize: theme.spacing(2),
  },
  boldTextBig: {
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
  },
  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    marginTop: theme.spacing(4),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

function ClassPage() {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const { classId } = useParams();
  const { localCoachingArticle, localCoachingSteps } = useCoachingPageStorage();
  const [data, setData] = React.useState({});
  const [loaderState, setStateLoader] = React.useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const { subscribeContent } = useLearning();

  const {
    showPaymentProgress,
    cancelPayment,
    paymentInProgress,
    paymentModal,
    paymentCall,
    moduleCompleteCall,
    buyCourse: buyClass,
    selectedPaymentCourse,
  } = usePayment({
    subscribeContent,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const clickLikeButton = () => {
    console.log("Class Detail ", data);
    if (!global_data.token) {
      history.push("/login");
    } else if (data.price <= 0) {
      subscribeContent(data);
    } else if (data.price > 0) {
      paymentCall(data);
    }
  };

  useEffect(() => {
    if (global_data.id) {
      const getItemSuccess = (response) => {
        if (response?.data.length) {
          var responseObject = response?.data;
          getLearningsAction(dispatch, responseObject);
          let allClasses = responseObject.filter((data) => {
            return data.type === "classes";
          })[0];
          let thisClass = allClasses?.classes?.find(
            (cls) => cls.id === classId
          );
          setData(thisClass);
          setStateLoader(false);
        }
      };
      Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
    } else {
      const getItemSuccess = (response) => {
        if (response?.data?.length) {
          let thisClass = response?.data?.find((cls) => cls.id === classId);
          setData(thisClass);
          setStateLoader(false);
        }
      };
      Get_API(Res.apiEndPoints?.classes, null, getItemSuccess, true);
    }
  }, [global_data?.id]);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500 }}>
        <Box className={styles.maincontainer}>
          {loaderState ? (
            <SkeletonLoader />
          ) : (
            <Box className={styles.textBox}>
              <Box>
                <Box className={styles.buttonContainer}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={styles.heading}
                  >
                    {data?.heading}
                  </Typography>
                  {global_data.isEditable && (
                    <IconButton
                      onClick={() => {
                        history.push(
                          `/update/class/${data.contentId}/${data?.id}`
                        );
                      }}
                    >
                      <CreateIcon />
                    </IconButton>
                  )}
                </Box>

                <Typography
                  variant="h6"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: data && data?.description,
                  }}
                />
                <Typography variant="h6" className={styles.boldText}>
                  {data?.localWebinarDate}
                </Typography>
                <Typography variant="h6" className={styles.boldText}>
                  {data?.localWebinarTime}
                </Typography>
                <Typography variant="h6" className={styles.boldTextBig}>
                  {data?.price && data?.price !== "0"
                    ? `₤${data?.price}`
                    : "Free"}
                </Typography>

                {if_NoSlots(data) && (
                  <Button
                    size="small"
                    className={styles.cardrootinnerButton}
                    style={{
                      backgroundColor: "#C5C4C4",
                      cursor: "auto",
                    }}
                  >
                    No Slots Available
                  </Button>
                )}

                {paymentInProgress && (
                  <Box py={1.5}>
                    <CircularProgress
                      style={{ color: "#75A8F4", height: 21, width: 21 }}
                    />
                  </Box>
                )}

                {/* IF BOOK AND NOT LOGGED IN */}
                {!global_data.id &&
                  (data?.numSlots > data?.numBooked || !data?.numBooked) && (
                    <RouterLink to="/login" style={{ textDecoration: "none" }}>
                      <Button
                        size="small"
                        className={styles.cardrootinnerButton}
                      >
                        Book
                      </Button>
                    </RouterLink>
                  )}

                {/* IF BOOK AND LOGGED IN */}
                {if_Book(data) && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="baseline"
                    >
                      <Box>
                        {data?.numBooked
                          ? data?.numSlots - data?.numBooked + " "
                          : data?.numSlots + " "}
                      </Box>
                      <Box pl={0.3}>
                        out of {data?.numSlots ? data?.numSlots + " " : 0 + " "}
                        Slots Available
                      </Box>
                    </Box>
                    <Button
                      onClick={clickLikeButton}
                      className={styles.cardrootinnerButton}
                    >
                      Book
                    </Button>
                  </>
                )}

                {/*  FOR BOOKED */}
                {if_Booked(data) && (
                  <Button
                    size="small"
                    className={styles.cardrootinnerButton}
                    style={{
                      backgroundColor: "#C5C4C4",
                      cursor: "auto",
                    }}
                  >
                    Booked
                  </Button>
                )}

                {/*  FOR JOIN */}
                {if_Join(data) && (
                  <a
                    target="_blank"
                    style={{
                      color: "#ffF",
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      moduleCompleteCall(data);
                    }}
                    href={data?.webinarLink}
                    rel="noreferrer"
                  >
                    <Button
                      size="small"
                      className={styles.cardrootinnerButton}
                      style={{ backgroundColor: "#10265F" }}
                    >
                      Join
                    </Button>
                  </a>
                )}

                {/* IF ATTENDED */}
                {if_Attended(data) && (
                  <Box
                    className={styles.iconBox}
                    style={{
                      marginBottom: 20,
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleIcon color="primary" />
                    <Box style={{ marginLeft: 5 }}>Attended</Box>
                  </Box>
                )}

                {if_Mark_Joined(data) && (
                  <Button
                    size="small"
                    className={styles.cardrootinnerButton}
                    style={{ backgroundColor: "#10265F" }}
                    onClick={() => {
                      moduleCompleteCall(data);
                    }}
                  >
                    Mark as Joined
                  </Button>
                )}

                <PaymentModal
                  modalState={paymentModal}
                  selectedDate={selectedPaymentCourse}
                  subscribeMethod={buyClass}
                  onClose={cancelPayment}
                  showPaymentProgress={showPaymentProgress}
                />
              </Box>
            </Box>
          )}
          <ClassSection
            localCoachingArticle={localCoachingArticle}
            localCoachingSteps={localCoachingSteps}
          />
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default ClassPage;
