import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page, Get_Page_Alt } from "../../../services/ApiServies";
import errorAction from "../../../store/actions/errorAction";
import keys from "../constantKeys";

const useCoachingPageStorage = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();

  const [localCoaching, setLocalCoaching] = useSessionStorage(
    keys.COACHING_PAGE,
    null
  );

  const fetchCoachingPage = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const localCoachingImageArticle = res.data?.part1;
      const localCoachingArticle = res.data?.part2;
      const localCoachingSteps = res.data?.coachingSteps;
      sessionStorage.removeItem(keys.COACHING_PAGE);

      setLocalCoaching({
        localCoachingImageArticle,
        localCoachingArticle,
        localCoachingSteps,
      });
      if (fromCreatePage) history.goBack();
    };

    Get_Page(
      Res.apiEndPoints.pagesUrl + "coaching",
      {},
      getPageSuccess,
      global_data?.token
    );
  };
  useEffect(() => {
    if (localCoaching === null) {
      fetchCoachingPage();
    }
  }, []);
  return { ...localCoaching, fetchCoachingPage };
};

export default useCoachingPageStorage;
