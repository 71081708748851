import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Res } from "../resources";

const makeStyle = makeStyles((theme) => ({
  modalBox: {
    width: "100%",
  },
  dialogBox: {
    overflow: "hidden",

    maxWidth: 500,
    padding: "9%",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  queryButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    width: "50%",
    fontSize: "1rem",
    textTransform: "unset",
    color: theme.palette.paper,
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
  },
  webinarImage: {
    width: "100%",
    maxHeight: 400,
  },
  modalImage: {
    position: "relative",
  },
  modalText: {
    position: "absolute",
    top: "65%",
    textAlign: "center",
    width: "100%",
  },
}));

const WebinarModal = (props) => {
  const styles = makeStyle();
  return (
    <Dialog
      className={styles.modalBox}
      open={props.modalState}
      onClose={props.onClose}
    >
      <DialogContent className={styles.dialogBox}>
        <Box className={styles.modalImage}>
          <img
            className={styles.webinarImage}
            src={Res.images.congrats}
            alt={Res.images.congrats}
            width="100%"
          />
          <div className={styles.modalText}>{props.text}</div>
        </Box>
      </DialogContent>
      <DialogActions className={styles.btnContainer}>
        <Button onClick={props.onClose} className={styles.queryButton}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebinarModal;
