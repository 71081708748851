import { GLOBAL_DATA } from "../../actionTypes";

const manipulateContentItemAction = (
  dispatch,
  data,
  res,
  global_data,
  manipulationType
) => {
  let field, subField;
  if (data.type === "resource") {
    field = "authResources";
    subField = "resources";
  } else if (data.type === "podcast") {
    field = "authPodcasts";
    subField = "podcasts";
  } else if (data.type === "class") {
    field = "authClasses";
    subField = "classes";
  }
  if (global_data[field] && global_data[field][subField]) {
    console.log(data, res, field, subField);
    let subCourse, indexToUpdate;

    if (manipulationType === "delete") {
      console.log("deleteeeeeeee");
      subCourse = global_data[field][subField]?.filter(
        (item) => item.id !== data.id
      );
    } else {
      indexToUpdate = global_data[field][subField].findIndex(
        (item) => item.id === data.id
      );
      subCourse = [...global_data[field][subField]];
      if (manipulationType === "subscribe") {
        subCourse[indexToUpdate].status = 1;
        subCourse[indexToUpdate].learningId = res?.data?.object?.learningId;
        if (data.type === "class") {
          subCourse[indexToUpdate].state = 1;
        }
      } else if (manipulationType === "unsubscribe") {
        subCourse[indexToUpdate].status = 0;
        subCourse[indexToUpdate].learningId = undefined;
        if (data.type === "class") {
          subCourse[indexToUpdate].state = 0;
        }
      } else if (manipulationType === "complete") {
        subCourse[indexToUpdate].status = 3;
        if (subCourse[indexToUpdate].state !== 2) {
          subCourse[indexToUpdate].state = 2;
        }
      }
    }
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        [field]: {
          ...global_data[field],
          [subField]: [...subCourse],
        },
      },
    });
    return subCourse[indexToUpdate];
  }
};

const subscribeContentItemAction = (dispatch, data, res, global_data) =>
  manipulateContentItemAction(dispatch, data, res, global_data, "subscribe");

const unSubscribeContentItemAction = (dispatch, data, res, global_data) =>
  manipulateContentItemAction(dispatch, data, res, global_data, "unsubscribe");

const completeContentItemAction = (dispatch, data, res, global_data) =>
  manipulateContentItemAction(dispatch, data, res, global_data, "complete");

const deleteContentItemAction = (dispatch, data, res, global_data) =>
  manipulateContentItemAction(dispatch, data, res, global_data, "delete");

export {
  subscribeContentItemAction,
  unSubscribeContentItemAction,
  completeContentItemAction,
  deleteContentItemAction,
};
