import {
  Box,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CreateIcon from "@material-ui/icons/Create";

const makeStyle = makeStyles((theme) => ({
  gridItemBox: {
    padding: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: theme.spacing(7),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(5),
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1.6rem 3rem",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 30,
  },
  itemLogoImg: {
    width: "160px",
    height: "160px",
    borderRadius: "50%",
  },
  itemLogoImgSm: {
    width: "50px",
    height: "50px",
  },
  itemTitle: {
    color: "#091133",
    fontWeight: 500,
    fontSize: 20,
  },
  itemDesignation: {
    color: "#5D6970",
    fontWeight: 600,
    fontSize: 16,
  },
  itemDetail: {
    color: "#5D6970",
    fontSize: 12,
  },
}));

const ItemGrid = (props) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);

  return (
    <Grid container>
      {props.gridItems?.map((item) => {
        return (
          <Grid
            item
            key={item.id}
            xs={12}
            sm={4}
            className={styles.gridItemBox}
          >
            <Box mb={1}>
              {
                props.page === "team" ? (
                  <>
                    {global_data.isEditable && (
                      <Box className={styles.buttonContainer}>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `/update/pageItem/teammember/${item?.id}`
                            );
                          }}
                        >
                          <CreateIcon />
                        </IconButton>
                      </Box>
                    )}
                    {item.image && (
                      <img src={item.image} className={styles.itemLogoImg} />
                    )}
                  </>
                ) : null
                // (
                // <img
                //   src={item.image}
                //   className={styles.itemLogoImgSm}
                //   alt="Content"
                // />
                // )
              }
            </Box>
            <Typography className={styles.itemTitle}>
              {item.name}
              {item.title}
            </Typography>
            <Typography className={styles.itemDesignation}>
              {item.featureTitle}
              {item.designation}
            </Typography>
            <Typography
              className={styles.itemDetail}
              component="p"
              dangerouslySetInnerHTML={{
                __html: `${item.description || ""}${
                  item.featureDescription || ""
                }`,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ItemGrid;
