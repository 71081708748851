import { responsiveFontSizes } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { variables } from "./Variables";
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: variables.color.primaryColor,
        contrastText: variables.color.whiteColor,
        main1:variables.color.grey,
      },
      primaryText: {
        main: variables.color.primaryTextColor,
      },
      secondary: {
        main: variables.color.secondaryColor,
        contrastText: variables.color.whiteColor,
      },
      dark: {
        main: variables.color.darkColor,
      },
      customGrey: {
        light: variables.color.lightGrey,
        main: variables.color.mainGrey,
        dark: variables.color.darkGrey,
      },
      brand: {
        main: variables.color.brandColor,
        text: variables.color.brandText,
      },
      green: {
        dark: variables.color.darkGreen,
        main: variables.color.mainGreen,
        light: variables.color.lightGreen,
        contrastText: variables.color.whiteColor,
      },
      paper: variables.color.whiteColor,
      bright: variables.color.brightColor,
      red: {
        main: variables.color.red,
      },
      blue: {
        main: variables.color.blue
      }
    },

    typography: {
      htmlFontSize: 16,
      fontFamily: ["Poppins", "Helvetica", "Arial", "Sans-serif"].join(","),
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      fontWeightDarkBold: 900,
      fontSize: 16,

      h6: {
        fontWeight: 500,
        fontSize: "1.125rem",
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
      },
    },

    overrides: {
      MuiAutocomplete: {
        padding: "0 4px",
      },
    },
  })
);

export default theme;
