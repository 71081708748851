import React from "react";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroud: "red",
  },
  gridContainer: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
    },
  },
  des: {
    wordWrap: "break-word",
    width: "100%",
    lineHeight: 2,
    marginBottom: 10,
    textAlign: "left",
    "& ul": {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("md")]: {
      "& ul": {
        marginLeft: "0%",
        transform: "translateX(-50%)",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& ul": {
        marginLeft: "50%",
        transform: "translateX(-50%)",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    transform: "translateX(-20%)",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      transform: "translateX(0)",
    },

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  maintxt: {
    fontSize: 22,
    fontWeight: "semi-bold",
    color: "black",
    marginLeft: 0,
    marginBottom: 10,
    marginTop: 10,
    width: "60%",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  btnStyle: {
    border: "1px solid #ADADAD",
    color: "#1297A7",
    borderRadius: 14,
    fontSize: 14,
    marginTop: 8,
  },
  gridItemBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function UnAuthSection({ items }) {
  const styles = useStyles();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);

  const btnClick1 = () => {
    history.push("/contenthub/courses");
  };
  const btnClick2 = () => {
    history.push("/contenthub/resources");
  };
  const btnClick3 = () => {
    history.push("/contenthub/podcasts");
  };
  const btnClick4 = () => {
    history.push("/journal");
  };

  return (
    <Grid className={styles.gridContainer} container spacing={3}>
      {items?.map((item, i) => (
        <Grid item xs={12} sm={12} md={4} lg={4} className={styles.gridItemBox}>
          <Box className={styles.buttonContainer}>
            <img src={item?.image} />
            {global_data.isEditable && (
              <IconButton
                onClick={() => {
                  history.push(`/update/pageItem/journalfeature/${item.id}`);
                }}
              >
                <CreateIcon />
              </IconButton>
            )}
          </Box>
          <Box className={styles.maintxt}>{item?.title}</Box>
          <Box
            className={styles.des}
            dangerouslySetInnerHTML={{
              __html: item?.description,
            }}
          ></Box>
          {/* <Button
            className={styles.btnStyle}
            onClick={() => {
              if (i === 0) btnClick1();
              else if (i === 1) btnClick2();
              else if (i === 2) btnClick3();
              else if (i === 3) btnClick4();
            }}
          >
            Take a look
          </Button> */}
        </Grid>
      ))}
    </Grid>
  );
}
