import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/header/Header";
import {
  Box,
  Container,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import useTermsOfBusiness from "../../resources/localStorage/hooks/useTermsOfBusiness";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function Terms() {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);

  const { termsOfBusiness } = useTermsOfBusiness();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />

      <Container style={{ minHeight: "80vh", paddingTop: "6vh" }}>
        <Box style={{ marginTop: 108, marginLeft: "6%", marginRight: "10%" }}>
          <Box className={styles.buttonContainer}>
            <Typography
              style={{ color: "#10265F", fontWeight: "bold", fontSize: 28 }}
            >
              {termsOfBusiness?.heading}
            </Typography>
            {global_data.isEditable && (
              <IconButton
                onClick={() => {
                  history.push(
                    `/update/pageItem/termsofbusiness/${termsOfBusiness.id}`
                  );
                }}
              >
                <CreateIcon />
              </IconButton>
            )}
          </Box>
          <div
            dangerouslySetInnerHTML={{
              __html: termsOfBusiness?.description || "",
            }}
          ></div>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Terms;
