import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const useCookiePolicy = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localCookiePolicy, setLocalCookiePolicy] = useSessionStorage(
    keys.COOKIE_POLICY,
    null
  );
  const history = useHistory();

  const fetchCookiePolicy = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const cookiePolicy = res.data;
      sessionStorage.removeItem(keys.COOKIE_POLICY);
      setLocalCookiePolicy({
        cookiePolicy,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "cookiepolicy",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localCookiePolicy === null) {
      fetchCookiePolicy();
    }
  }, []);

  return { ...localCookiePolicy, fetchCookiePolicy };
};

export default useCookiePolicy;
