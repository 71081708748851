import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BookIcon from "@material-ui/icons/Book";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/header/Header";
import QueryModal from "../../../../components/QueryModal";
import { Res } from "../../../../resources";
import { Delete_API, Get_API, Post_API } from "../../../../services/ApiServies";
import { CustomizeToastError } from "../../../../services/CommonLogic";
import { GLOBAL_DATA } from "../../../../store/actionTypes";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(12.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(18) },
  },
  maincontainer: {
    background: "white",
    padding: theme.spacing(8),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },

  image: {
    width: theme.spacing(30),
    height: theme.spacing(26),
    margin: theme.spacing(2),
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
  },

  cardroot: {
    minWidth: 445,
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
    boxShadow: theme.shadows[1],
    "@media (max-width:800px)": {
      minWidth: 45,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
    },
  },
  cardroot1: {
    marginBottom: theme.spacing(3),
    maxHeight: 340,
    margin: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  cardroot1Box: {
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
    "@media (max-width:800px)": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      paddingRight: theme.spacing(2),
    },
  },

  media: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "60%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
    },
  },
  media1: {
    height: 140,
  },

  cardrootinner: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2.25),
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1.25),
      padding: theme.spacing(1),
    },
  },
  cardrootinnerFont: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(1.75),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.2),
    },
  },

  courseTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    cursor: "pointer",
  },

  cardrootinnerIcon: {
    fontSize: theme.spacing(1.75),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontWeight: "bold",
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.9),
    },
  },
  cardrootinnerIcon11: {
    fontSize: theme.spacing(1.75),
    marginLeft: theme.spacing(0.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.5),
    },
  },
  cardrootinnerBadge: {
    width: "90%",
    height: "80%",
    "@media (max-width:800px)": {
      height: "41%",
    },
  },
  pageTitle: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(1),
  },
  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: theme.spacing(1.75),
    color: theme.palette.paper,

    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },

    "@media (max-width:800px)": {
      fontSize: ".6rem",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardrootinnerButton1: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },

    "@media (max-width:800px)": {
      fontSize: ".6rem",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  bar: {
    width: "100%",
    marginTop: theme.spacing(6),
  },
  cardIcon: {
    fontSize: theme.spacing(2),
    color: theme.palette.blue.main,
    marginLeft: theme.spacing(0.27),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1),
    },
  },

  borderclass: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  gridContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  headingText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
  },
  notSubscribedText: {
    padding: theme.spacing(4),
    // boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    fontWeight: 400,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: theme.spacing(1.5),
    },
  },
}));

export default function HomeCourses() {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [unSubscribeModal, setUnSubscribeModal] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState({});

  React.useEffect(() => {
    const getItemSuccess = (response) => {
      if (response.data.length) {
        var responseObject = response.data;
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            authSections1: responseObject.filter((data) => {
              console.log(data.type);
              return data.type !== "podcasts" && data.type !== "resources";
            }),
            authResources: responseObject.filter((data) => {
              return data.type === "resources";
            })[0],
            authPodcasts: responseObject.filter((data) => {
              return data.type === "podcasts";
            })[0],
          },
        });
      }
    };
    Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);
  }, [dispatch, global_data?.id]);

  const startCourse = (course) => {
    const addModule = (card) => {
      const addModuleList = (res) => {
        if (res.data.success) {
          history.push(
            `/courses/${course.id}/${course.subscriptionId}/${card.id}`
          );
        } else {
          CustomizeToastError(res?.data?.message);
        }
      };
      Post_API(
        Res.apiEndPoints.addModule,
        {
          subscriptionId: course.subscriptionId,
          moduleId: card?.id,
          webinar: card.webinar,
          scheduledAt: "24-07-2021 01:05:00",
          durationMinutes: "200",
        },
        addModuleList,
        true
      );
    };
    const firstModule = course.data.sections.filter(
      (module) => !module.webinar
    )[0];
    console.log("FIRST-", firstModule);
    addModule(firstModule);
  };

  const unSubscribeCoarse = () => {
    const course = selectedCourse;
    console.log("COURSE ", selectedCourse);
    const unSubscribeCoarseSuccess = (response) => {
      if (response.data.success) {
        const Courses = global_data?.authSections1?.filter((item) => {
          return item.id != course.id;
        });
        dispatch({ type: GLOBAL_DATA, payload: { authSections1: Courses } });
        setUnSubscribeModal(false);
      } else {
        setUnSubscribeModal(false);
        CustomizeToastError(response?.data?.message);
      }
    };
    Delete_API(
      Res.apiEndPoints.unSubscribeLearning,
      {
        ownerId: global_data?.id,
        courseId: course?.id,
        title: course?.heading,
        contentType: "course",
      },
      unSubscribeCoarseSuccess,
      true
    );
  };

  const continueCourse = (course) => {
    history.push(
      `/courses/${course.id}/${course.subscriptionId}/${course.currentModule}`
    );
  };

  const onClose = () => {
    setUnSubscribeModal(false);
  };
  const clickLikeButton = (course) => {
    setSelectedCourse(course);
    setUnSubscribeModal(true);
  };

  const courseOpen = (card) => {
    if (global_data.token) {
      history.push(
        `/courses/${card.id ? card.id : card.courseId}/${
          card.subscriptionId ? card.subscriptionId : "coursedetail"
        }`
      );
    } else {
      history.push("/login");
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <div>
        <Container style={{ minHeight: 500, maxWidth: 1130 }}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon fontSize="large" style={{ marginTop: 15 }} />
            }
            aria-label="breadcrumb"
          >
            <Typography
              variant="h4"
              component="h2"
              className={styles.headingText}
            >
              <Link to="/" style={{ textDecoration: "none", color: "#0D2860" }}>
                Dashboard
              </Link>
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              className={styles.headingText}
            >
              Courses
            </Typography>
          </Breadcrumbs>
          <Box className={styles.borderclass}>
            {global_data?.authSections1 &&
              global_data?.authSections1?.length == 0 && (
                <Typography
                  variant="h5"
                  component="h2"
                  className={styles.notSubscribedText}
                >
                  No Course Subscribed
                </Typography>
              )}
            <Grid container className={styles.gridContainer}>
              {global_data?.authSections1 &&
                global_data?.authSections1?.map((course, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sm={12}
                      key={`course${i}`}
                    >
                      <Box className={styles.cardrootinner}>
                        <Box pl={0.5}>
                          <Typography
                            onClick={() => courseOpen(course)}
                            variant="h5"
                            color="textPrimary"
                            component="h4"
                            className={styles.courseTitle}
                          >
                            {course?.heading}
                          </Typography>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="flex-start"
                            pt={2}
                            pb={2}
                          >
                            <IconButton aria-label="add to favorites">
                              <BookIcon className={styles.cardIcon} />
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className={styles.cardrootinnerIcon}
                              >
                                {course?.sections?.length} Sections
                              </Typography>
                            </IconButton>
                            {course?.hasTask ? (
                              <IconButton aria-label="add to favorites">
                                <AssignmentIcon className={styles.cardIcon} />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                  className={styles.cardrootinnerIcon}
                                >
                                  Task
                                </Typography>
                              </IconButton>
                            ) : null}
                            <IconButton>
                              <AccessTimeIcon className={styles.cardIcon} />
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className={styles.cardrootinnerIcon}
                              >
                                {course?.durationHours} Hours
                              </Typography>
                            </IconButton>
                            <QueryModal
                              modalState={unSubscribeModal}
                              yesText="Unsubscribe!"
                              closeText="Explore More"
                              queryText="Do you want to subscribe?"
                              onYes={() => unSubscribeCoarse()}
                              onClose={onClose}
                            />
                            <IconButton onClick={() => clickLikeButton(course)}>
                              <FavoriteIcon
                                style={{ color: "#eb349b", fontSize: 20 }}
                              />
                            </IconButton>
                          </Box>
                          <Box style={{ width: "80%" }}>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              component="p"
                              className={styles.cardrootinnerIcon11}
                            >
                              {course?.description?.length > 280
                                ? course?.description?.slice(0, 280) + "..."
                                : course?.description}
                            </Typography>
                          </Box>

                          {/* {course?.status === 1 && (
                            <Button
                              onClick={() => continueCourse(course)}
                              className={styles.cardrootinnerButton}
                            >
                              Continue Course
                            </Button>
                          )} */}

                          {course?.status === 0 && (
                            <Button
                              onClick={() => {
                                startCourse(course);
                              }}
                              className={styles.cardrootinnerButton}
                            >
                              Start Course
                            </Button>
                          )}
                          {(course?.status === 2 || course?.status === 3) && (
                            <Button className={styles.cardrootinnerButton}>
                              Completed
                            </Button>
                          )}
                        </Box>
                        <CardMedia
                          onClick={() => courseOpen(course)}
                          className={styles.media}
                          image={course?.image}
                          title={course?.heading}
                        />
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Container>
        <Footer />
      </div>
    </div>
  );
}
