import {
  Box,
  ClickAwayListener,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { Icon } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  customInputBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.paper,
    border: "1px solid",
    borderColor: theme.palette.customGrey.dark,
    paddingLeft: theme.spacing(0.9),
    flex: 1,
  },
  customInputLabel: {
    paddingBottom: 8,
    color: theme.palette.dark.main,
    fontWeight: 400,
  },
  closeButton: {
    color: theme.palette.customGrey.main,
    position: "relative",
    top: 0,
  },
  customInput: {
    padding: "12px",
    paddingLeft: theme.spacing(1),
    border: "none",
    fontSize: theme.spacing(2),
    flex: 1,
    textAlignVertical: "top",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: theme.palette.customGrey.main,
    },
  },
  focused: {
    borderColor: theme.palette.secondary.main,
  },
  blurred: {
    borderColor: theme.palette.customGrey.main,
  },
  navSearch: {
    backgroundColor: "#F3F3F3",
    width: 43,
    height: 43,
    position: "relative",
    left: -7,
  },
  showSearchField: {
    zIndex: 1000,
    display: "block",
    top: 0,
    left: 0,
    width: "calc(100% - 70px)",
    position: "absolute",
  },
  hideSearchField: {
    display: "none",
  },
}));

const SearchItem = ({ searchState, handleClose, clickHandler, ...props }) => {
  const styles = useStyle();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box component="span">
        <IconButton className={styles.navSearch} onClick={clickHandler}>
          <Icon>{searchState ? "close" : "search"}</Icon>
        </IconButton>
        <Box
          className={
            searchState ? styles.showSearchField : styles.hideSearchField
          }
        >
          <Box className={styles.customInputBox}>
            <input
              className={styles.customInput}
              type="text"
              id={props.inputId}
              {...props}
              placeholder={isFocused ? "" : props.placeholder}
              onBlur={() => {
                setIsFocused(false);
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
            />
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SearchItem;
