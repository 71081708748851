import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  CircularProgress,
  Container,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
// import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BookIcon from "@material-ui/icons/Book";
import CreateIcon from "@material-ui/icons/Create";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import PaymentModal from "../../../components/PaymentModal";
import QueryModal from "../../../components/QueryModal";
import WebinarModal from "../../../components/WebinarModal";
import ModuleButton from "./components/ModuleButton";
import WebinarButton from "./components/WebinarButton";
import useAuthoring from "./hooks/useAuthoring";
import useLearning from "./hooks/useLearning";
import usePayment from "./hooks/usePayment";
import useSubLearnings from "./hooks/useSubLearnings";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(8.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  maincontainer: {
    background: "white",
    padding: theme.spacing(8),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
  },
  borderclass: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    "@media (max-width:800px)": {
      padding: theme.spacing(0),
      overflow: "hidden",
      overflowX: "scroll",
    },
    marginBottom: theme.spacing(4),
  },

  media: {
    height: 250,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1.25),
    "@media (max-width:800px)": {
      height: 200,
    },
    [theme.breakpoints.down("sm")]: {
      height: 140,
    },
  },
  media1: {
    height: 140,
  },

  cardrootinnerIcon: {
    fontSize: theme.spacing(1.3),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.5),
    },
  },

  cardrootinnerDescription: {
    fontSize: theme.spacing(1.8),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.5),
    },
  },

  cardBottomBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },

  pageTitle: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    color: "#0D2860",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      fontSize: theme.spacing(2.5),
    },
  },
  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    marginTop: theme.spacing(4),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  bar: {
    width: "100%",
    marginTop: theme.spacing(6),
  },

  cardroot1font: {
    color: "#75A8F4",
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(0.27),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1),
    },
  },
  courseIcon: {
    color: "#75A8F4",
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(0.27),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1),
    },
  },
}));

function Contenthubmodule() {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const { courseId, subId } = useParams();
  const [moduleCompleteCount, setModuleCompleteCount] = useState(0);
  const [result, setResult] = useState(0);

  const history = useHistory();
  const location = useLocation();

  const {
    subscribeContent,
    unSubscribeContent,
    onLearningModalClose,
    clickCourseLikeButton,
    subscribeModal,
    unSubscribeModal,
    selectedCourse,
  } = useLearning();

  const {
    directBuyCourse,
    showPaymentProgress,
    cancelPayment,
    paymentCall,
    buyCourse,
    directPaymentCall,
    paymentInProgress,
    paymentModal,
    directPaymentModal,
    selectedPaymentCourse,
  } = usePayment({
    subscribeContent,
    fromCourseModulePage: true,
  });

  const { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal } =
    useAuthoring();

  const {
    data,
    setData,
    viewSection,
    addModule,
    startCourse,
    closeWebinarModal,
    bookWebinarModal,
    completeCall,
  } = useSubLearnings({ subscribeContent });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login", { prevPath: location.pathname });
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500 }}>
        <Box className={styles.maincontainer}>
          <Typography variant="h4" component="h2" className={styles.pageTitle}>
            {data?.heading}
          </Typography>
          <Box display="flex" flexDirection="column">
            <Box className={styles.borderclass}>
              <Box>
                <Box>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        {data?.sections?.length ? (
                          <IconButton aria-label="add to favorites">
                            <BookIcon className={styles.courseIcon} />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              className={styles.cardrootinnerIcon}
                            >
                              {data?.sections?.length
                                ? location.pathname?.includes("courses")
                                  ? `${data?.sections?.length} Module${
                                      data?.sections?.length === 1 ? "" : "s"
                                    }`
                                  : `${data?.sections?.length} Chapter${
                                      data?.sections?.length === 1 ? "" : "s"
                                    }`
                                : ""}
                            </Typography>
                          </IconButton>
                        ) : null}
                        <IconButton aria-label="add to favorites">
                          <AccessTimeIcon className={styles.courseIcon} />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className={styles.cardrootinnerIcon}
                          >
                            {data?.durationHours !== 0 &&
                            data?.durationHours !== "0"
                              ? `${data?.durationHours} Hours `
                              : ""}

                            {data?.durationMinutes !== 0
                              ? `${data?.durationMinutes} Minutes`
                              : null}
                          </Typography>
                        </IconButton>
                        <IconButton
                          style={{ marginLeft: "0.2rem" }}
                          onClick={() => {
                            if (location.pathname?.includes("courses")) {
                              clickCourseLikeButton(data);
                            } else {
                              data.isMyLearning
                                ? unSubscribeContent(data, {
                                    fromCourseModulePage: true,
                                  })
                                : subscribeContent(data, {
                                    fromCourseModulePage: true,
                                  });
                            }
                          }}
                        >
                          {global_data.token && (
                            <Icon
                              className={styles.courseIcon}
                              style={{
                                color: data.status >= 0 ? "#d656a1" : "#75A8F4",
                              }}
                            >
                              {data.status >= 0
                                ? "favorite"
                                : "favorite_border"}
                            </Icon>
                          )}
                        </IconButton>
                        {paymentInProgress && (
                          <Box py={1.5}>
                            <CircularProgress
                              style={{
                                color: "#75A8F4",
                                height: 21,
                                width: 21,
                              }}
                            />
                          </Box>
                        )}

                        <Box
                          ml={1}
                          color="primary"
                          component="span"
                          className={styles.cardrootinnerButton}
                          style={{
                            fontSize: 12,
                            padding: 2,
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          {!data?.price || data?.price === "0"
                            ? "Free"
                            : "₤ " + data?.price}
                        </Box>

                        <Typography
                          variant="body2"
                          color="textPrimary"
                          component="p"
                          className={styles.cardrootinnerDescription}
                          dangerouslySetInnerHTML={{
                            __html: `${data?.description || ""}`,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <CardMedia
                          className={styles.media}
                          image={data?.image}
                          title={data?.heading}
                        />
                      </Grid>
                    </Grid>

                    {data.scormId && !data.needToBuy && (
                      <Button
                        onClick={() => {
                          history.push(`/scorms/${data.id}`);
                        }}
                        className={styles.cardrootinnerButton}
                      >
                        Play Course
                      </Button>
                    )}
                    {data?.needToBuy && (
                      <Button
                        onClick={() => {
                          if (!global_data.id) {
                            return history.push("/login", {
                              prevPath: location.pathname,
                            });
                          }
                          data?.isMyLearning
                            ? directPaymentCall(data)
                            : paymentCall(data);
                        }}
                        className={styles.cardrootinnerButton}
                      >
                        Buy Course
                      </Button>
                    )}

                    {!data.scormId &&
                      data.status === 0 &&
                      !data?.needToBuy &&
                      !!data?.sections?.length && (
                        <Button
                          onClick={startCourse}
                          className={styles.cardrootinnerButton}
                        >
                          Start
                          {location.pathname?.includes("courses")
                            ? " Course"
                            : " Talks"}
                        </Button>
                      )}
                    {!data.scormId &&
                      (data.status === 2 || data.status === 3) && (
                        <Button className={styles.cardrootinnerButton}>
                          Completed
                        </Button>
                      )}
                  </Box>
                </Box>
              </Box>
              {/* <Box className={styles.bar}>
                {data?.status !== -1 && !data.scormId && (
                  <Box display="flex" justifyContent="space-between" pb={1}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={styles.cardrootinnerIcon}
                    >
                      Course Progress
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={styles.cardrootinnerIcon}
                    >
                      {`${moduleCompleteCount ? moduleCompleteCount : "0"}/${
                        data?.sections?.length
                      } module`}
                    </Typography>
                  </Box>
                )}
                <LinearProgress
                  variant="determinate"
                  value={data?.sections?.length > 0 ? result : 0}
                />
              </Box> */}
            </Box>

            {global_data.isEditable && (
              <>
                <Box display="flex" py={1} justifyContent="flex-end">
                  {location.pathname?.includes("courses") ? (
                    <>
                      <Button
                        style={{
                          padding: 5,
                          textTransform: "none",
                          marginRight: 5,
                        }}
                        onClick={() =>
                          history.push("/create/module/" + courseId)
                        }
                      >
                        <AddCircleIcon
                          style={{ fontSize: 21 }}
                          color="primary"
                        />
                        <Typography variant="caption">Add Module</Typography>
                      </Button>

                      <Button
                        style={{ padding: 5, textTransform: "none" }}
                        onClick={() =>
                          history.push("/create/webinar/" + courseId)
                        }
                      >
                        <AddCircleIcon
                          style={{ fontSize: 21 }}
                          color="primary"
                        />
                        <Typography variant="caption">Add Webinar</Typography>
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{
                        padding: 5,
                        textTransform: "none",
                        marginRight: 5,
                      }}
                      onClick={() => history.push("/create/talk/" + courseId)}
                    >
                      <AddCircleIcon style={{ fontSize: 21 }} color="primary" />
                      <Typography variant="caption">Add Talk</Typography>
                    </Button>
                  )}
                </Box>
              </>
            )}
            {data.scormId ? null : (
              <Box>
                <Box className={styles.borderclass}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    px={1}
                    flexDirection="row"
                  >
                    <Box
                      component="h5"
                      fontSize={18}
                      fontWeight="fontWeightBold"
                    >
                      {location.pathname?.includes("courses")
                        ? "Modules"
                        : "Chapters"}
                    </Box>
                  </Box>

                  <Grid container>
                    {data?.sections?.map((card, i) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          key={i}
                          className={styles.gridItem}
                        >
                          <Card
                            style={{
                              minHeight: global_data.isEditable ? 350 : 300,
                            }}
                          >
                            {global_data.isEditable ? (
                              <>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      openDeleteModal(card);
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{ fontSize: 21 }}
                                      color="primary"
                                    />
                                  </Button>

                                  <IconButton
                                    onClick={() => {
                                      history.push(
                                        `/update/${card.type}/${card.contentId}/${card.id}`
                                      );
                                    }}
                                  >
                                    <CreateIcon
                                      style={{
                                        fontSize: 21,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </>
                            ) : null}
                            <CardActionArea>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                p={1}
                              >
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  component="p"
                                  style={{ fontWeight: 600, color: "black" }}
                                >
                                  {card?.heading}
                                </Typography>
                                <div>
                                  {card?.type === "webinar" &&
                                  !data.needToBuy ? (
                                    <WebinarButton
                                      section={card}
                                      completeCall={completeCall}
                                      addModule={addModule}
                                      fromCourseModulePage={true}
                                    />
                                  ) : (
                                    <Box display="flex" flexDirection="column">
                                      {data?.status >= 0 && !data.needToBuy && (
                                        <ModuleButton
                                          section={card}
                                          addModule={addModule}
                                          viewSection={viewSection}
                                        />
                                      )}
                                      <Box
                                        style={{ visibility: "hidden" }}
                                        py={0.5}
                                      >
                                        hiii
                                      </Box>
                                    </Box>
                                  )}
                                </div>
                              </Box>

                              <CardMedia
                                className={styles.media1}
                                image={card?.image}
                                title={card?.heading}
                                onClick={() => {
                                  if (data.needToBuy) return;
                                  card?.status === 0 && card?.type !== "webinar"
                                    ? addModule(card)
                                    : viewSection(card);
                                }}
                              />
                            </CardActionArea>
                            <WebinarModal
                              onClose={closeWebinarModal}
                              modalState={bookWebinarModal}
                              text="Your webinar is booked. The webinar details will be emailed shortly"
                            />
                            <CardActions>
                              <Box className={styles.cardBottomBox}>
                                <Box>
                                  {card.type === "webinar" ? (
                                    <IconButton
                                      aria-label="video"
                                      onClick={() =>
                                        card?.status === 0
                                          ? addModule(card)
                                          : viewSection(card)
                                      }
                                    >
                                      <PeopleOutlineIcon
                                        className={styles.cardroot1font}
                                      />
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        className={styles.cardrootinnerIcon}
                                      >
                                        Webinar
                                      </Typography>
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      aria-label="video"
                                      onClick={() =>
                                        card?.status === 0
                                          ? addModule(card)
                                          : viewSection(card)
                                      }
                                    >
                                      <PlayArrowIcon
                                        className={styles.cardroot1font}
                                      />
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        className={styles.cardrootinnerIcon}
                                      >
                                        {card?.fileType}
                                      </Typography>
                                    </IconButton>
                                  )}
                                </Box>
                                <Box>
                                  {((card?.state === 0 &&
                                    card?.type === "webinar") ||
                                    (card?.state === 1 &&
                                      card?.type === "webinar")) && (
                                    <IconButton aria-label="add to favorites">
                                      <DateRangeIcon
                                        className={styles.cardroot1font}
                                        style={{ marginRight: "0" }}
                                      />
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        className={styles.cardrootinnerIcon}
                                      >
                                        {card?.localWebinarDate
                                          ? card?.localWebinarDate
                                          : ""}
                                        <br />
                                        {card?.localWebinarTime
                                          ? card?.localWebinarTime
                                          : ""}
                                      </Typography>
                                    </IconButton>
                                  )}
                                </Box>
                                <Box>
                                  <IconButton aria-label="add to favorites">
                                    <AccessTimeIcon
                                      className={styles.cardroot1font}
                                      style={{ marginRight: "0" }}
                                    />
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      className={styles.cardrootinnerIcon}
                                    >
                                      {card?.durationHours !== 0 &&
                                      card?.durationHours !== "0"
                                        ? `${card?.durationHours} Hours `
                                        : ""}

                                      {!card?.durationHours &&
                                      card?.durationMinutes
                                        ? `${card?.durationMinutes} Minutes`
                                        : ""}
                                    </Typography>
                                  </IconButton>
                                </Box>
                              </Box>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            )}

            <QueryModal
              modalState={subscribeModal}
              yesText="Save to My Information"
              onYes={() =>
                subscribeContent(selectedCourse, { fromCourseModulePage: true })
              }
              onClose={onLearningModalClose}
            />
            <QueryModal
              modalState={unSubscribeModal}
              yesText="Unsubscribe!"
              closeText="Explore More"
              queryText="Are you sure you want to unsubscribe ?"
              onYes={() =>
                unSubscribeContent(selectedCourse, {
                  fromCourseModulePage: true,
                })
              }
              onClose={onLearningModalClose}
            />
            <PaymentModal
              modalState={paymentModal}
              subscribeMethod={buyCourse}
              onClose={cancelPayment}
              showPaymentProgress={showPaymentProgress}
              selectedDate={selectedPaymentCourse}
            />
            <PaymentModal
              modalState={directPaymentModal}
              subscribeMethod={(paymentId) =>
                directBuyCourse(paymentId, () =>
                  setData({ ...data, needToBuy: false })
                )
              }
              onClose={cancelPayment}
              showPaymentProgress={showPaymentProgress}
              selectedDate={selectedPaymentCourse}
            />
            <QueryModal
              modalState={deleteModalFlag}
              queryText="Are you sure you want to delete this ?"
              yesText="Yes"
              closeText="No"
              onYes={() => {
                deleteContent({
                  onSuccess: (selected) => {
                    let filteredSections = data.sections?.filter(
                      (sec) => sec.id !== selected.id
                    );
                    data.sections = filteredSections;
                    setData(data);
                  },
                });
              }}
              onClose={() => {
                closeDeleteModal();
              }}
            />
          </Box>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Contenthubmodule;
