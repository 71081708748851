import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-community/async-storage";
import contentReducer from "./reducers/content.reducer";
import profileReducer from "./reducers/profile.reducer";

const rootReducer = combineReducers({
  commonReducer: contentReducer,
  profileData: profileReducer,
});

const config = {
  key: "parentvillage",
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(config, rootReducer);
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
