import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Res } from "../../../../resources";
import {
  Delete_API,
  Post_API,
  Post_API_With_Token,
  Put_API,
} from "../../../../services/ApiServies";
import { CustomizeToastError } from "../../../../services/CommonLogic";
import {
  completeContentItemAction,
  subscribeContentItemAction,
  unSubscribeContentItemAction,
} from "../../../../store/actions";
import {
  subscribeContentPackageAction,
  unSubscribeContentPackageAction,
} from "../../../../store/actions";

const useLearning = () => {
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [unSubscribeModal, setUnSubscribeModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // in case of fromCourseModulePage
  const { courseId, subId } = useParams();

  const subscribeContent = (
    card,
    {
      onSuccess,
      onFailure,
      onFinal,
      fromCourseModulePage = false,
      paymentId,
    } = {}
  ) => {
    if (!global_data.token) {
      return history.push("/login");
    }

    if (
      (card.type === "class" || card.type === "webinar") &&
      card?.numSlots === card?.numBooked
    ) {
      return;
    }

    const subscribeSuccess = (res) => {
      if (res.data.success) {
        if (card.type === "course" || card.type === "talks") {
          let updatedAllCourses = subscribeContentPackageAction(
            dispatch,
            card,
            res,
            global_data,
            {
              afterPayment: !!paymentId && !card?.isMyLearning,
              justPayment: !!paymentId && card?.isMyLearning,
            }
          );
          if (typeof onSuccess === "function") {
            onSuccess(updatedAllCourses);
          }
          if (fromCourseModulePage) {
            history.replace(
              `/${
                location.pathname?.includes("courses") ? "courses" : "talks"
              }/${courseId}/${
                res.data?.object?.subscriptionId
                  ? res.data?.object?.subscriptionId
                  : subId
              }`
            );
          }
        } else {
          subscribeContentItemAction(dispatch, card, res, global_data);
          if (typeof onSuccess === "function") {
            onSuccess();
          }
          if (card.type === "class" || card.type === "webinar") {
            Post_API_With_Token(
              Res.apiEndPoints.job,
              {
                webinarId: card?.id,
                minutesBefore: 10,
                hoursBefore: 1,
                daysBefore: 1,
                scheduledAt: card?.webinarDate,
                jobTitle: card?.heading,
                type: card.type,
                url: card?.webinarLink,
                fname: global_data.profile.fname,
                email: global_data.email,
                localTime: card.localWebinarDate + " " + card.localWebinarTime,
              },
              () => {},
              global_data.token
            );
          }
        }
      } else {
        console.log(res?.data?.message);
        CustomizeToastError(res?.data?.message);
        if (typeof onFailure === "function") {
          onFailure();
        }
      }
      if (typeof onFinal === "function") {
        onFinal();
      }
      if (card.type === "course") {
        setSubscribeModal(false);
      }
    };

    if (card.type === "talks" || card.type === "course") {
      let data = {
        ownerId: global_data?.id,
        courseId: card.id ? card.id : card.courseId,
        title: card?.title ? card?.title : card?.heading,
        email: global_data?.email,
        contentType: card.type,
      };

      if (card.scormId) {
        data = { ...data, scormId: card.scormId, desc: card.description };
      }
      if (paymentId) {
        data = {
          ...data,
          chargeAmount:
            (parseInt(card?.price) + 0.2 * parseInt(card?.price)) * 100,
          paymentstripeToken: paymentId,
        };
      }
      Post_API(Res.apiEndPoints.subscribeCourse, data, subscribeSuccess, true);
    } else {
      let subscriptionId;
      if (card.type === "resource") {
        subscriptionId = global_data.authResources?.subscriptionId;
      } else if (card.type === "podcast") {
        subscriptionId = global_data.authPodcasts?.subscriptionId;
      } else if (card.type === "class") {
        subscriptionId = global_data.authClasses?.subscriptionId;
      } else if (card.type === "talk") {
        subscriptionId = global_data.allTalks?.find(
          (talk) => talk.id === card.contentId
        )?.subscriptionId;
      } else if (card.type === "module" || card.type === "webinar") {
        subscriptionId = global_data.allCourses?.find(
          (course) => course.id === card.contentId
        )?.subscriptionId;
      }
      let data = {
        subscriptionId,
        moduleId: card?.id,
        email: global_data?.email,
        durationMinutes: card?.durationHours * 60 + card.durationMinutes,
        webinar: false,
        itemTitle: card?.heading,
        contentItemType: card?.type,
      };
      if (card.webinar || card.webinarDate) {
        data = {
          ...data,
          webinar: true,
          scheduledAt: card?.webinarDate,
          numSlots: card?.numSlots || "",
        };

        if (paymentId) {
          data = {
            ...data,
            chargeAmount:
              (parseInt(card?.price) + 0.2 * parseInt(card?.price)) * 100,
            paymentstripeToken: paymentId,
          };
        }
      }
      Post_API(Res.apiEndPoints.addModule, data, subscribeSuccess, true);
    }
  };

  const unSubscribeContent = (
    card,
    { onSuccess, onFailure, onFinal, fromCourseModulePage = false } = {}
  ) => {
    const unSubscribeSuccess = (res) => {
      if (res.data.success) {
        if (card.type === "course" || card.type === "talks") {
          unSubscribeContentPackageAction(dispatch, card, res, global_data);
        } else {
          unSubscribeContentItemAction(dispatch, card, res, global_data);
        }
        if (typeof onSuccess === "function") {
          onSuccess();
        }
        if (fromCourseModulePage) {
          history.replace(
            `/${
              location.pathname?.includes("courses") ? "courses" : "talks"
            }/${courseId}/${
              location.pathname?.includes("courses")
                ? "coursedetail"
                : "talksdetail"
            }`
          );
        }
      } else {
        console.log(res?.data?.message);
        CustomizeToastError(res?.data?.message);
        if (typeof onFailure === "function") {
          onFailure();
        }
      }
      if (typeof onFinal === "function") {
        onFinal();
      }
      if (card.type === "course") {
        setUnSubscribeModal(false);
      }
    };
    if (card.type === "talks" || card.type === "course") {
      Delete_API(
        Res.apiEndPoints.unSubscribeLearning,
        {
          ownerId: global_data?.id,
          email: global_data?.email,
          courseId: card?.id,
          title: card?.heading,
          contentType: card.type,
        },
        unSubscribeSuccess,
        true
      );
    } else {
      Delete_API(
        Res.apiEndPoints.unSubscribeResourcePodcast,
        {
          ownerId: global_data?.id,
          email: global_data?.email,
          learningId: card?.learningId,
          title: card?.heading,
          contentType: card.type,
          courseId: "",
        },
        unSubscribeSuccess,
        true
      );
    }
  };

  const onLearningModalClose = () => {
    setSubscribeModal(false);
    setUnSubscribeModal(false);
  };

  const clickCourseLikeButton = (course) => {
    console.log("Course Detail ", course);
    if (!global_data.token) {
      history.push("/login");
    } else if (!course.isMyLearning) {
      setSubscribeModal(true);
    } else if (course.isMyLearning) {
      setUnSubscribeModal(true);
    }
    setSelectedCourse(course);
  };

  const courseOpen = (card) => {
    if (global_data.token) {
      history.push(
        `/courses/${card.id ? card.id : card.courseId}/${
          card.isMyLearning ? card.subscriptionId : "coursedetail"
        }`
      );
    } else {
      history.push("/login");
    }
  };

  const moduleCompleteCall = (card) => {
    Put_API(
      Res.apiEndPoints.updateModule,
      { moduleId: card?.id, markCompleted: true },
      (res) => {
        completeContentItemAction(dispatch, card, res, global_data);
      }
    );
  };
  return {
    subscribeContent,
    unSubscribeContent,
    onLearningModalClose,
    courseOpen,
    clickCourseLikeButton,
    subscribeModal,
    setSubscribeModal,
    unSubscribeModal,
    setUnSubscribeModal,
    selectedCourse,
    setSelectedCourse,
    moduleCompleteCall,
  };
};

export default useLearning;
