import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Res } from "../../../../resources";
import { Post_API } from "../../../../services/ApiServies";
import { CustomizeToastError } from "../../../../services/CommonLogic";
import { deleteContentItemAction } from "../../../../store/actions";
import { deleteContentPackageAction } from "../../../../store/actions";

const useAuthoring = () => {
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [selected, setSelected] = useState("");

  const openDeleteModal = (selected) => {
    setSelected(selected);
    setDeleteModalFlag(true);
  };
  const closeDeleteModal = () => {
    setSelected("");
    setDeleteModalFlag(false);
  };

  const deleteContent = ({ onSuccess } = {}) => {
    const deleteModuleSuccess = (res) => {
      if (res.data.success) {
        if (selected.type === "course" || selected.type === "talks") {
          deleteContentPackageAction(dispatch, selected, res, global_data);
        } else {
          deleteContentItemAction(dispatch, selected, res, global_data);
          if (typeof onSuccess === "function") {
            onSuccess(selected);
          }
        }
      } else {
        console.log(res?.data?.message);
        CustomizeToastError(res?.data?.message);
      }
      closeDeleteModal();
    };
    Post_API(
      Res.apiEndPoints.deleteContent,
      { type: selected.type, id: selected.id },
      deleteModuleSuccess
    );
  };

  return { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal };
};

export default useAuthoring;
