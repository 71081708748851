import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import InstagramIcon from '@mui/icons-material/Instagram';
import { Res } from "../resources";
import useFooter from "../resources/localStorage/hooks/useFooter";
import baseContent from "../services/Content";
import { ReactComponent as InstagramIcon } from "./instagram.svg";
import { ReactComponent as TiktokIcon } from "./tiktok.svg";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5),
    },
  },
  footerbackcolour: {
    backgroundColor: Res.variables.color.secondaryColor,
    bottom: 0,
    "@media (max-width:800px)": {
      backgroundColor: Res.variables.color.whiteColor,
      boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
    },
  },
  footerlink: {
    fontSize: "1.3em",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px",
    },
  },

  footerfont: {
    color: Res.variables.color.whiteColor,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: theme.spacing(0.5),
    "@media (max-width:800px)": {
      fontSize: 10,
      color: Res.variables.color.secondaryColor,
      width: "100%",
    },
  },
  footerfontcopyright: {
    color: Res.variables.color.whiteColor,
    fontWeight: theme.typography.fontWeightRegular,
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.25),
      color: Res.variables.color.secondaryColor,
    },
  },
  footericon: {
    color: Res.variables.color.whiteColor,
    cursor: "pointer",
    marginTop: theme.spacing(1),
    "@media (max-width:800px)": {
      color: Res.variables.color.secondaryColor,
    },
    // [theme.breakpoints.down("xs")]: {
    //   color: Res.variables.color.secondaryColor,
    // },
  },
}));

const Footer = (props) => {
  const styles = makeStyle();
  const { footer } = useFooter();
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();
  return (
    <Box className={styles.footerbackcolour}>
      <Container className={styles.root} lg={12}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box display="flex" justifyContent="space-around">
              <Box mr={4}>
                <Typography variant="caption">
                  <Box className={styles.footerfont}>
                    <Link to="/terms" className={styles.footerfont}>
                      {footer?.termOfBusinessNav}
                    </Link>
                    {global_data.isEditable && (
                      <CreateIcon
                        onClick={() => {
                          history.push(
                            `/update/siteFooter/termOfBusinessNav/${footer.id}`
                          );
                        }}
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                      />
                    )}
                  </Box>
                  <Box className={styles.footerfont}>
                    <Link to="/privacy" className={styles.footerfont}>
                      {footer?.privacyPolicyNav}
                    </Link>
                    {global_data.isEditable && (
                      <CreateIcon
                        onClick={() => {
                          history.push(
                            `/update/siteFooter/privacyPolicyNav/${footer.id}`
                          );
                        }}
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                      />
                    )}
                  </Box>
                  <Box className={styles.footerfont}>
                    <Link to="/cookie" className={styles.footerfont}>
                      {footer?.cookiePolicyNav}
                    </Link>
                    {global_data.isEditable && (
                      <CreateIcon
                        onClick={() => {
                          history.push(
                            `/update/siteFooter/cookiePolicyNav/${footer.id}`
                          );
                        }}
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                      />
                    )}
                  </Box>
                  <Box className={styles.footerfont}>
                    <a
                      style={{ textDecoration: "none" }}
                      href={footer?.sitemapUrl}
                      target="_blank"
                    >
                      <Box className={styles.footerfont}>
                        {footer?.sitemapNav}
                      </Box>
                    </a>
                    {global_data.isEditable && (
                      <CreateIcon
                        onClick={() => {
                          history.push(
                            `/update/siteFooter/sitemapUrl/${footer.id}`
                          );
                        }}
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                      />
                    )}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="caption">
              <Box className={styles.footerfont} fontWeight="fontWeightBold">
                {global_data.isEditable && (
                  <CreateIcon
                    onClick={() => {
                      history.push(
                        `/update/siteFooter/rightTitle/${footer.id}`
                      );
                    }}
                    style={{}}
                    className={styles.footerlink}
                  />
                )}
                {footer?.rightTitle}
              </Box>
              <Box className={styles.footerfont}>
                {global_data.isEditable && (
                  <CreateIcon
                    onClick={() => {
                      history.push(
                        `/update/siteFooter/phoneNumber/${footer.id}`
                      );
                    }}
                    style={{ fontSize: "1.3em", cursor: "pointer" }}
                  />
                )}
                <a
                  className={styles.footerfont}
                  href={`tel:${baseContent.pageText.footer.phone}`}
                  style={{ textDecoration: "none" }}
                >
                  {footer?.phoneNumber}
                </a>
              </Box>
              <Box className={styles.footerfont}>
                {global_data.isEditable && (
                  <CreateIcon
                    onClick={() => {
                      history.push(`/update/siteFooter/email/${footer.id}`);
                    }}
                    style={{ fontSize: "1.3em", cursor: "pointer" }}
                  />
                )}
                <a
                  className={styles.footerfont}
                  href={`mailto:${footer?.email}`}
                >
                  {footer?.email}
                </a>
              </Box>
              <Box ml={1} className={styles.footerfont}>
                {footer?.socialLinks?.map((socialLink, i) => (
                  <div key={`footer_key_${i}`}>
                    <a target="blank" href={socialLink?.url}>
                      {i === 0 && (
                        <LinkedInIcon className={styles.footericon} />
                      )}
                      {i === 1 && (
                        <FacebookIcon className={styles.footericon} />
                      )}
                      {i === 2 && (
                        <InstagramIcon
                          className={styles.footericon}
                          style={{ marginLeft: ".25em", marginRight: ".25em" }}
                        />
                      )}
                      {i === 3 && (
                        <TiktokIcon
                          className={styles.footericon}
                          style={{ marginLeft: ".25em", marginRight: ".25em" }}
                        />
                      )}
                    </a>
                    {global_data.isEditable && (
                      <CreateIcon
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                        onClick={() => {
                          history.push(
                            `/update/pageItem/socialLinks_${i}/${socialLink?.id}`
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="caption" align="center">
            <Box py={2} className={styles.footerfontcopyright}>
              {footer?.copyRight}
              {global_data.isEditable && (
                <CreateIcon
                  onClick={() => {
                    history.push(`/update/siteFooter/copyRight/${footer.id}`);
                  }}
                  style={{ fontSize: "1.3em", cursor: "pointer" }}
                />
              )}
            </Box>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
