import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page, Get_Page_Alt } from "../../../services/ApiServies";
import errorAction from "../../../store/actions/errorAction";
import keys from "../constantKeys";

const useJournalPageStorage = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localJournal, setLocalJournal] = useSessionStorage(
    keys.JOURNAL_PAGE,
    null
  );
  const history = useHistory();

  const fetchJournalPage = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const localJournalImageArticle = res.data.part1;
      const localJournalFeatures = res.data.journalFeatures;
      const localJournalFeatures2 = res.data.journalFeatures2;
      sessionStorage.removeItem(keys.JOURNAL_PAGE);
      setLocalJournal({
        localJournalImageArticle,
        localJournalFeatures,
        localJournalFeatures2,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "journal",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localJournal === null) {
      fetchJournalPage();
    }
  }, []);

  return { ...localJournal, fetchJournalPage };
};

export default useJournalPageStorage;
