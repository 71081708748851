import { Box, Button, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import getCreateRoute from "../../../../utils/getCreateRoute";
import CarouseItem from "./CarouseItem";
import "./carousel.css";
import makeStyle from "./styles";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CustomCarousel = (props) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [carouselCount, setCarouselCount] = useState(2);

  useEffect(() => {
    function resize() {
      console.log("Window width****", window.innerWidth, carouselCount);
      if (window.innerWidth <= 620) {
        setCarouselCount(1);
      } else if (window.innerWidth <= 960) {
        setCarouselCount(2);
      } else if (window.innerWidth > 960) {
        setCarouselCount(4);
      }
    }

    if (window.innerWidth <= 620) {
      setCarouselCount(1);
    } else if (window.innerWidth <= 960) {
      setCarouselCount(2);
    } else if (window.innerWidth > 960) {
      setCarouselCount(4);
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [carouselCount]);

  const linkChange = () => {
    history.push(props.link);
  };

  return (
    <Box className={styles.borderclass}>
      {global_data.isEditable && (
        <Box display="flex" py={1} justifyContent="flex-end">
          <Button
            style={{ padding: 5, textTransform: "none" }}
            onClick={() => {
              history.push(getCreateRoute({ type: props.type }));
            }}
          >
            <AddCircleIcon color="primary" size="large" />
            <Typography variant="caption">{`Add ${props.carouselTitle}`}</Typography>
          </Button>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mx={0.6}>
        <Box className={styles.titleBox}>
          <Box fontSize={18} pb={0.5} fontWeight="fontWeightBold">
            {props.carouselTitle}
          </Box>
          <Box fontSize={15} mb={2}>
            {props.carouselSubTitle}
          </Box>
        </Box>
        <Button
          className={styles.viewMore}
          size="small"
          onClick={() => linkChange()}
        >
          View More
        </Button>
      </Box>
      <Box className={styles.cardroot1Box}>
        <Carousel
          responsive={responsive}
          speed={500}
          slidesToShow={carouselCount}
          slidesToScroll={1}
          infinite={true}
        >
          {props?.carouselItems?.map((card, i) => (
            <CarouseItem
              key={`${props.carouselTitle}_${i}`}
              card={card}
              {...props}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default CustomCarousel;
