import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Res } from "../../../../resources";
import { Get_Page } from "../../../../services/ApiServies";
import { GLOBAL_DATA } from "../../../../store/actionTypes";

const useAllCourses = () => {
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [allCourses, setAllCourses] = useState(global_data.allCourses);

  useEffect(() => {
    const getDataSuccess = (response) => {
      if (response?.data?.courses) {
        dispatch({
          type: GLOBAL_DATA,
          payload: { allCourses: response.data.courses },
        });
      }
    };
    Get_Page(Res.apiEndPoints.courses, {}, getDataSuccess, global_data?.token);
  }, [global_data.id]);

  useEffect(() => {
    setAllCourses(global_data.allCourses);
  }, [global_data.allCourses]);

  return [allCourses, setAllCourses];
};

export default useAllCourses;
