import {
  Box,
  Container,
  Grid,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import ItemGrid from "../../../components/ItemGrid";
import useWhoWeArePageStorage from "../../../resources/localStorage/hooks/useWhoWeArePageStorage";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import PageBottomSection from "../../../components/PageBottomSection";
import useBottomSections from "../../../resources/localStorage/hooks/useBottomSections";
import h2p from "html2plaintext";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(15),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  pageTitle1: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },

    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  pageText: {
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
      textAlign: "left",
    },
  },
  imageWhoWeAreBig: {
    maxHeight: 270,
    display: "flex",
    alignSelf: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      width: "60%",
    },
  },
  mainContainer: {
    maxWidth: 1090,
    marginLeft: "auto",
    marginRight: "auto",
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(6),
    },
  },
  purchaseButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(0.3),
    textTransform: "none",
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.paper,
    marginTop: theme.spacing(4),
    padding: theme.spacing(0.7),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  gridContainer: {
    display: "flex",
  },
}));

function WhoWeAre() {
  const styles = makeStyle();
  const { localWhoWeAreArticle, localWhoWeAreImageArticle, localTeam } =
    useWhoWeArePageStorage();
  const global_data = useSelector((state) => state.commonReducer);
  const { whoweare: whoweareBottomSection } = useBottomSections();

  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500 }}>
        <Box className={styles.mainContainer}>
          <Box mb={3} className={styles.buttonContainer}>
            <Typography
              variant="h4"
              component="h2"
              className={styles.pageTitle}
            >
              {localWhoWeAreArticle?.title}
            </Typography>
            {global_data.isEditable && (
              <IconButton
                onClick={() => {
                  history.push(
                    `/update/pageItem/whowearearticle/${localWhoWeAreArticle?.id}`
                  );
                }}
              >
                <CreateIcon />
              </IconButton>
            )}
          </Box>
          <Box mb={6}>
            <Typography
              variant="h6"
              component="p"
              className={styles.pageText}
              // dangerouslySetInnerHTML={{
              //   __html: localWhoWeAreArticle?.description || "",
              // }}
            >
              {h2p(localWhoWeAreArticle?.description)}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography
              variant="h4"
              component="h2"
              className={styles.pageText}
              style={{ fontWeight: 500 }}
            >
              Our Team
            </Typography>
          </Box>
          <Grid container className={styles.gridContainer}>
            <Grid item xs={12}>
              <ItemGrid page="team" gridItems={localTeam?.members} />
            </Grid>
          </Grid>
        </Box>
        <PageBottomSection
          page="whoweare"
          item={whoweareBottomSection}
          buttonText="Find out more"
          // title="Book Classes"
          // des="Enjoy our expert-led sessions from the comfort of your sofa"
          // btnName="Find out more"
        />
      </Container>
      <Footer />
    </div>
  );
}

export default WhoWeAre;
