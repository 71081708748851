import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Avatar,
  Button,
  Icon,
} from "@material-ui/core";
import { Link as RouteLink } from "react-router-dom";
import Header from "../../../components/header/Header";
import CustomButton from "../../../components/CustomButton";
import Footer from "../../../components/Footer";
import { Get_API, Post_API } from "../../../services/ApiServies";
import { Res } from "../../../resources";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_DATA, GLOBAL_DATA } from "../../../store/actionTypes";
import baseContent from "../../../services/Content";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../services/CommonLogic";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import useFooter from "../../../resources/localStorage/hooks/useFooter";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  loginImg: {
    maxWidth: 150,
    paddingBottom: theme.spacing(2),
  },
  image: {
    width: 160,
    height: 160,
  },
  smallimg: {
    position: "absolute",
    bottom: theme.spacing(1.25),
    right: theme.spacing(2.25),
    background: theme.palette.primary.light,
  },

  profileImageBox: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },
  inputBox: {
    borderBottom: "1px solid",
    borderTop: "1px solid",
    borderBottomColor: theme.palette.customGrey.light,
    borderTopColor: theme.palette.customGrey.light,
    marginBottom: -1,
    position: "relative",
    height: 50,
  },
  profileItemButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: 50,
    padding: theme.spacing(0.3),
    color: theme.palette.dark.main,
    textTransform: "unset",
    fontSize: theme.spacing(2),
  },
}));

function Profile() {
  const styles = makeStyle();
  const dispatch = useDispatch();
  let history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const [name, setName] = useState(
    global_data.profile.fname + " " + global_data.profile.lname
  );
  const [loader, setLoader] = useState(false);

  const [uploaded, setUploaded] = useState(false);

  const [profileImage, setProfileImage] = useState(
    global_data.profile.profileImage + "?" + new Date().getTime()
  );
  const [picture, setPicture] = useState(
    global_data.profile.profileImage + "?" + new Date().getTime()
  );
  const [showSupport, setShowSupport] = useState(false);
  const { footer } = useFooter();

  const showTab = () => setShowSupport(!showSupport);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const unSubscribeNotification = () => {
    dispatch({ type: DELETE_DATA });
    localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    if (global_data.token) {
      console.log("Profile", global_data.profile);
      const profileSuccess = (response) => {
        if (response.data.success) {
          var responseObject = response.data.object;
          dispatch({
            type: GLOBAL_DATA,
            payload: {
              profile: responseObject,
            },
          });
          setName(responseObject.fname + " " + responseObject.lname);
          setProfileImage(
            responseObject.profileImage + "?" + new Date().getTime()
          );
        }
      };

      Get_API(
        Res.apiEndPoints.profileOwner + "/" + global_data.id,
        {},
        profileSuccess,
        true,
        false
      );
    } else {
      history.push("/login");
    }
  }, [
    global_data.id,
    global_data.profile.fname,
    global_data.profile.fname,
    global_data.profile.profileImage,
  ]);

  // useEffect(() => { const profileSuccess = (response) => {
  //   if (response.data.success) {
  //     var responseObject = response.data.object;
  //     dispatch({
  //       type: GLOBAL_DATA,
  //       payload: {
  //         profile: responseObject,
  //       },
  //     });
  //     setName(responseObject.fname + " " + responseObject.lname);
  //     setProfileImage(responseObject.profileImage);
  //   }
  // };

  // Get_API(
  //   Res.apiEndPoints.profileOwner + "/" + global_data.id,
  //   {},
  //   profileSuccess,
  //   true,
  //   false
  // );

  // }, [picture]);

  const uploadImage = (event) => {
    setLoader(true);

    global_data.imageChanged += 1;
    Post_API(
      Res.apiEndPoints.profilePicture + "/" + global_data.profile.profileId,
      {
        file: event.target.files[0],
      },
      updateProfileImageSuccess,
      true,
      true
    );
  };

  const updateProfileImageSuccess = (response) => {
    if (response.data.success) {
      setTimeout(() => {
        setUploaded(response.data.success);
        setPicture(
          response.data.object.profileImage + "?" + new Date().getTime()
        );
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            profile: {
              ...global_data.profile,
              profileImage:
                response.data.object.profileImage + "?" + new Date().getTime(),
            },
          },
        });
        setLoader(false);
      }, 500);
    } else {
      setLoader(false);
      CustomizeToastError(
        "Image size cannot be greater than 1 MB. Please use a smaller image"
      );
    }
  };

  const forgotHandler = () => {
    if (global_data.email) {
      Post_API(
        Res.apiEndPoints.userRegenerate,
        { email: global_data.email },
        forgotSuccess,
        false
      );
    }
  };
  const forgotSuccess = (response) => {
    if (response.data.status) {
      history.push("setpassword");
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          email: response.data.data.email,
          id: response.data.data.userid,
        },
      });
      CustomizeToast(
        response.data.msg,
        () => history.push("setpassword"),
        Res.variables.toastTimerDelay
      );
    } else {
      CustomizeToastError(response.data.msg);
    }
  };

  return (
    <div>
      <Header profileImage={profileImage} allow={uploaded} />
      <Container className={styles.root} lg>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="relative"
        >
          <Box
            pb={1}
            fontWeight="fontWeightBold"
            textAlign="center"
            fontSize="h3.fontSize"
            color="secondary.main"
          >
            {baseContent.pageText.profile.topHeading}
          </Box>
          <Box className={styles.profileImageBox}>
            <Avatar alt={name} src={picture} className={styles.image} />

            <Box component="div">
              <label htmlFor="file-upload" className="custom-file-upload">
                {!loader ? (
                  <Avatar className={styles.smallimg}>
                    <Icon>camera_alt</Icon>
                  </Avatar>
                ) : (
                  <Loader />
                )}
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={uploadImage}
              />
            </Box>
          </Box>
          <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
            <Box
              display="flex"
              justifyContent="center"
              mb={2}
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <Box
                  fontWeight="fontWeightBold"
                  textAlign="center"
                  fontSize="h6.fontSize"
                >
                  {name}
                </Box>
              </Box>
            </Box>
            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                <RouteLink to="contact" style={{ textDecoration: "none" }}>
                  <Button
                    className={styles.profileItemButton}
                    endIcon={
                      <Icon style={{ fontSize: 30 }}>
                        keyboard_arrow_right_icon
                      </Icon>
                    }
                  >
                    Contact Information
                  </Button>
                </RouteLink>
              </Box>
            </Box>
            {/* <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                <Button
                  className={styles.profileItemButton}
                  endIcon={
                    <Icon style={{ fontSize: 30 }}>
                      keyboard_arrow_right_icon
                    </Icon>
                  }
                >
                  Subscription
                </Button>
              </Box>
            </Box> */}
            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                <Button
                  className={styles.profileItemButton}
                  endIcon={
                    <Icon style={{ fontSize: 30 }}>
                      keyboard_arrow_right_icon
                    </Icon>
                  }
                  onClick={forgotHandler}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
            <Box className={styles.inputBox}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                <Button
                  className={styles.profileItemButton}
                  endIcon={
                    <Icon style={{ fontSize: 30 }}>
                      keyboard_arrow_right_icon
                    </Icon>
                  }
                  onClick={() => history.push("/paymenthistory")}
                >
                  Payment History
                </Button>
              </Box>
            </Box>
            <Box
              className={styles.inputBox}
              style={{ height: showSupport ? 160 : null }}
            >
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                <Button
                  className={styles.profileItemButton}
                  endIcon={
                    <Icon style={{ fontSize: 30 }}>
                      keyboard_arrow_right_icon
                    </Icon>
                  }
                  onClick={showTab}
                >
                  Support
                </Button>
              </Box>
              {showSupport && (
                <Box py={2.5} pt={2.5} pl={0.3}>
                  <Box display="flex" flexDirection="row" alignItems="baseline">
                    <Box flex={2.5} pr={2} fontSize={18}>
                      Phone
                    </Box>
                    <Box flex={9.5} color="customGrey.light">
                      {footer?.phoneNumber}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="baseline">
                    <Box flex={2.5} pr={2} fontSize={18}>
                      Email
                    </Box>
                    <Box flex={9.5} color="customGrey.light">
                      {footer?.email}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>

            <Box
              display="flex"
              mt={2}
              mb={4}
              flexDirection="row"
              justifyContent="center"
            >
              <Box mt={0.5} width="50%">
                <CustomButton
                  buttonType="contained"
                  buttonColor="primary"
                  buttonText="Sign Out"
                  customStyle={{ width: "100%", borderRadius: 40 }}
                  clickHandler={() => {
                    unSubscribeNotification();
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Profile;
