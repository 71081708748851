import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const useCommunityPage = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();

  const [localCommunity, setLocalCommunity] = useSessionStorage(
    keys.COMMUNITY_PAGE,
    null
  );

  const fetchCommunityPage = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const communityArticle = res.data.authCommunity;
      const unAuthCommunityArticle = res.data.unAuthCommunity;
      sessionStorage.removeItem(keys.COMMUNITY_PAGE);
      setLocalCommunity({
        communityArticle,
        unAuthCommunityArticle,
      });

      if (fromCreatePage) history.goBack();
    };

    Get_Page(
      Res.apiEndPoints.pagesUrl + "community",
      {},
      getPageSuccess,
      global_data?.token
    );
  };
  useEffect(() => {
    if (localCommunity === null) {
      fetchCommunityPage();
    }
  }, []);
  return { ...localCommunity, fetchCommunityPage };
};

export default useCommunityPage;
