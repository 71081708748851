import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Button, Grid, Icon, IconButton, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

const mainStyle = makeStyles((theme) => ({
  iconClass: {
    fontSize: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(3),
    },
  },
  notificationItem: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.customGrey.main,
  },
  delIcon: {
    fontSize: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(3),
    },
  },
  delBox: {
    textAlign: "center",
    alignSelf: "center",
  },
  notificationTitle: {
    whiteSpace: "nowrap",
    fontSize: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(2),
    },
    [theme.breakpoints.down("xd")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  notificationDetail: {
    paddingTop: theme.spacing(1),
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(1.5),
      width: "90%",
    },
  },
  notificationText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  notificationImage: {
    marginTop: theme.spacing(1.25),
  },
  invoiceButton: {
    marginLeft: 20,
    textTransform: "none",
    padding: "0 8px",
    fontWeight: 400,
    fontSize: 12,
  },
}));

export default function Notification(props) {
  const styles = mainStyle();
  const [expanded, setExpanded] = React.useState(!!props.expanded);
  const dispatch = useDispatch();
  const expandFunction = () => {
    setExpanded(!expanded);
  };
  const deleteFunction = () => {
    props.notificationClickHandel(props.nid);
  };

  return (
    <Grid container id={`notification_${props.nid}`}>
      <Grid
        item
        xs={props.type === "payment" ? 12 : 11}
        container
        className={styles.notificationItem}
      >
        <Grid item xs={1}>
          <Box
            fontSize={{ xs: 14, sm: 18, md: 22 }}
            fontWeight="fontWeightBold"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={{ xs: 18, sm: 23, md: 30 }}
            height={{ xs: 18, sm: 23, md: 30 }}
            borderRadius={30}
            color="secondary.main"
            bgcolor="#fff"
            marginTop="3px"
          >
            <span>i</span>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            mt={0}
            mr={1}
            className={expanded ? null : styles.notificationText}
          >
            <Typography variant="h5" className={styles.notificationTitle}>
              {props.notificationTitle}
            </Typography>
            <Typography variant="body2" className={styles.notificationDetail}>
              {expanded ? props.notificationDetail : null}
            </Typography>
            {props.type == "payment" && expanded && (
              <Typography variant="h5" className={styles.notificationDetail}>
                {props.paymentAmount} &#163;
                <Button
                  variant="outlined"
                  className={styles.invoiceButton}
                  color="primary"
                >
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={props.url}
                    target="_blank"
                  >
                    Get Invoice
                  </a>
                </Button>
              </Typography>
            )}
          </Box>
          <Box mt={0.4} fontSize={10} className={styles.notificationTime}>
            {props.notificationTime}
          </Box>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <IconButton onClick={expandFunction} style={{ padding: 0 }}>
            <Icon className={styles.iconClass}>
              {!expanded ? "expand_more_icon" : "expand_less"}
            </Icon>
          </IconButton>
          {expanded && props.imgUrl ? (
            <img
              src={props.imgUrl}
              className={styles.notificationImage}
              width="100%"
              alt=""
            />
          ) : null}
        </Grid>
      </Grid>
      {props.type !== "payment" ? (
        <Grid item xs={1} className={styles.delBox}>
          <IconButton style={{ padding: 5 }} onClick={deleteFunction}>
            <Icon className={styles.delIcon}>delete_outline</Icon>
          </IconButton>
        </Grid>
      ) : null}
    </Grid>
  );
}
