import {
  Avatar,
  Badge,
  Box,
  Button,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  withStyles,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { GLOBAL_DATA } from "../../store/actionTypes";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: theme.spacing(0.5),
    backgroundColor: theme.palette.red.main,
  },
}))(Badge);

const useStyle = makeStyles((theme) => ({
  authItem: {
    marginRight: theme.spacing(1),
  },
  notificationBox: {
    zIndex: theme.zIndex.mobileStepper,
    position: "absolute",
    bottom: -"100%",
    right: -40,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.paper,
  },
  notificationBadge: {
    color: theme.palette.paper,
  },
  notificationText: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  notificationItem: {
    fontSize: theme.spacing(1.25),
    backgroundColor: theme.palette.customGrey.main,
    paddingRight: 10,
    marginBottom: 6,
    whiteSpace: "nowrap",
    width: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      color: theme.palette.lightText,
      fontSize: theme.spacing(1),
    },
  },
  notificationAvatar: {
    fontSize: 15,
    height: 20,
    width: 20,
    borderRadius: 30,
    fontWeight: "fontWeightBold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
    color: theme.palette.paper,
    backgroundColor: theme.palette.secondary.main,
  },
  emptyNotificationAvatar: {
    border: "2px solid",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    backgroundColor: "#ffF",
  },
  cardrootinnerButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(5),
    color: theme.palette.paper,
    padding: 1,
    fontSize: theme.spacing(1.5),
    textTransform: "unset",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "@media (max-width:800px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
const NotificationNav = () => {
  const styles = useStyle();
  const [dropdownState, setDropdownState] = useState(false);
  const [notificationState, setNotificationState] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showInNav, setShowInNav] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.pathname?.includes("notifications")) {
      setShowInNav(false);
    } else if (
      setShowInNav === false &&
      location?.pathname?.includes("notifications") === false
    ) {
      setShowInNav(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (global_data.notifications) {
      setNotifications(
        global_data.notifications?.filter(
          (item) =>
            !item?.watched &&
            global_data?.clickedNotifications?.includes(
              item?.notification?.notificationId
            ) === false
        )
      );
    }
  }, [global_data.notifications]);

  const handleClickAway = () => {
    if (window.innerWidth > 960) {
      setDropdownState(false);
      // console.log("handleClickAway!", !dropdownState);
    }
  };
  const handleDropNotificationClose = () => {
    setNotificationState(false);
  };

  const handelDropNotification = (event) => {
    setNotificationState(!notificationState);
    event.stopPropagation();
  };

  const onMenuItemClick = (notificationId) => {
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        clickedNotifications: [
          ...global_data?.clickedNotifications,
          notificationId,
        ],
      },
    });
    history.push(`/notifications/${notificationId}`);
  };

  const handleNotificationClickAway = (event) => {
    setNotificationState(false);
    event.stopPropagation();
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        newNotificationsCount: 0,
      },
    });
  };
  if (!showInNav) return null;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative">
        <RouterLink to="/notifications">
          <IconButton
            onMouseOver={handelDropNotification}
            onMouseOut={handleNotificationClickAway}
            style={{ padding: 6 }}
            className={styles.authItem}
          >
            <StyledBadge
              badgeContent={global_data?.newNotificationsCount}
              className={styles.notificationBadge}
            >
              <Icon color="secondary">notifications</Icon>
            </StyledBadge>
          </IconButton>
        </RouterLink>
        <MenuList
          className={styles.notificationBox}
          onMouseOver={handelDropNotification}
          onMouseOut={handleNotificationClickAway}
          style={{
            display: notificationState ? "block" : "none",
          }}
        >
          <Box fontSize={10} py={1} pl={1} className={styles.notificationText}>
            <Icon color="secondary" style={{ fontSize: 22, paddingRight: 10 }}>
              notifications
            </Icon>
            Notifications
          </Box>
          {notifications?.length ? (
            notifications
              ?.reverse()
              ?.slice(0, 4)
              .map((item, i) => {
                return (
                  <MenuItem
                    className={styles.notificationItem}
                    key={i.toString()}
                    onClick={() =>
                      onMenuItemClick(item.notification?.notificationId)
                    }
                  >
                    <Avatar className={styles.notificationAvatar}>i</Avatar>
                    <Box
                      whiteSpace="nowrap"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      pl={0.5}
                      fontSize={14}
                    >
                      {item?.notification?.message}
                    </Box>
                  </MenuItem>
                );
              })
          ) : (
            <MenuItem className={styles.menuItem}>
              <Avatar
                className={`${styles.notificationAvatar} ${styles.emptyNotificationAvatar}`}
              >
                i
              </Avatar>
              No new notification
            </MenuItem>
          )}
          {notifications?.length > 4 ? (
            <RouterLink to="/notifications" style={{ textDecoration: "none" }}>
              <MenuItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleDropNotificationClose}
              >
                <Button size="small" className={styles.cardrootinnerButton}>
                  More notifications
                </Button>
              </MenuItem>
            </RouterLink>
          ) : null}
        </MenuList>
      </Box>
    </ClickAwayListener>
  );
};

export default NotificationNav;
