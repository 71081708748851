import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/header/Header";
import {
  Box,
  Container,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import useTermsOfBusiness from "../../resources/localStorage/hooks/useTermsOfBusiness";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import usePrivacyPolicy from "../../resources/localStorage/hooks/usePrivacyPolicy";
import useCookiePolicy from "../../resources/localStorage/hooks/useCookiePolicy";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function Cookie() {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);

  const { cookiePolicy } = useCookiePolicy();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Container style={{ minHeight: "80vh", paddingTop: "6vh" }}>
        <Box style={{ marginTop: 108, marginLeft: "6%", marginRight: "10%" }}>
          <Box className={styles.buttonContainer}>
            <Typography
              style={{ color: "#10265F", fontWeight: "bold", fontSize: 28 }}
            >
              {cookiePolicy?.heading}
            </Typography>
            {global_data.isEditable && (
              <IconButton
                onClick={() => {
                  history.push(
                    `/update/pageItem/cookiepolicy/${cookiePolicy.id}`
                  );
                }}
              >
                <CreateIcon />
              </IconButton>
            )}
          </Box>
          <div
            dangerouslySetInnerHTML={{
              __html: cookiePolicy?.description || "",
            }}
          ></div>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
// return (
//     <div >
//         <Header />
//         <Container style={{ minHeight: '80vh' }}>
//             <Box style={{ marginTop: 108, marginLeft: '6%', marginRight: '10%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: 'bold', fontSize: 28 }}>Privacy Policy</Typography>
//             </Box>
//             <Box style={{ marginTop: 50, marginLeft: '6%', marginBottom: 10, marginRight: '10%' }}>
//                 <Typography style={{ fontSize: 20, marginTop: 10 }}>Team Teach Ltd takes your data very seriously.
//                 </Typography>
//                 <Typography style={{ fontSize: 20, marginTop: 18 }}>We will never trade or share your data with any external companies however we do need to collect some information to enable us to provide you with a service. For instance, we will need to share your information with the post office if we need to send you anything via the post.</Typography>
//                 <Typography style={{ fontSize: 20, marginTop: 18 }}>This Privacy Policy explains in detail the types of personal data we at Team Teach may collect about you when you interact with us in various ways. It also explains how we’ll store, handle and protect that data.</Typography>
//                 <Typography style={{ fontSize: 20, marginTop: 18 }}>We want you to be fully informed about your rights, and how Team Teach Ltd uses your data, so we have included quite a lot of information here. We hope the following sections will answer any questions you have but if not, please do get in touch</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Team Teach Ltd</Typography>
//                 <Typography style={{ fontSize: 15 }}>Team Teach Ltd is a training provider based in the UK. In this document when we use the term ‘Team Teach’ we are referring to Teach Teach Ltd.</Typography>

//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>The Legal Framework</Typography>
//                 <Typography style={{ fontSize: 15, fontWeight: 'bold' }}>The law on data protection sets out a number of different reasons why a company may collect and process your personal data.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Consent - In specific situations, we can collect and process your data with your consent. (For example, when you tick a box to receive Team Teach emails and/or newsletters, or when you sign up for a course).
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Contractual obligations - In certain circumstances, we need your personal data to comply with our contractual obligations. (For example, if you order training or an item from us for home delivery, we’ll collect your address details and pass them to the trainers delivering your course so that they can find you, or to a courier to deliver your parcel).</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Legal compliance - If the law requires us to, we may need to collect and process your data. (For example, we can pass on details of people involved in fraud or other criminal activity affecting our business to law enforcement. We may also be asked to provide information in relation to civil cases and child protection investigations).</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Legitimate interest - In specific situations, we may require your data to pursue our legitimate interests in a way which might reasonably be expected as part of running our business which does not materially impact on your rights, freedom or interests.</li></Typography>

//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>When we collect your personal data
//                 </Typography>
//                 <Typography style={{ fontSize: 15, fontWeight: 'bold' }}>Data can be collected in a variety of ways.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you visit our website and log into your account to access information or buy products and services from Team Teach on the phone, or online.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you register to use our ‘Useful Resources’ area.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you make an online order and check out as a guest (in which case we just collect transaction-based data).
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you register to create an account with us.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you communicate with us and/or purchase a product or service by email, text or phone.
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you contact us by any means with queries, comments, compliments, complaints etc.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>•When you complete signing-in sheets as part of a training course.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you complete course registration documents at the beginning of a training course.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you complete an evaluation at the end of a training course.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you complete a quiz as part of your training course.
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When trainers complete their training record of what was taught on your course.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When trainers write out course certificates on successful completion of a training course.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you complete surveys or questionnaires we send you.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>When you fill in any forms in relation to our business with you. For example, if an accident happens we may collect your personal data as part of the record.
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>We may collect data from publicly-available sources (such as Land Registry and Google Maps) when you have given your consent to share information or where the information is made public as a matter of law.</li></Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>What sort of personal data we collect?</Typography>
//                 <Typography style={{ fontSize: 15, fontWeight: 'bold' }}>A variety of data may be collected.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>f you have a web account with us, your name, gender, date of birth, billing/delivery address, orders and receipts, email and telephone number may be recorded.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>For your security, we’ll also keep an encrypted record of your login password.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>New registrants/participants will receive an email along with their certificate to invite them to register to our ‘Useful Resources’ area. We will collect your name, email address, certificate number and course details which will be transferred to our database to provide access to trainer resources and to maintain your Team Teach accreditation.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>For new registrants/participants in the online training course, we may collect your name, email address, the name of the organisation you work for, teacher registration number (if applicable), your job position, and your sector.
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Details of your interactions with us. For example, we may keep notes from our conversations with you, details of any compliments, comments or complaints you make, details of purchases you have made from us and records of communications by email.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Copies of any documents you provide to prove your age or identity, where the law requires this. (For example, your passport and driver's licence). This will include details of your full name, address, date of birth and facial image. If you provide a passport, the data will also include your place of birth, gender and nationality.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Details of your visits to our website and contributions to online conversations.</li></Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Why we need to collect your personal data?</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We collect your personal data to enable us to process your orders and comply with our legal obligations. Your details may need to be passed to a third party to supply or deliver the product or service that you ordered, and we may keep your details for a reasonable period afterwards in order to fulfil any contractual obligations such as refunds, guarantees and so on. Training may be delivered on our behalf by independent contractors who are also responsible for complying with GDPR and The Data Protection Act 1518.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We will use your course, certification and personal details to confirm and maintain your Team Teach accreditation and to ensure that you are reminded when your training is due for renewal. It is also collected to provide you with access to our ‘Useful Resources’ area which contains videos, trainers’ content and useful resources.
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We need to keep records to inform future communications (for example to respond to queries, refund requests and complaints and, more commonly, to provide references, advice and support).
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We do this on the basis of our contractual obligations to you, our legal obligations and our legitimate interests in providing you with the best service.
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We also need to protect our business and your account from fraud and other illegal activities. This includes using your personal data to maintain, update and safeguard your account.
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We may also monitor your browsing activity with us to quickly identify and resolve any problems and protect the integrity of our websites. For example, by checking your password when you log-in and using automated monitoring of IP addresses to identify possible fraudulent log-ins from unexpected locations. We’ll do all of this as part of our legitimate interest.</Typography>
//             </Box>
//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>How we protect your personal data</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Data security matters to all our customers. We treat your data with the utmost care and take all appropriate steps to protect it. We secure access to all transactional areas of our websites using ‘https’ technology. Access to your personal data is password-protected, and sensitive data (such as payment card information) is secured by SSL encryption.</Typography>

//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>How long will we keep your personal data?
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Whenever we collect or process your personal data, we only keep it for as long as is necessary for the purpose for which it was collected.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>At the end of that retention period, your data will either be deleted completely or anonymised, for example by aggregation with other data, so that it can be used in a non-identifiable way for statistical analysis and business planning.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Team Teach Ltd retains business records, training summary evaluations, course registration documents and contracts for a period of 7 years after they expire. The tutor database is also retained for 7 years before inactive accounts are either deleted or rendered anonymous.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Who do we share your personal data with?
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We sometimes need to share your personal data with trusted third parties. For example, delivery couriers, consultants visiting you, and trainers all need your contact details. We provide only the information they need to perform their specific services. They may only use your data for the exact purposes we specify in our contract with them. We work closely with them to ensure that your privacy is respected and protected at all times.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>If we stop using their services, any of your data held by them will either be deleted or rendered anonymous. Examples of the kind of third parties we work with are:</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>IT companies who support our website and other business systems.
//                 </li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Operational companies such as venues and delivery couriers.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>Independent contractors delivering Team Teach training and consultancy.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>For fraud management, we may share information about fraudulent or potentially fraudulent activity in our premises or systems. This may include sharing data about individuals with law enforcement bodies.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, marginLeft: 20 }}><li>We may also be required to disclose your personal data to the police or other enforcement, regulatory or Government body, in your country of origin or elsewhere, upon a valid request to do so. These requests are assessed on a case-by-case basis and take the privacy of our customers into consideration. We may, from time to time, expand, reduce or sell Team Teach and this may involve the transfer of divisions or the whole business to new owners. If this happens, your personal data will, where relevant, be transferred to the new owner or controlling party, under the terms of this Privacy Notice.</li></Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>For further information please contact us at dataprotection@teamteach.co.uk</Typography>
//             </Box>
//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Where your personal data may be processed</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Sometimes we may need to share your personal data with third parties and suppliers outside the European Economic Area (EEA), such as Australia. If you are based outside the UK and place an order with us, we will transfer the personal data that we collect from you to Team Teach in the UK. The EEA includes all EU Member countries as well as Iceland, Liechtenstein and Norway. We may transfer personal data that we collect from you to third-party data processors in countries that are outside the EEA.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>For example, this might be required in order to fulfil your order, process your payment details or provide support services. Any transfer of your personal data will follow applicable laws and we will treat the information under the guiding principles of this Privacy Notice.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>What are your rights over your personal data?</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>You have the right to request access to the personal data we hold about you, free of charge in most cases. You are also able to correct any of your personal data on the website if it is incorrect, out of date or incomplete. For example, when you withdraw consent, or object and we have no legitimate overriding interest, or once the purpose for which we hold the data has come to an end.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>You have the right to request a copy of any information about you that Team Teach holds at any time, and have that information corrected if it is inaccurate. To ask for your information, please email dataprotection@teamteach.co.uk. If we choose not to action your request, we will explain to you the reasons for our refusal.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>If you have given us your consent to use your personal data, you have the right to change your mind at any time and withdraw that consent.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>In cases where we are processing your personal data on the basis of our legitimate interest, you can ask us to stop for reasons connected to your individual situation. We must then do so unless we believe we have a legitimate overriding reason to continue processing your personal data.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>To protect the confidentiality of your information, we will ask you to verify your identity before proceeding with any request you make under this Privacy Notice. If you have authorised a third party to submit a request on your behalf, we will ask them to prove they have your permission to act.</Typography>
//             </Box>
//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Contacting the Regulator</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>If you feel that your data has not been handled correctly, or you are unhappy with our response to any requests you have made to us regarding the use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>You can contact them by calling 0303 123 1113, or go online to www.ico.org.uk/concerns.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Information Technology</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>By using our services or providing your personal data to us, you expressly consent to the processing of your personal data by us or on our behalf. Of course, you still have the right to ask us not to process your data in certain ways, and if you do so, we will normally respect your wishes.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Many IT providers store data in servers which are located outside of the UK. (For example, iCloud, Dropbox, and Amazon Web Services). Sometimes we’ll need to transfer your personal data between countries to enable us to supply the goods or services you’ve requested. In the ordinary course of business, we may transfer your personal data from your country of residence to ourselves and to third parties located in the UK. By dealing with us, you are giving your consent to this overseas use, transfer and disclosure of your personal data outside your country of residence for our ordinary business purposes.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>This may occur because our information technology storage facilities and servers are located outside your country of residence and could include storage of your personal data on servers in the UK.</Typography>
//             </Box>
//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Privacy Policy – Addendum for Australia
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>This addendum to the Privacy Policy applies to individuals located in Australia. The terms set out in this Australian Addendum are in addition to the terms set out above in the Team Teach Privacy Policy. In the event of any conflict or inconsistency between the Team Teach Privacy Policy and the terms of this Australian Addendum, this Australian Addendum shall govern and prevail.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Definitions</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>All references to ‘personal data’ in the above Privacy Policy are to be interpreted as a reference to personal information. Personal information has the meaning set out in the Australian Privacy Act 1988 (Cth) (Privacy Act).</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>All references to Team Teach in the above Privacy Policy are read to include reference to TTAP Pty Ltd. TTAP Pty Ltd is Team Teach’s Australian company and the company which will collect your personal information.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Compliance with the Privacy Act</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Notwithstanding any other obligations in this Privacy Policy, Team Teach will comply at all times with the Privacy Act with respect to all personal information collected from individuals in Australia.
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>Team Teach is collecting your personal information for the purposes of providing you with its services. If you do not provide us with your personal information we may not be able to provide you with these services.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Collection, use and disclosure</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We may collect and use your personal information for the purposes of providing you with our services and as otherwise described in this Privacy Policy.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We do not generally require you to disclose any sensitive information (e.g. details of race, religious belief, sexual orientation or membership of a trade union) to us. If you do provide us with sensitive information for any reason, you consent to us collecting that information and using and disclosing that information for the purpose for which you disclosed it to us and as permitted by the Privacy Act and other relevant laws.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 20, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Overseas disclosure</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We may disclose your information to third parties (e.g. our related companies or third parties who provide goods and services to us). Some of these third parties are located in the United Kingdom. We will take reasonable steps to ensure that such overseas recipients do not breach the Australian Privacy Principles in the Privacy Act 1988 (Cth) in relation to such information.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 50, marginRight: '15%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>Questions, Comments or Complaints</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>If you have any questions, comments or complaints about our collection, use or disclosure of personal information, or if you believe that we have not complied with this Privacy Policy (including Australian Addendum) or the Privacy Act, please contact us at dataprotection@teamteach.com.au.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>We will take any privacy complaint seriously and any complaint will be assessed with the aim of resolving any issue in a timely and efficient manner. We request that you cooperate with us during this process and provide us with any relevant information that we may need.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18 }}>If you are not satisfied with the outcome of our assessment of your complaint, you may wish to contact the Office of the Australian Information Commissioner.</Typography>
//             </Box>
//         </Container>
//         <Footer />

//     </div>
// )
// }

// return (
//     <div >
//         <Header />
//         <Container style={{ minHeight: '80vh' }}>
//             <Box style={{ marginTop: 108, marginLeft: '6%', marginRight: '10%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: 'bold', fontSize: 28 }}>Cookie Policy</Typography>
//             </Box>
//             <Box style={{ marginTop: 50, marginLeft: '6%', marginBottom: 10, marginRight: '10%' }}>
//                 <Typography style={{ fontSize: 20, marginTop: 18 }}>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</Typography>
//                 <Typography style={{ fontSize: 20, marginTop: 18 }}>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse, register and login to our website. It also allows us to improve our site. By accepting our cookies policy using the accept cookies button on our website, you are agreeing to our use of cookies.</Typography>
//                 <Typography style={{ fontSize: 20, marginTop: 18 }}>The table below explains the cookies we use and why.</Typography>
//             </Box>

//             <Box style={{ marginTop: 50, marginLeft: '8%', marginBottom: 60, marginRight: '10%' }}>
//                 <Typography style={{ color: '#10265F', fontWeight: '400', fontSize: 22, marginBottom: 20 }}>We use cookies for:</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, fontWeight: 'bold', }}>Strictly necessary</Typography>
//                 <Typography style={{ fontSize: 15, }}>Cookies that enable the operation of the site and allow you to use the site effectively e.g. log in to your Trainers account, search for courses and sign in or access course content.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, fontWeight: 'bold', }}>Functionality cookies</Typography>
//                 <Typography style={{ fontSize: 15, }}>These are used to recognise you when you return to our website e.g. remember your login username for your account. This enables us to personalise our content for you.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, fontWeight: 'bold', }}>Analytical/performance cookies</Typography>
//                 <Typography style={{ fontSize: 15, }}>They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 18, fontWeight: 'bold', }}>Targeting cookies</Typography>
//                 <Typography style={{ fontSize: 15, }}>These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose. Most web browsers allow some control of most cookies through the browser settings.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 15 }}>To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit www.aboutcookies.org.uk or www.allaboutcookies.org.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 15 }}>We do not use cookies to gather any personal information to advertise products or services to you on other websites.
//                 </Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 15 }}>Cookies can be removed or disabled altogether. You can control the use of these cookies either by changing the settings in your web browser or by using the third parties’ own tools.</Typography>
//                 <Typography style={{ fontSize: 15, marginTop: 15 }}>If you choose to remove the cookies, you will not be able to use some of the key features of the website. This includes registering with www.teamteach.co.uk, logging into your Trainers account on www.teamteach.co.uk and searching for courses relevant by location on www.teamteach.co.uk.</Typography>

//             </Box>
//         </Container>
//         <Footer />
//     </div>
// )
// }

export default Cookie;
