import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Res } from "../../../../resources";
import { Get_Page } from "../../../../services/ApiServies";
import { getLearningsAction } from "../../../../store/actions";

const useAllearnings = () => {
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [allearnings, setAllearnings] = useState(global_data.allCourses);

  useEffect(() => {
    if (global_data.id) {
      const getItemSuccess = (response) => {
        console.log("response?.data?.length", response?.data);
        if (response?.data?.length) {
          var responseObject = response?.data;
          getLearningsAction(dispatch, responseObject);
          setAllearnings(responseObject);
        }
      };
      Get_Page(
        Res.apiEndPoints?.allAuthCourses,
        null,
        getItemSuccess,
        global_data.token
      );
    }
  }, []);

  return [allearnings, setAllearnings];
};

export default useAllearnings;
