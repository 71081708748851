import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import AuthValidationError from "../../components/AuthValidationError";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import Footer from "../../components/Footer";
import Header from "../../components/header/Header";
import { getToken } from "../../firebase";
import { Res } from "../../resources";
import { Get_API_With_Token, Post_API } from "../../services/ApiServies";
import { CustomizeToast } from "../../services/CommonLogic";
import { emailValidator, passwordValidator } from "../../services/Validators";
import setProfileAction from "../../store/actions/profileactions/setProfile.action";
import { GLOBAL_DATA } from "../../store/actionTypes";

const makeStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    minHeight: 500,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(18),
    },
  },
  loginImg: {
    maxWidth: 150,
    paddingBottom: theme.spacing(2),
  },
}));

function Login(props) {
  const styles = makeStyle();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [loaderState, setLoaderState] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErr, setValidationErr] = useState("");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    global_data.token && history.replace("/");
  }, []);

  useEffect(() => {
    console.log("location.state", location.state);
  });
  const clickHandler = () => {
    setValidationErr("");
    if (email.startsWith(" ") || password.startsWith(" ")) {
      setValidationErr("Make sure email or password does not have spaces");
      return;
    }
    if (
      emailValidator(email, setValidationErr) &&
      passwordValidator(password, setValidationErr)
    ) {
      setLoaderState(true);
      Post_API(
        Res.apiEndPoints.login,
        { userid: email.trim(), password: password.trim() },
        loginSuccess,
        false
      );
    }
  };
  const loginSuccess = (response) => {
    if (response.data.data.status) {
      setTimeout(
        () => setLoaderState(false),
        Res.variables.toastTimerDelay - 50
      );
      var decoded = jwt_decode(response.data.headers.authorization);

      getToken(decoded?.jti);

      dispatch({
        type: GLOBAL_DATA,
        payload: {
          token: response.data.headers.authorization,
          id: decoded.sub,
          email: email,
          loginState: true,
        },
      });

      console.log("GLOBAL ", decoded.jti, "  ", global_data.clientToken);

      Get_API_With_Token(
        Res.apiEndPoints.profileOwner + "/" + decoded.sub,
        undefined,
        profileSuccess,
        response.data.headers.authorization
      );
      Get_API_With_Token(
        Res.apiEndPoints.usersWTB + "/" + email,
        undefined,
        wtbSuccess,
        response.data.headers.authorization
      );

      CustomizeToast(
        // response.data.data.message,
        "Authentication Successful",
        () => {
          dispatch({
            type: GLOBAL_DATA,
            payload: {
              loginState: false,
            },
          });
          if (location.state?.toDashboard) {
            return history.replace("/");
          }
          history.goBack();
        },
        Res.variables.toastTimerDelay
      );
    } else {
      setTimeout(
        () => setLoaderState(false),
        Res.variables.toastTimerDelay - 50
      );
      setValidationErr(response.data.data.message);
    }
  };

  const profileSuccess = (response) => {
    if (response.data.success) {
      var responseObject = response.data.object;
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          profile: responseObject,
          isEditable:
            responseObject?.userType === "Author" ||
            responseObject?.userType === "Admin",
        },
      });

      setProfileAction(dispatch, responseObject);
    }
  };

  const wtbSuccess = (response) => {
    if (response.data.status && response.data.data !== null) {
      var responseObject = response.data.data;
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          wtbToken: responseObject.wtbToken,
        },
      });
    }
  };

  return (
    <div>
      <Header />
      <Container className={styles.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {validationErr ? (
            <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
              <AuthValidationError>{validationErr}</AuthValidationError>
            </Grid>
          ) : (
            <img
              src={Res.images.loginImage}
              alt={Res.images.loginImage}
              className={styles.loginImg}
            />
          )}
          <Grid item xs={12} sm={6} md={6} lg={4} style={{ width: "100%" }}>
            <Box
              pb={5}
              fontWeight="fontWeightBold"
              textAlign="center"
              fontSize="h4.fontSize"
              color="secondary.main"
            >
              Login
            </Box>
            <form id="login_form" onSubmit={(e) => e.preventDefault()}>
              <CustomInput
                inputIcon="email"
                inputId="name"
                inputLabe="Email"
                inputType="email"
                form="login_form"
                autoFocus={true}
                placeholder="example@gmail.com"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value.trim());
                }}
              />

              <CustomInput
                inputIcon="lock"
                inputId="password"
                form="login_form"
                inputLabe="Password"
                inputType="password"
                placeholder="***************"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value.trim());
                }}
                onkeyup="rtl(this);"
              />
            </form>
            <Box
              fontWeight="fontWeightBold"
              textAlign="right"
              fontSize="body2.fontSize"
            >
              <RouterLink
                to="/forgetpassword"
                style={{ textDecoration: "none", color: "#000" }}
              >
                Forgot Password?
              </RouterLink>
            </Box>
            <Box mx={3} mt={4}>
              <CustomButton
                buttonType="contained"
                buttonColor="primary"
                buttonText="Login"
                form="login_form"
                type="submit"
                customStyle={{ borderRadius: 40 }}
                clickHandler={clickHandler}
                ajaxLoader={loaderState}
                dynamicText
              />
            </Box>
            <Box
              pt={5}
              fontWeight="fontWeightMedium"
              textAlign="center"
              fontSize="body1.fontSize"
            >
              Don't have an account?
              <RouterLink to="/signup" style={{ textDecoration: "none" }}>
                <Box color="brand.main" component="span">
                  Register
                </Box>
              </RouterLink>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Login;
