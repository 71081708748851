import { DELETE_DATA, GLOBAL_DATA } from "../actionTypes";

const intialState = {
  token: "",
  id: "",
  email: "",
  wtbToken: "",
  imageChanged: 0,
  loginState: false,
  profile: {
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    profileImage: "",
    timezone: null,
    userType: "",
    vat_percent: 0,
  },
  isEditable: false,
  subscribe: {},
  subscribePodcast: {},
  subscribeResourses: {},
  sections: {},
  authSections: {
    description: "",
    durationHours: "",
    durationMinutes: "",
    hasTask: "",
    heading: "",
    image: "",
    price: "",
    sections: [],
  },
  authResources: [],
  authPodcasts: [],
  authClasses: { classes: [] },
  allCourses: [],
  allTalks: [],
  subscribedCourses: [],
  allCoursesLoaded: false,
  addSections: {},
  podcastData: {},
  notifications: [],
  clickedNotifications: [],
  newNotificationsCount: 0,
  errors: [],
};

const contentReducer = (state = intialState, action) => {
  switch (action.type) {
    case GLOBAL_DATA:
      return Object.assign({}, state, action.payload);
    case "error":
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    case DELETE_DATA:
      return intialState;

    default:
      return state;
  }
};
export default contentReducer;
