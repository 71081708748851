import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DateRangeIcon from "@material-ui/icons/DateRange";
import React from "react";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import WebinarButton from "./components/WebinarButton";
import useSubLearnings from "./hooks/useSubLearnings";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(14.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(18) },
  },
  maincontainer: {
    padding: theme.spacing(1),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },
  videoContainer: {
    width: "min-content",
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    boxShadow: theme.shadows[4],
  },
  borderclassBottom: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1.25),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(4),

    "@media (max-width:800px)": {
      width: "100%",
    },
  },
  timeBox: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(1),
  },

  media: {
    width: "100%",
    minHeight: 220,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  courseIcon: {
    color: "#75A8F4",
    fontSize: theme.spacing(2.5),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(2),
    },
  },
  cardrootinnerFont: {
    fontSize: theme.spacing(1.4),
    marginLeft: theme.spacing(1),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.11),
    },
  },

  cardrootinnerDescription: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    width: "80%",
  },
  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
    "@media (max-width:500px)": {
      fontSize: ".4rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  pageTitle: {
    paddingBottom: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0),
    color: "#0D2860",
  },
}));

function ContenthubWebinarDetail() {
  const global_data = useSelector((state) => state.commonReducer);
  const styles = makeStyle();
  const history = useHistory();
  const { courseId, subId, sectionId } = useParams();
  const dispatch = useDispatch();
  const {
    data,
    setData,
    section,
    addModule,
    viewWebinar,
    startCourse,
    completeCall,
    closeWebinarModal,
    bookWebinarModal,
  } = useSubLearnings();

  // const [section, setSection] = useState({});

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login");
    }
  }, []);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span> Webinar Started!</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}hours {minutes}mins {seconds} seconds
        </span>
      );
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ minHeight: 500, maxWidth: 1130 }}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Box className={styles.maincontainer}>
              <Typography
                variant="h4"
                component="h2"
                className={styles.pageTitle}
              >
                Webinar
              </Typography>
              <Typography
                style={{ fontWeight: 600, paddingTop: 30, paddingBottom: 10 }}
              >
                {section?.heading}
              </Typography>
              <Box pt={2} pb={2}>
                <Box className={styles.timeBox}>
                  <DateRangeIcon
                    className={styles.courseIcon}
                    style={{ marginRight: "0" }}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    className={styles.cardrootinnerFont}
                  >
                    {section?.localWebinarDate ? section?.localWebinarDate : ""}{" "}
                    {section?.localWebinarTime ? section?.localWebinarTime : ""}
                  </Typography>
                </Box>

                {/* {section?.state === 2 && (
                  <Box className={styles.timeBox}>
                    <AccessTimeIcon
                      className={styles.courseIcon}
                      style={{ marginRight: "0" }}
                    />
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="p"
                      className={styles.cardrootinnerFont}
                    >
                      Webinar starts in :
                      <b>
                        <Countdown
                          date={Date.now() + section?.timeRemaining * 60 * 1000}
                          intervalDelay={0}
                          precision={3}
                          renderer={renderer}
                        />
                      </b>
                    </Typography>
                  </Box>
                )} */}
              </Box>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"
                className={styles.cardrootinnerDescription}
                dangerouslySetInnerHTML={{
                  __html: `${section?.description || ""}`,
                }}
              />

              {section.hostName ? (
                <Typography>
                  <b> Host :</b> {section.hostName}
                </Typography>
              ) : null}
              <Box py={5}>
                <WebinarButton
                  section={section}
                  completeCall={completeCall}
                  addModule={addModule}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img className={styles.media} src={section?.image} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

export default ContenthubWebinarDetail;
