import "@brainhubeu/react-carousel/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import EventIcon from "@material-ui/icons/Event";
import ImageIcon from "@material-ui/icons/Image";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CreateInput from "../../../components/CreateInput";
import CustomButton from "../../../components/CustomButton";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import { Res } from "../../../resources";
import useBottomSections from "../../../resources/localStorage/hooks/useBottomSections";
import useCoachingPageStorage from "../../../resources/localStorage/hooks/useCoachingPageStorage";
import useCommunityPage from "../../../resources/localStorage/hooks/useCommunityPage";
import useCookiePolicy from "../../../resources/localStorage/hooks/useCookiePolicy";
import useFooter from "../../../resources/localStorage/hooks/useFooter";
import useHomePageStorage from "../../../resources/localStorage/hooks/useHomePageStorage";
import useJournalPageStorage from "../../../resources/localStorage/hooks/useJournalPageStorage";
import useMobileData from "../../../resources/localStorage/hooks/useMobileData";
import usePrivacyPolicy from "../../../resources/localStorage/hooks/usePrivacyPolicy";
import useTermsOfBusiness from "../../../resources/localStorage/hooks/useTermsOfBusiness";
import useWhoWeArePageStorage from "../../../resources/localStorage/hooks/useWhoWeArePageStorage";
import { Get_API, Get_Page, Post_API } from "../../../services/ApiServies";
import { CustomizeToastError } from "../../../services/CommonLogic";
import { getLearningsAction } from "../../../store/actions";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(8.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  maincontainer: {
    padding: theme.spacing(5),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },

  borderclassBottom: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1.25),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(4),

    "@media (max-width:800px)": {
      width: "100%",
    },
  },

  media: {
    height: 200,
    width: 250,
    objectFit: "contain",
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(1.25),
    "@media (max-width:800px)": {
      height: 170,
    },
  },

  cardrootinnerFont: {
    fontSize: theme.spacing(1.8),
    margin: theme.spacing(3),
    marginLeft: 0,
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.11),
    },
  },

  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    color: theme.palette.paper,
    marginBottom: theme.spacing(2),
    padding: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  inputBox: {
    marginBottom: theme.spacing(1),
  },

  pageTitle: {
    paddingBottom: theme.spacing(4.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0),
    color: "#0D2860",
    textTransform: "capitalize",
  },
  input: {
    display: "none",
  },
  customInputLabel: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.dark.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
  },
  filePickerBox: {
    display: "flex",
    flexDirection: "row",
  },
  fileSelector: {
    backgroundColor: theme.palette.paper,
    borderRadius: theme.spacing(0.5),
    border: "1px solid",
    borderColor: theme.palette.customGrey.main,
    paddingLeft: 10,
    fontSize: theme.spacing(2),
    paddingTop: 1,
    paddingBottom: 1,
    width: "100%",
  },
  smallInput: {
    padding: theme.spacing(1),
    border: "none",
    // borderLeft: "1px solid",
    // borderLeftColor: theme.palette.customGrey.main,
    flex: 1,
    textAlignVertical: "top",
    fontFamily: "Poppins",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: theme.palette.customGrey.main,
    },
  },
  timeBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  imageContainer: {
    border: "1px solid",
    borderColor: theme.palette.customGrey.main,
    display: "flex",
    justifyContent: "center",
    borderRadius: theme.spacing(0.4),
    marginBottom: theme.spacing(1),
  },
}));

function CreateSubContent() {
  const global_data = useSelector((state) => state.commonReducer);
  const { type, parentId, contentId } = useParams();
  const styles = makeStyle();
  const history = useHistory();
  const location = useLocation();
  const [operationType, setOperationType] = useState(
    location.pathname.split("/")[1]
  );
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [fileType, setFileType] = useState("video");
  const [file, setFile] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [price, setPrice] = useState(0);
  const [scormId, setScormId] = useState("");
  const [totalModules, setTotalModules] = useState("");
  const [image, setImage] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [imagePreview, setImagePreview] = useState("/images/contentImg.jpg");
  const [loaderState, setLoaderState] = useState(false);
  const [isScorm, setIsScorm] = useState(false);
  const [scormData, setScormData] = useState([]);
  const [contentLink, setContentLink] = useState("");
  const [webinarDate, setWebinarDate] = useState("");
  const [webinarDateLabel, setWebinarDateLabel] = useState("");
  const [webinarTime, setWebinarTime] = useState("");
  const [webinarTimeLabel, setWebinarTimeLabel] = useState("");
  const [designation, setDesignation] = useState("");
  const [name, setName] = useState("");
  const [fileLabel, setFileLabel] = useState("");
  const [numSlots, setNumSlots] = useState(0);
  const [hostName, setHostName] = useState("");
  const [expiresOn, setExpiresOn] = useState("date");
  const [expiresOnLabel, setExpiresOnLabel] = useState();
  const [footerValue, setFooterValue] = useState("");
  const { footer, fetchFooter } = useFooter();
  const {
    localHomeArticle,
    localHomeImageArticle,
    localBooking,
    localHomeFeature,
    localAuthArticle,
    localAuthHomeAricle,
    fetchHomePage,
  } = useHomePageStorage();

  const {
    localWhoWeAreArticle,
    localWhoWeAreImageArticle,
    localTeam,
    fetchWhowearePage,
  } = useWhoWeArePageStorage();

  const {
    localCoachingArticle,
    localCoachingImageArticle,
    localCoachingSteps,
    fetchCoachingPage,
  } = useCoachingPageStorage();

  const { localJournalImageArticle, localJournalFeatures2, fetchJournalPage } =
    useJournalPageStorage();

  const { communityArticle, unAuthCommunityArticle, fetchCommunityPage } =
    useCommunityPage();

  const { termsOfBusiness, fetchTermsOfBusiness } = useTermsOfBusiness();
  const { privacyPolicy, fetchPrivacyPolicy } = usePrivacyPolicy();
  const { cookiePolicy, fetchCookiePolicy } = useCookiePolicy();
  const {
    journal: journalBottonSection,
    contenthub: contenthubBottonSection,
    whoweare: whoweareBottomSection,
    community: communityBottomSection,
    home: homeBottomSection,
    fetchBottomSections,
  } = useBottomSections();

  const { mobileSupport, mobileHelp, fetchSupport, fetchHelp } =
    useMobileData();
  const dateRef = useRef();
  const timeRef = useRef();
  function onSelectFile(e) {
    setFile(e.target.files[0]);
    setFileLabel(e.target.files[0].name);
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login");
    }
  }, []);

  React.useEffect(() => {
    console.log(contentId, type);
    var username = "K05F7L12FL";
    var password = "KHseNsVMbbx3bbkhC1IU6zI3EMqvFfEoM90WpqKt";
    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token).toString("base64");
    const session_url = Res.apiEndPoints.scormCall;
    var config = {
      method: "get",
      url: session_url,
      headers: { Authorization: "Basic " + encodedToken },
    };
    axios(config)
      .then(function (response) {
        setScormData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    const getDataSuccess = (response) => {
      const responseObject = response.data;
      if (
        operationType === "update" &&
        (type === "course" || type === "talks")
      ) {
        setTitle(responseObject.heading || "");
        setPrice(responseObject.price || 0);
        setHours(responseObject.durationHours || 0);
        setMinutes(responseObject.durationMinutes || 0);
        setDescription(responseObject.description || "");
        if (responseObject.image && responseObject.image !== "null") {
          setImage(responseObject.image);
          setImagePreview(responseObject.image || "/images/contentImg.jpg");
        }
        if (responseObject.expiresOn) {
          let dateString = responseObject.expiresOn;
          let dateLabel = dateString.slice(0, dateString.indexOf("T"));
          setExpiresOnLabel(dateLabel);
        }
        if (responseObject.scormId) {
          setIsScorm(true);
          setScormId(responseObject.scormId);
          setTotalModules(+responseObject.totalModules || 0);
        }
      } else if (operationType === "update") {
        let item;
        if (type === "resource") {
          item = responseObject?.resources?.find(
            (item) => item.id === contentId
          );
        } else if (type === "podcast") {
          item = responseObject?.podcasts?.find((item) => item.id == contentId);
        } else if (type == "class") {
          item = responseObject?.find((item) => item.id == contentId);
          setPrice(item?.price || 0);
          setNumSlots(item?.numSlots);
        } else {
          item = responseObject?.sections?.find((item) => item.id == contentId);
        }
        setTitle(item?.heading || "");
        setHours(item?.durationHours || 0);
        setMinutes(item?.durationMinutes || 0);
        setDescription(item?.description || "");
        setImage(item?.image);
        setFileType(item?.fileType);
        setFile(item?.file);
        setImagePreview(item?.image || "/images/contentImg.jpg");
        if (item?.webinarDate) {
          setNumSlots(item?.numSlots);
          setWebinarDate(item?.webinarDate);
          let dateString = item?.webinarDate;
          let dateLabel = dateString.slice(0, dateString.indexOf("T"));
          setWebinarDateLabel(dateLabel);
          let date = new Date(dateString)?.toTimeString() || "";
          let time = date?.slice(0, date.lastIndexOf(":"));
          setWebinarTimeLabel(time);
        }
        setContentLink(item?.webinarLink || item?.linkUrl);
        if (item?.hostName && item?.hostName !== "undefined") {
          setHostName(item?.hostName);
        }
      }
    };
    if (operationType === "update" && type === "appPolicies") {
      setEmail(mobileSupport?.email || "");
      setPhoneNumber(mobileSupport?.phoneNumber);
    } else if (operationType == "update" && type == "pageItem") {
      if (parentId === "mylearning") {
        setHeading(localAuthHomeAricle?.title || "");
        setDescription(localAuthHomeAricle?.description || "");
        setTitle(localAuthHomeAricle?.heading || "");
        setImage(localAuthHomeAricle?.image);
        setImagePreview(localAuthHomeAricle?.image || "/images/contentImg.jpg");
      }
      if (parentId === "homeimagearticle") {
        setHeading(localHomeImageArticle?.title || "");
        setDescription(localHomeImageArticle?.description || "");
        setImage(localHomeImageArticle?.image);
        setImagePreview(
          localHomeImageArticle?.image || "/images/contentImg.jpg"
        );
      }
      if (parentId === "homearticle") {
        setHeading(localHomeArticle?.title || "");
        setDescription(localHomeArticle?.description || "");
      }
      if (parentId === "homeAuthArticle") {
        setHeading(localAuthArticle?.title || "");
        setDescription(localAuthArticle?.description || "");
      }
      if (parentId === "homefeature") {
        let feature = localHomeFeature.find(
          (feature) => feature.id === contentId
        );
        setHeading(feature?.title || "");
        setDescription(feature?.description || "");
        setImage(feature?.image);
        setImagePreview(feature?.image || "/images/contentImg.jpg");
      }
      if (parentId === "whowearearticle") {
        setHeading(localWhoWeAreArticle.title | "");
        setDescription(localWhoWeAreArticle.description || "");
      }
      if (parentId === "whoweareimagearticle") {
        setHeading(localWhoWeAreImageArticle.title || "");
        setDescription(localWhoWeAreImageArticle.description || "");
        setImage(localWhoWeAreImageArticle.image || "");
        setImagePreview(
          localWhoWeAreImageArticle.image || "/images/contentImg.jpg"
        );
      }
      if (parentId === "teammember") {
        let member = localTeam.members.find(
          (member) => member.id === contentId
        );
        setName(member.name || "");
        setDescription(member.description || "");
        setDesignation(member.designation || "");
        setImage(member.image);
        setImagePreview(member.image);
      }
      if (parentId === "coachingimagearticle") {
        setTitle(localCoachingImageArticle?.heading || "");
        setHeading(localCoachingImageArticle?.title || "");
        setDescription(localCoachingImageArticle?.description || "");
        setImage(localCoachingImageArticle?.image);
        setImagePreview(
          localCoachingImageArticle?.image || "/images/contentImg.jpg"
        );
      }
      if (parentId === "coachingarticle") {
        setTitle(localCoachingArticle?.heading || "");
        setHeading(localCoachingArticle?.title || "");
        setDescription(localCoachingArticle?.description || "");
      }
      if (parentId === "step") {
        let step = localCoachingSteps?.find(
          (stepItem) => stepItem.id === contentId
        );
        setHeading(step?.title || "");
        setDescription(step?.description || "");
      }
      if (parentId === "journalimagearticle") {
        setTitle(localJournalImageArticle.heading || "");
        setHeading(localJournalImageArticle.title || "");
        setDescription(localJournalImageArticle.description || "");
        setImage(localJournalImageArticle.image);
        setImagePreview(
          localJournalImageArticle.image || "/images/contentImg.jpg"
        );
      }
      if (parentId === "journalfeature") {
        let feature = localJournalFeatures2.find(
          (feature) => feature.id === contentId
        );
        setHeading(feature?.title || "");
        setDescription(feature?.description || "");
        setImage(feature?.image);
        setImagePreview(feature?.image || "/images/contentImg.jpg");
      }
      if (parentId === "playStoreLink") {
        setFooterValue(localJournalImageArticle?.playStoreLink || "");
      }
      if (parentId === "appleStoreLink") {
        setFooterValue(localJournalImageArticle?.appleStoreLink || "");
      }
      if (parentId === "communityArticle") {
        setHeading(communityArticle?.title || "");
        setDescription(communityArticle?.description || "");
      }
      if (parentId === "unAuthCommunityArticle") {
        setHeading(unAuthCommunityArticle?.title || "");
        setDescription(unAuthCommunityArticle?.description || "");
      }
      if (parentId === "termsofbusiness") {
        setTitle(termsOfBusiness.heading || "");
        setDescription(termsOfBusiness.description || "");
      }
      if (parentId === "privacypolicy") {
        setTitle(privacyPolicy.heading || "");
        setDescription(privacyPolicy.description || "");
      }
      if (parentId === "cookiepolicy") {
        setHeading(cookiePolicy.heading || "");
        setDescription(cookiePolicy.description || "");
      }
      if (parentId?.startsWith("bottomSection")) {
        if (parentId?.includes("home")) {
          setHeading(homeBottomSection?.title || "");
          setDescription(homeBottomSection?.description || "");
        }
        if (parentId?.includes("journal")) {
          setHeading(journalBottonSection?.title || "");
          setDescription(journalBottonSection?.description || "");
        }

        if (parentId?.includes("contenthub")) {
          setHeading(contenthubBottonSection?.title || "");
          setDescription(contenthubBottonSection?.description || "");
        }
        if (parentId?.includes("whoweare")) {
          setHeading(whoweareBottomSection?.title || "");
          setDescription(whoweareBottomSection?.description || "");
        }
        if (parentId?.includes("community")) {
          setHeading(communityBottomSection?.title || "");
          setDescription(communityBottomSection?.description || "");
        }
      }
      if (parentId === "terms_conditions") {
        setHeading(mobileSupport?.terms_conditions?.title || "");
        setDescription(mobileSupport?.terms_conditions?.description || "");
      }
      if (parentId === "privacy_policies") {
        setHeading(mobileSupport?.privacy_policies?.title || "");
        setDescription(mobileSupport?.privacy_policies?.description || "");
      }
      if (parentId === "help") {
        setHeading(mobileHelp?.title || "");
        setDescription(mobileHelp?.description || "");
      }
    }

    if (
      operationType == "update" &&
      type === "siteFooter" &&
      parentId.includes("social") == false
    ) {
      if (footer) setFooterValue(footer[parentId] || "");
    }
    if (operationType == "update" && parentId?.includes("social")) {
      let socialIndex = +parentId[parentId.length - 1];
      if (footer && Array.isArray(footer?.socialLinks) && socialIndex !== NaN) {
        setFooterValue(footer?.socialLinks[socialIndex]?.url || "");
      }
    }

    if (
      operationType === "update" &&
      (type === "module" || type === "webinar" || type === "talk")
    ) {
      Get_Page(
        Res.apiEndPoints.course + parentId,
        { subscriptionId: null },
        getDataSuccess,
        global_data?.token
      );
    }
    if (operationType === "update" && (type === "talks" || type === "course")) {
      Get_Page(
        Res.apiEndPoints.course + contentId,
        { subscriptionId: null },
        getDataSuccess,
        global_data?.token
      );
    }
    if (
      operationType == "update" &&
      (type == "class" || type == "podcast" || type == "resource")
    ) {
      if (type == "class") {
        Get_API(
          Res.apiEndPoints.classes,
          {},
          getDataSuccess,
          global_data?.token
        );
      }
      if (type == "resource") {
        Get_API(
          Res.apiEndPoints.resources,
          {},
          getDataSuccess,
          global_data?.token
        );
      }
      if (type == "podcast") {
        Get_API(
          Res.apiEndPoints.podcasts,
          {},
          getDataSuccess,
          global_data?.token
        );
      }
    }
  }, []);

  function selectFileType(e) {
    console.log("e.target.value", e.target.value);

    setFileType(e.target.value);
  }
  function onSelectImage(e) {
    const reader = new FileReader();
    setImage(e.target.files[0]);
    reader.onload = () => {
      if (reader?.readyState == 2) {
        setImagePreview(reader?.result);
      }
    };
    reader?.readAsDataURL(e.target.files[0]);
  }

  const saveCourseSuccess = React.useCallback((response) => {
    const getItemSuccess = async (response) => {
      if (type === "pageItem") {
        if (
          parentId === "mylearning" ||
          parentId === "homeimagearticle" ||
          parentId === "homearticle" ||
          parentId === "homefeature" ||
          parentId === "homeAuthArticle"
        ) {
          await fetchHomePage(true);
        }
        if (
          parentId === "whoweareimagearticle" ||
          parentId === "whowearearticle" ||
          parentId === "teammember"
        ) {
          await fetchWhowearePage(true);
        }
        if (
          parentId === "coachingimagearticle" ||
          parentId === "coachingarticle" ||
          parentId === "step"
        ) {
          await fetchCoachingPage(true);
        }
        if (
          parentId === "journalimagearticle" ||
          parentId === "journalfeature"
        ) {
          await fetchJournalPage(true);
        }
        if (
          parentId === "communityArticle" ||
          parentId === "unAuthCommunityArticle"
        ) {
          await fetchCommunityPage(true);
        }
        if (parentId === "termsofbusiness") {
          await fetchTermsOfBusiness(true);
        }
        if (parentId === "privacypolicy") {
          await fetchPrivacyPolicy(true);
        }
        if (parentId.startsWith("bottomSection")) {
          await fetchBottomSections(true);
        }
        if (parentId === "cookiepolicy") {
          await fetchCookiePolicy(true);
        }
        if (
          parentId === "terms_conditions" ||
          parentId === "privacy_policies"
        ) {
          await fetchSupport(true);
        }
        if (parentId === "help") {
          await fetchHelp(true);
        }
        return;
      }
      if (response?.data.length) {
        var responseObject = response?.data;

        getLearningsAction(dispatch, responseObject);

        history.goBack();
      }
    };
    Get_API(Res.apiEndPoints?.allAuthCourses, null, getItemSuccess, true);

    if (response.data.success) {
      setTimeout(
        () => setLoaderState(false),
        Res.variables.toastTimerDelay - 50
      );
    }
  }, []);

  const saveSingleValue = () => {
    setLoaderState(true);
    const onSuccess = async () => {
      if (type === "appPolicies") {
        await fetchSupport(true);
      } else if (
        parentId === "playStoreLink" ||
        parentId === "appleStoreLink"
      ) {
        await fetchJournalPage(true);
      } else await fetchFooter(true);
      setLoaderState(false);
    };
    if (type === "appPolicies") {
      Post_API(
        Res.apiEndPoints.createCourse,
        {
          id: contentId,
          type: type,
          email: email,
          phoneNumber: phoneNumber,
        },
        onSuccess,
        true,
        true
      );
    } else if (parentId?.includes("social")) {
      Post_API(
        Res.apiEndPoints.createCourse,
        {
          id: contentId,
          type: type,
          url: footerValue,
        },
        onSuccess,
        true,
        true
      );
    } else
      Post_API(
        Res.apiEndPoints.createCourse,
        {
          id: contentId,
          type: type,
          [parentId]: footerValue,
        },
        onSuccess,
        true,
        true
      );
  };

  const saveHandler = () => {
    if (type === "class" || type === "webinar") {
      if (!webinarDate) {
        CustomizeToastError(
          `The ${type} date cannot be empty`,
          () => {},
          Res.variables.toastTimerDelay
        );
        return;
      }
    }
    if (type === "course") {
      if (!title) {
        CustomizeToastError(
          `Heading cannot be empty`,
          () => {},
          Res.variables.toastTimerDelay
        );
        return;
      }
      if (!image) {
        CustomizeToastError(
          `Please select image`,
          () => {},
          Res.variables.toastTimerDelay
        );
        return;
      }
    }
    setLoaderState(true);
    const id = {};
    if (scormId || isScorm) {
      id.totalModules = totalModules;
    }
    if (type !== "pageItem") {
      id.contentId = type === "course" || type === "talks" ? "" : parentId;
    }
    if (operationType === "update") {
      id.id = contentId;
    }
    console.log({
      ...id,
      type: type,
      durationHours: hours,
      durationMinutes: minutes,
      description: description,
      title: heading,
      heading: title,
      name: name,
      designation: designation,
      file: type === "module" || type === "resource" ? file : "",
      fileType:
        type === "module" || type === "resource"
          ? fileType === "contentLink"
            ? "video"
            : fileType
          : "",
      contentId: type !== "course" && type !== "talks" ? parentId : "",
      price: type === "course" ? price : "",
      image: image,
      scormId: type === "course" ? scormId : "",
      webinarDate: type === "webinar" || type === "class" ? webinarDate : "",
      webinarLink: type === "webinar" || type === "class" ? contentLink : "",
      numSlots: type === "webinar" || type === "class" ? numSlots : "",
      hostName: type === "webinar" || type === "class" ? hostName : "",
      linkUrl:
        type === "podcast" || type === "module" || type === "talk"
          ? contentLink
          : "",
      expiresOn: expiresOn,
    });

    Post_API(
      Res.apiEndPoints.createCourse,
      {
        ...id,
        type: type,
        durationHours: hours || 0,
        durationMinutes: minutes,
        description: description,
        title: heading,
        heading: title,
        name: name,
        designation: designation,
        file: type === "module" || type === "resource" ? file : "",
        fileType:
          type === "module" || type === "resource"
            ? fileType === "contentLink"
              ? "video"
              : fileType
            : "",
        contentId: type !== "course" ? parentId : "",
        price: type === "course" || type === "class" ? price : "",
        image: image,
        scormId: type === "course" ? scormId : "",
        webinarDate: type === "webinar" || type === "class" ? webinarDate : "",
        webinarLink: type === "webinar" || type === "class" ? contentLink : "",
        numSlots: type === "webinar" || type === "class" ? numSlots : "",
        hostName:
          type === "webinar" || type === "class" ? hostName?.trim() : "",
        linkUrl:
          type === "podcast" || type === "module" || type === "talk"
            ? contentLink
            : "",
        expiresOn: expiresOn,
      },
      saveCourseSuccess,
      true,
      true
    );
  };
  const onScormCheckChange = (e) => {
    setIsScorm(e.target.checked);
  };
  if (
    parentId === "terms_conditions" ||
    parentId === "privacy_policies" ||
    parentId === "help"
  ) {
    return (
      <div className={styles.pageContainer}>
        <Header />
        <Container style={{ maxWidth: 1130, marginTop: "10vh" }}>
          <Box className={styles.maincontainer}>
            <Box className={styles.inputBox}>
              <CreateInput
                inputLabe="Heading"
                inputType="text"
                placeholder="Type a Heading"
                value={heading}
                onChange={(event) => {
                  setHeading(event.target.value);
                }}
              />
            </Box>
            <Box className={styles.inputBox}>
              <Box pb={0.5} fontWeight="fontWeightMedium" fontSize={16}>
                Description
              </Box>
              <Editor
                value={description}
                init={{
                  selector: "textarea",
                  plugins: "link image code| lists",
                  toolbar:
                    "styleselect| numlist bullist| fontselect |underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat | lineheight| bold italic |fontsizeselect|  searchreplace undo redo | link image | bullist numlist outdent indent",
                  font_formats: `'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats'`,
                  fontsize_formats:
                    "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                  min_height: 300,
                  lineheight_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
                  placeholder: "Type here...",
                }}
                onEditorChange={(newValue, editor) => {
                  console.log("HI", newValue, description);
                  setDescription(newValue);
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Box mx={3} width="40%" mt={4}>
                <CustomButton
                  buttonType="outlined"
                  buttonColor="primary"
                  buttonText="Cancel"
                  customStyle={{ borderRadius: 40 }}
                  clickHandler={() => {
                    history.goBack();
                  }}
                  dynamicText
                />
              </Box>
              <Box mx={3} width="40%" mt={4}>
                <CustomButton
                  buttonType="contained"
                  buttonColor="primary"
                  buttonText="Save"
                  customStyle={{ borderRadius: 40 }}
                  clickHandler={saveHandler}
                  ajaxLoader={loaderState}
                  dynamicText
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    );
  }

  if (
    type === "siteFooter" ||
    parentId?.includes("social") ||
    parentId === "playStoreLink" ||
    parentId === "appleStoreLink" ||
    type === "appPolicies"
  ) {
    return (
      <div className={styles.pageContainer}>
        <Header />
        <Container style={{ maxWidth: 1130, marginTop: "10vh" }}>
          <Box className={styles.maincontainer}>
            <Box className={styles.inputBox}>
              {type === "appPolicies" ? (
                <>
                  <CreateInput
                    inputLabe="Email"
                    inputType="text"
                    placeholder="Type email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <CreateInput
                    inputLabe="Phone Number"
                    inputType="text"
                    placeholder="Type Phone Number"
                    value={phoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value);
                    }}
                  />
                </>
              ) : (
                <CreateInput
                  inputLabe="Put Value"
                  inputType="text"
                  placeholder="Type a title"
                  value={footerValue}
                  onChange={(event) => {
                    setFooterValue(event.target.value);
                  }}
                />
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Box mx={3} width="40%" mt={4}>
                <CustomButton
                  buttonType="outlined"
                  buttonColor="primary"
                  buttonText="Cancel"
                  customStyle={{ borderRadius: 40 }}
                  clickHandler={() => {
                    history.goBack();
                  }}
                  dynamicText
                />
              </Box>
              <Box mx={3} width="40%" mt={4}>
                <CustomButton
                  buttonType="contained"
                  buttonColor="primary"
                  buttonText="Save"
                  customStyle={{ borderRadius: 40 }}
                  clickHandler={saveSingleValue}
                  ajaxLoader={loaderState}
                  dynamicText
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    );
  }
  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ maxWidth: 1130 }}>
        <Box className={styles.maincontainer}>
          <Typography variant="h4" component="h2" className={styles.pageTitle}>
            {operationType} {type === "pageItem" ? "page item" : type}
          </Typography>

          {parentId === "termsofbusiness" ||
          parentId === "privacypolicy" ||
          parentId === "cookiepolicy" ? (
            <>
              <Box className={styles.inputBox}>
                <CreateInput
                  inputLabe="Heading"
                  inputType="text"
                  placeholder="Type a title"
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
              </Box>
              <Box className={styles.inputBox}>
                <Box pb={0.5} fontWeight="fontWeightMedium" fontSize={16}>
                  Description
                </Box>
                <Editor
                  value={description}
                  init={{
                    selector: "textarea",
                    plugins: "link image code| lists",
                    toolbar:
                      "styleselect| numlist bullist| fontselect |underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat | lineheight| bold italic |fontsizeselect|  searchreplace undo redo | link image | bullist numlist outdent indent",
                    font_formats: `'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats'`,
                    fontsize_formats:
                      "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                    min_height: 300,
                    lineheight_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
                    placeholder: "Type here...",
                  }}
                  onEditorChange={(newValue, editor) => {
                    console.log("HI", newValue, description);
                    setDescription(newValue);
                  }}
                />
              </Box>

              <Box display="flex" justifyContent="center">
                <Box mx={3} width="40%" mt={4}>
                  <CustomButton
                    buttonType="outlined"
                    buttonColor="primary"
                    buttonText="Cancel"
                    customStyle={{ borderRadius: 40 }}
                    clickHandler={() => {
                      history.goBack();
                    }}
                    dynamicText
                  />
                </Box>
                <Box mx={3} width="40%" mt={4}>
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    buttonText="Save"
                    customStyle={{ borderRadius: 40 }}
                    clickHandler={saveHandler}
                    ajaxLoader={loaderState}
                    dynamicText
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              {type === "pageItem" && parentId === "teammember" ? (
                <>
                  <CreateInput
                    inputLabe="Name"
                    inputType="text"
                    placeholder="Type Name"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value.trim());
                    }}
                  />
                  <CreateInput
                    inputLabe="Designation"
                    inputType="text"
                    placeholder="Type designation"
                    value={designation}
                    onChange={(event) => {
                      setDesignation(event.target.value.trim());
                    }}
                  />
                </>
              ) : (
                type === "pageItem" &&
                parentId !== "mylearning" && (
                  <Box className={styles.inputBox}>
                    <CreateInput
                      inputLabe="Title"
                      inputType="text"
                      placeholder="Type title"
                      value={heading}
                      onChange={(event) => {
                        setHeading(event.target.value.trim());
                      }}
                    />
                  </Box>
                )
              )}
              {parentId !== "homeimagearticle" &&
                parentId !== "whowearearticle" &&
                parentId !== "teammember" &&
                parentId !== "step" &&
                parentId !== "whoweareimagearticle" && (
                  <Box className={styles.inputBox}>
                    <CreateInput
                      inputLabe={
                        parentId === "coachingarticle"
                          ? "Booking Title"
                          : "Heading"
                      }
                      inputType="text"
                      placeholder="Type a title"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </Box>
                )}
              <Box className={styles.inputBox}>
                <Box pb={0.5} fontWeight="fontWeightMedium" fontSize={16}>
                  Description
                </Box>
                <Editor
                  value={description}
                  init={{
                    selector: "textarea",
                    plugins: "link image code| lists",
                    toolbar:
                      "styleselect| numlist bullist| fontselect |underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat | lineheight| bold italic |fontsizeselect|  searchreplace undo redo | link image | bullist numlist outdent indent",
                    font_formats: `'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats'`,
                    fontsize_formats:
                      "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                    min_height: 300,
                    lineheight_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
                    placeholder: "Type here...",
                  }}
                  onEditorChange={(newValue, editor) => {
                    console.log("HI", newValue, description);
                    setDescription(newValue);
                  }}
                />
              </Box>
              {parentId !== "coachingarticle" && parentId !== "step" && (
                <Box>
                  <Box pb={0.5} className={styles.customInputLabel}>
                    Image
                  </Box>
                  <Box className={styles.imageContainer}>
                    <input
                      accept="image/*"
                      className={styles.input}
                      id="imagePicker"
                      multiple
                      type="file"
                      onChange={onSelectImage}
                    />
                    <img className={styles.media} src={imagePreview} title="" />
                  </Box>
                  <Box mb={2}>
                    <label htmlFor="imagePicker">
                      <Button
                        style={{ fontSize: 10, marginRight: 10 }}
                        variant="outlined"
                        color="primary"
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                    <Button
                      style={{ fontSize: 10 }}
                      variant="outlined"
                      color="primary"
                      component="span"
                      onClick={() => {
                        setImage("");
                        setImagePreview("");
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              )}

              {(type === "module" || type === "resource") && (
                <Box mb={2} className={styles.filePickerBox}>
                  <Box flex={1}>
                    <Box pb={0.5} fontWeight="fontWeightMedium" fontSize={16}>
                      File Type
                    </Box>
                    <Select
                      value={fileType}
                      onChange={selectFileType}
                      className={styles.fileSelector}
                    >
                      <MenuItem value="audio">Audio</MenuItem>
                      <MenuItem value="video">Video File</MenuItem>
                      <MenuItem value="contentLink">Youtube Link</MenuItem>
                      <MenuItem value="pdf">Pdf</MenuItem>
                      <MenuItem value="image">Image</MenuItem>
                    </Select>
                  </Box>
                  <Box flex={1} pl={1}>
                    {fileType === "contentLink" ? (
                      <CreateInput
                        inputLabe="Link"
                        inputType="text"
                        placeholder="Type a link "
                        className={styles.smallInput}
                        value={contentLink}
                        onChange={(event) => {
                          setContentLink(event.target.value.trim());
                        }}
                      />
                    ) : (
                      <>
                        <Box
                          pb={0.5}
                          fontWeight="fontWeightMedium"
                          fontSize={16}
                        >
                          {fileLabel ? (
                            <>
                              <>{fileLabel} </>
                              {fileType === "video" ? (
                                <PlayArrowIcon
                                  color="primary"
                                  style={{ fontSize: 16 }}
                                />
                              ) : null}
                              {fileType === "audio" ? (
                                <AudiotrackIcon
                                  color="primary"
                                  style={{ fontSize: 16 }}
                                />
                              ) : null}
                              {fileType === "pdf" ? (
                                <InsertDriveFileIcon
                                  color="primary"
                                  style={{ fontSize: 16 }}
                                />
                              ) : null}
                              {fileType === "image" ? (
                                <ImageIcon
                                  color="primary"
                                  style={{ fontSize: 16 }}
                                />
                              ) : null}
                            </>
                          ) : (
                            "File (Audio,video,pdf)"
                          )}
                        </Box>
                        <input
                          accept="file_extension,audio/*,video/*,image/*,.pdf*"
                          className={styles.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={onSelectFile}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            style={{ width: "100%", fontSize: 16, padding: 3 }}
                            variant="outlined"
                            color="primary"
                            component="span"
                          >
                            Upload File
                          </Button>
                        </label>
                      </>
                    )}
                  </Box>
                </Box>
              )}
              {(type === "webinar" ||
                type === "podcast" ||
                type == "class") && (
                <>
                  <Box className={styles.inputBox}>
                    <CreateInput
                      inputLabe="Link"
                      inputType="text"
                      placeholder="Type a link "
                      value={contentLink}
                      onChange={(event) => {
                        setContentLink(event.target.value.trim());
                      }}
                    />
                  </Box>
                  {console.log(type)}
                  {(type == "webinar" || type == "class") && (
                    <Grid container className={styles.timeBox}>
                      <Grid
                        ref={dateRef}
                        item
                        xs={12}
                        style={{ paddingRight: 5, marginBottom: 10 }}
                      >
                        <Box pb={0.5} className={styles.customInputLabel}>
                          {`${
                            type === "webinar" ? "Webinar" : "Class"
                          } Date & Time ${
                            operationType === "update"
                              ? "(cannot be updated)"
                              : ""
                          }`}
                        </Box>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            className={styles.inputBox}
                            inputVariant="outlined"
                            value={
                              webinarDate ? new Date(webinarDate) : new Date()
                            }
                            onChange={(dt) => {
                              setWebinarDate(new Date(dt).toISOString());
                            }}
                            minDate={new Date()}
                            disabled={operationType === "update"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      {/* <Grid
                        ref={dateRef}
                        item
                        xs={6}
                        style={{ paddingRight: 5 }}
                      >
                        <CreateInput
                          inputLabe={`${
                            type === "webinar" ? "Webinar" : "Class"
                          } Date ${
                            operationType === "update"
                              ? "(cannot be updated)"
                              : ""
                          }`}
                          inputType="date"
                          value={webinarDateLabel}
                          disabled={operationType === "update"}
                          onChange={(event) => {
                            let newDateString;
                            let yearMonthDate = event.target.value
                              .split("-")
                              .map((input, i) => {
                                if (i === 1) {
                                  return +input - 1;
                                }
                                return +input;
                              });
                            if (webinarTime) {
                              console.log("webinarTime", webinarTime);
                              newDateString = new Date(
                                ...yearMonthDate,
                                ...webinarTime.split(":")
                              ).toISOString();
                              setWebinarDate();
                            } else
                              newDateString = new Date(
                                event.target.value
                              ).toISOString();
                            setWebinarDate(newDateString);
                            setWebinarDateLabel(event.target.value.trim());
                          }}
                        />
                      </Grid> */}

                      {/* <Grid item xs={6} style={{ paddingRight: 5 }}>
                        <CreateInput
                          inputLabe={`${
                            type === "webinar" ? "Webinar" : "Class"
                          } Time ${
                            operationType === "update"
                              ? "(cannot be updated)"
                              : ""
                          } `}
                          value={webinarTimeLabel}
                          inputType="time"
                          disabled={operationType === "update"}
                          onChange={(event) => {
                            if (webinarDateLabel) {
                              let yearMonthDate = webinarDateLabel
                                .split("-")
                                .map((input, i) => {
                                  if (i === 1) {
                                    return +input - 1;
                                  }
                                  return +input;
                                });
                              let newDateString = new Date(
                                ...yearMonthDate,
                                ...event.target.value.split(":")
                              ).toISOString();
                              setWebinarDate();
                              setWebinarDate(newDateString);
                              setWebinarTimeLabel(event.target.value);
                            }
                            setWebinarTime(event.target.value);
                          }}
                        />
                      </Grid> */}
                      <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        <CreateInput
                          inputLabe="Number of Slots"
                          inputType="number"
                          placeholder="Type number of slots available"
                          value={numSlots}
                          min={0}
                          onChange={(event) => {
                            setNumSlots(event.target.value.trim());
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        <CreateInput
                          inputLabe="Hosted by"
                          placeholder="Type host name/names"
                          value={hostName}
                          min={0}
                          onChange={(event) => {
                            setHostName(event.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {type !== "pageItem" && (
                <Grid container className={styles.timeBox}>
                  <Grid item xs={6} style={{ paddingRight: 5 }}>
                    <CreateInput
                      inputLabe="Time in Hours"
                      inputType="number"
                      placeholder="Type hours"
                      min={0}
                      value={hours}
                      onKeyUp={(e) => {
                        console.log(e);
                        if (e.key === ".") {
                          setHours("");
                          return CustomizeToastError(
                            "Please enter round numbers"
                          );
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value?.includes(".")) {
                          return;
                        }
                        setHours(e.target.value.trim());
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 5 }}>
                    <CreateInput
                      inputLabe="Time In Minutes"
                      inputType="number"
                      placeholder="Type minutes"
                      value={minutes}
                      min={0}
                      max={59}
                      onKeyUp={(e) => {
                        if (e.key === ".") {
                          setMinutes("");
                          return CustomizeToastError(
                            "Please enter round numbers"
                          );
                        }
                        if (
                          +e.target.value > 59 ||
                          +e.target.value < 0 ||
                          e.key === "-"
                        ) {
                          e.target.value = "";
                          CustomizeToastError(
                            "Minutes value must be between 0 and 59"
                          );
                        }
                      }}
                      onChange={(event) => {
                        if (event.target.value?.includes(".")) {
                          return;
                        }
                        if (event.target.value?.includes("-") === false) {
                          setMinutes(event.target.value.trim());
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {type === "talk" && (
                <Grid item xs={12}>
                  <CreateInput
                    inputLabe="Youtube Link"
                    inputType="text"
                    placeholder="Type Youtube Link"
                    value={contentLink}
                    onChange={(event) => {
                      setContentLink(event.target.value.trim());
                    }}
                  />
                </Grid>
              )}
              {type === "class" && (
                <Grid item xs={6}>
                  <CreateInput
                    inputLabe="Price"
                    inputType="number"
                    placeholder="Type price"
                    value={price}
                    min={0}
                    onChange={(event) => {
                      setPrice(event.target.value.trim());
                    }}
                  />
                </Grid>
              )}
              {type === "course" && (
                <Grid container className={styles.timeBox}>
                  <Grid item xs={6} style={{ paddingRight: 5 }}>
                    <CreateInput
                      inputLabe="Expires On"
                      inputType="date"
                      value={expiresOnLabel}
                      onChange={(event) => {
                        console.log(event.target.value);
                        let isoString = new Date(
                          event.target.value
                        ).toISOString();
                        setExpiresOn(isoString);
                        setExpiresOnLabel(event.target.value.trim());
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 5 }}>
                    <CreateInput
                      inputLabe="Price"
                      inputType="number"
                      placeholder="Type price"
                      value={price}
                      min={0}
                      onChange={(event) => {
                        setPrice(event.target.value.trim());
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: 10, paddingRight: 5 }}>
                    <Box flex alignItems="baseline">
                      <label
                        className={styles.customInputLabel}
                        htmlFor="isScorm_check"
                      >
                        isScorm
                      </label>
                      <Checkbox
                        checked={isScorm}
                        onChange={onScormCheckChange}
                        id="isScorm_check"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      visibility: isScorm ? "visible" : "hidden",
                      paddingRight: 5,
                    }}
                  >
                    <CreateInput
                      inputLabe="Scorm Id"
                      list="browsers"
                      inputType="text"
                      placeholder="Type scormid"
                      value={scormId}
                      onChange={(event) => {
                        setScormId(event.target.value.trim());
                      }}
                    />

                    <datalist id="browsers">
                      {scormData &&
                        scormData?.courses?.map((item) => {
                          return <option value={item.id}>{item.title}</option>;
                        })}
                    </datalist>
                    <CreateInput
                      inputLabe="Total modules"
                      inputType="number"
                      placeholder="Type total modules"
                      value={totalModules}
                      onChange={(event) => {
                        setTotalModules(event.target.value.trim());
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Box display="flex" justifyContent="center">
                <Box mx={3} width="40%" mt={4}>
                  <CustomButton
                    buttonType="outlined"
                    buttonColor="primary"
                    buttonText="Cancel"
                    customStyle={{ borderRadius: 40 }}
                    clickHandler={() => {
                      history.goBack();
                    }}
                    dynamicText
                  />
                </Box>
                <Box mx={3} width="40%" mt={4}>
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    buttonText="Save"
                    customStyle={{ borderRadius: 40 }}
                    clickHandler={saveHandler}
                    ajaxLoader={loaderState}
                    dynamicText
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default CreateSubContent;
