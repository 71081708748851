import { handleRes } from "./ApiHandler";
import axios from "axios";

export const Post_API = (
  url,
  data,
  callback,
  tokenRequired = true,
  formDataFlag = false
) => {
  console.log(callback, url, data);
  return handleRes("post", url, data, callback, tokenRequired, formDataFlag);
};
export const Post_API_With_Token = (url, data, callback, token) => {
  return handleRes("post", url, data, callback, true, "", true, token);
};

export const Get_API = (url, data, callback, tokenRequired = true) => {
  return handleRes("get", url, data, callback, tokenRequired);
};

export const Get_API_With_Token = (url, data, callback, token) => {
  return handleRes("get", url, data, callback, true, "", true, token);
};

export const Put_API = (url, data, callback, tokenRequired = true) => {
  return handleRes("put", url, data, callback, tokenRequired);
};
export const Delete_API = (url, data, callback, tokenRequired = true) => {
  return handleRes("delete", url, data, callback, tokenRequired);
};

export const Get_Page_Alt = async (url, data, localKey, token) => {
  // try {
  let res = await axios({
    method: "GET",
    url: url,
    params: data,
    headers: { Authorization: token },
  });
  return { localKey, data: res.data };
  // } catch(error) { }
};

export const Get_Page = (url, data, callback, token) => {
  axios({
    method: "GET",
    url: url,
    params: data,
    headers: { Authorization: token },
  })
    .then(function (response) {
      console.log("loggggggggggg", response);
      return callback({ data: response.data });
    })
    .catch((error) => {
      console.log("loggggggggggg", error);

      return callback({ data: { success: false, message: "" + error } });
    });
};
