import { URL } from "./server.js";

const endPoints = {
  signup: URL + "/users/signup",
  verify: URL + "/users/verify",
  userRegenerate: URL + "/users/regenerate",
  login: URL + "/users/login",
  forgotPassword: URL + "/user/login/forgotPassword",
  resendOtp: URL + "users/resendOTP",
  entry: URL + "/journals/entry",
  entrySearch: URL + "/journals/entry/search",
  categories: URL + "/recommendations/categories",
  profile: URL + "/profiles",
  profileOwner: URL + "/profiles/owner",
  subscribeCourse: URL + "/learnings/course/subscribe",
  // job: "http://localhost:8089" + "/jobs/save",
  job: URL + "/jobs/save",
  allCourses: URL + "/learnings/owner/",
  addModule: URL + "/learnings/module/add",
  updateModule: URL + "/learnings/module/update",
  getModule: URL + "/learnings/modules/",
  usersWTB: URL + "/users/wtb",
  profileChild: URL + "/profiles/child",
  profilePicture: URL + "/profiles/picture",
  recommendations: URL + "/recommendations",
  journalOwner: URL + "/journals/owner",
  journalEntry: URL + "/journals/entry",
  journalBuildReport: URL + "/journals/buildReport/",
  journalSendReport: URL + "/journals/sendReport/",
  // pagesUrl: "http://localhost:8091" + "/contenthub/page/",
  pagesUrl: URL + "/contenthub/page/",
  authCourses: URL + "/cms/progress?subscriptionId=",
  allAuthCourses: URL + "/contenthub/learning",
  // allAuthCourses: "http://localhost:8091" + "/contenthub/learning",
  courses: URL + "/contenthub/courses",
  resources: URL + "/contenthub/resources",
  podcasts: URL + "/contenthub/podcasts",
  talks: URL + "/contenthub/talks",
  classes: URL + "/contenthub/classes",
  isFavCourses: URL + "/cms/pages?page=all_courses&userid=",
  firebaseSubscribe: URL + "/notifications/subscribe",
  firebaseUnSubscribe: URL + "/notifications/unsubscribe",
  saveNotificationToken: URL + "/notifications/savetoken",
  course: URL + "/contenthub/course/",
  // course: "http://localhost:8091" + "/contenthub/course/",
  allNotification: URL + "/notifications/messages/",
  watchNotification: URL + "/notifications/updatewatched",
  unSubscribeLearning: URL + "/learnings/course/unsubscribe",
  unSubscribeResourcePodcast: URL + "/learnings/content/unsubscribe",
  createCourse: URL + "/contenthub/content",
  deleteContent: URL + "/contenthub/content/delete",
  // createCourse: "http://localhost:8091" + "/contenthub/content",
  // deleteContent: "http://localhost:8091" + "/contenthub/content/delete",

  scormCall: "https://cloud.scorm.com/api/v2/courses?appId=K05F7L12FL",
  paymentUrl: URL + "/learnings/payments/",
  directPayment: URL + "/learnings/course/buy",
};

export const imgUrl = "https://teamteach.s3.ap-south-1.amazonaws.com/";

export const apiEndPoints = {
  ...endPoints,
};
