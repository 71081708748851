import { Button, CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const CustomButton = ({
  buttonType,
  customStyle,
  clickHandler,
  customClass,
  buttonColor,
  href,
  ...props
}) => {
  const ButtonComponent = withStyles({
    root: {
      fontWeight: 400,
      borderRadius: 8,
      textTransform: "unset",
      width: "100%",
      height: 38,
    },
  })(Button);

  return (
    <ButtonComponent
      {...props}
      variant={buttonType === "outlined" ? "outlined" : "contained"}
      style={customStyle}
      onClick={clickHandler ? clickHandler : null}
      color={buttonColor === "primary" ? "primary" : "secondary"}
      className={customClass}
      href={href ? href : null}
    >
      {props.ajaxLoader && (
        <CircularProgress style={{ color: "#fff", height: 21, width: 21 }} />
      )}

      {props.ajaxLoader && props.dynamicText ? "" : props.buttonText}
    </ButtonComponent>
  );
};

export default CustomButton;
