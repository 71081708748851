import { SET_PROFILE } from "../../actionTypes";

const setProfileAction = (dispatch, responseObject) => {
  dispatch({
    type: SET_PROFILE,
    payload: {
      ...responseObject,
    },
  });
};

export default setProfileAction;
