import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import h2p from "html2plaintext";
import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import QueryModal from "../../../../components/QueryModal";
import getCardRoute from "../../../../utils/getCardRoute";
import useAuthoring from "../hooks/useAuthoring";
import useLearning from "../hooks/useLearning";
import "./carousel.css";
import makeStyle from "./styles";

const CarouseItem = ({ card }) => {
  const styles = makeStyle();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const { subscribeContent, unSubscribeContent } = useLearning();
  const { deleteModalFlag, deleteContent, openDeleteModal, closeDeleteModal } =
    useAuthoring();

  return (
    <Card className={styles.cardroot1}>
      {global_data.isEditable && (
        <>
          <IconButton
            onClick={() => {
              history.push(`/update/${card.type}/${card.contentId}/${card.id}`);
            }}
          >
            <CreateIcon style={{ fontSize: 21 }} />
          </IconButton>
          <IconButton
            onClick={() => {
              openDeleteModal(card);
            }}
          >
            <DeleteIcon style={{ fontSize: 21 }} />
          </IconButton>
          <QueryModal
            modalState={deleteModalFlag}
            queryText="Are you sure you want to delete this ?"
            yesText="Yes"
            closeText="No"
            onYes={() => {
              deleteContent();
            }}
            onClose={() => {
              closeDeleteModal();
            }}
          />
        </>
      )}
      <CardActionArea>
        <RouterLink
          style={{ textDecoration: "none" }}
          to={global_data.id ? getCardRoute(card) : `/login`}
        >
          <Box
            fontSize={{ xs: 12, md: 15 }}
            lineHeight={1.1}
            className={styles.headingHeight}
            fontWeight={600}
            fontFamily="poppins"
            overflow="hidden"
            mb={1}
            mt={1}
          >
            {card?.heading}
          </Box>

          <CardMedia
            className={styles.media1}
            image={card.image}
            title={card?.heading}
          />
        </RouterLink>
        <CardContent style={{ padding: "5px", cursor: "auto" }}>
          <Box
            lineHeight={1.1}
            color="customGrey.dark"
            fontFamily="poppins"
            height="5vw"
            className={styles.description}
            mb={1}
            dangerouslySetInnerHTML={{
              __html: `${
                card?.description?.length > 83
                  ? h2p(card?.description)?.slice(0, 82) + "..."
                  : card?.description || ""
              }`,
            }}
          />
        </CardContent>
      </CardActionArea>
      {
        <CardActions>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexDirection="row"
          >
            <Box className={styles.cardOptions}>
              {card.durationHours > 0 ? card.durationHours + " hours " : ""}
              {card.durationMinutes > 0
                ? card.durationMinutes + " minutes"
                : ""}
            </Box>
            {global_data.id ? (
              (card.type === "talks" && card?.isMyLearning) ||
              (card.type !== "talks" && card?.status > 0) ? (
                <IconButton
                  onClick={() => {
                    unSubscribeContent(card);
                  }}
                  style={{ padding: 5 }}
                >
                  <FavoriteIcon
                    style={{
                      color: "#DD2979",
                      fontSize: 16,
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => subscribeContent(card)}
                  style={{ padding: 5 }}
                >
                  <FavoriteBorderIcon
                    style={{
                      fontSize: 16,
                      color: "#75A8F4",
                    }}
                  />
                </IconButton>
              )
            ) : null}
          </Box>
        </CardActions>
      }
    </Card>
  );
};

export default CarouseItem;
