import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  capitalize,
  CircularProgress,
  Grid,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BookIcon from "@material-ui/icons/Book";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteIcon from "@material-ui/icons/Favorite";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const makeStyle = makeStyles((theme) => ({
  image: {
    width: theme.spacing(30),
    height: theme.spacing(26),
    margin: theme.spacing(2),
  },

  media: {
    height: 230,
    width: "100%",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.25),
    "@media (max-width:800px)": {
      height: 200,
    },
    [theme.breakpoints.down("sm")]: {
      height: 140,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      marginTop: theme.spacing(1),
    },
    cursor: "pointer",
  },

  cardrootinner: {
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2.25),
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1.25),
      padding: theme.spacing(1),
    },
  },
  cardrootinnerIcon: {
    fontSize: theme.spacing(1.75),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontWeight: "bold",
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.9),
    },
  },
  cardrootinnerIcon11: {
    fontSize: theme.spacing(1.75),
    marginLeft: theme.spacing(0.5),
    paddingRight: "6%",
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.5),
    },
  },

  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: theme.spacing(1.75),
    color: theme.palette.paper,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },

    "@media (max-width:800px)": {
      fontSize: ".8rem",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "@media (max-width:500px)": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  cardrootinnerButton1: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    fontSize: "1rem",
    color: theme.palette.paper,
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      fontSize: ".8rem",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "@media (max-width:500px)": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  cardIcon: {
    fontSize: theme.spacing(2),
    color: theme.palette.blue.main,
    marginLeft: theme.spacing(0.27),
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1),
    },
  },

  ImageGrid: {
    order: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  detailGrid: {
    order: 1,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
}));

const CourseItem = ({
  course,
  courseOpen,
  clickCourseLikeButton,
  paymentInProgress,
  selectedCourse,
  openDeleteModal,
  paymentCall,
  directPaymentCall,
}) => {
  const styles = makeStyle();
  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Grid container className={styles.cardrootinner}>
        <Box pl={0.5} style={{ width: "100%" }}>
          <Grid container>
            <Grid item sm={10} md={6}>
              <Typography
                onClick={() => courseOpen(course)}
                variant="h5"
                color="textPrimary"
                component="h4"
                className={styles.courseTitle}
              >
                {course?.heading ? course?.heading : course?.title}
              </Typography>
            </Grid>
            <Grid item sm={2} md={6} style={{ textAlign: "right" }}>
              <Box
                ml={2}
                py={0.4}
                color="primary"
                component="span"
                className={styles.cardrootinnerButton}
              >
                {!course?.price || course?.price === "0"
                  ? "Free"
                  : "₤ " + course?.price}
              </Box>
            </Grid>
          </Grid>
          <Grid container style={{ display: "flex" }}>
            <Grid className={styles.detailGrid} item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  {course.totalModules && (
                    <IconButton aria-label="add to favorites">
                      <BookIcon
                        className={styles.cardIcon}
                        style={{
                          fontSize: 22,
                        }}
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={styles.cardrootinnerIcon}
                      >
                        {course.totalModules
                          ? course.totalModules +
                            ` Module${course.totalModules === 1 ? "" : "s"}`
                          : ""}
                      </Typography>
                    </IconButton>
                  )}
                  <IconButton aria-label="add to favorites">
                    {course?.durationHours !== 0 ||
                    course?.durationMinutes !== 0 ? (
                      <AccessTimeIcon
                        className={styles.cardIcon}
                        style={{
                          fontSize: 22,
                        }}
                      />
                    ) : null}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={styles.cardrootinnerIcon}
                    >
                      {course?.durationHours !== 0
                        ? `${course?.durationHours} Hours `
                        : null}
                      {course?.durationMinutes !== 0
                        ? `${course?.durationMinutes} Minutes`
                        : null}
                    </Typography>
                  </IconButton>
                  {(global_data.token && course?.isMyLearning === false && (
                    <IconButton onClick={() => clickCourseLikeButton(course)}>
                      <Icon
                        className={styles.cardIcon}
                        style={{
                          fontSize: 22,
                          marginLeft: "0.2rem",
                        }}
                      >
                        favorite_border
                      </Icon>
                    </IconButton>
                  )) ||
                    (global_data.token && (
                      <IconButton onClick={() => clickCourseLikeButton(course)}>
                        <FavoriteIcon
                          style={{
                            color: "#eb349b",
                            fontSize: 20,
                          }}
                        />
                      </IconButton>
                    ))}

                  {paymentInProgress && selectedCourse.id == course.id && (
                    <Box py={1.5}>
                      <CircularProgress
                        style={{
                          color: "#75A8F4",
                          height: 21,
                          width: 21,
                        }}
                      />
                    </Box>
                  )}

                  {global_data.isEditable && (
                    <>
                      <IconButton
                        onClick={() => {
                          history.push(
                            `/update/${course.type}/courses/${course.id}`
                          );
                        }}
                      >
                        <CreateIcon style={{ fontSize: 21 }} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          openDeleteModal(course);
                        }}
                      >
                        <DeleteIcon style={{ fontSize: 21 }} />
                      </IconButton>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="p"
                    style={{ marginTop: 10 }}
                    className={styles.cardrootinnerIcon11}
                    dangerouslySetInnerHTML={{
                      __html: course?.description,
                    }}
                  ></Typography>
                </Grid>
                <Grid item xs={12}>
                  {course?.needToBuy && (
                    <Button
                      onClick={() => {
                        if (!global_data.id) {
                          return history.push("/login");
                        }
                        course?.isMyLearning
                          ? directPaymentCall(course)
                          : paymentCall(course);
                      }}
                      className={styles.cardrootinnerButton}
                    >
                      Buy Course
                    </Button>
                  )}

                  {!course?.needToBuy && (
                    <Button
                      onClick={() => {
                        courseOpen(course);
                      }}
                      className={styles.cardrootinnerButton1}
                    >
                      Go to Course
                    </Button>
                  )}

                  {(course?.status === 2 || course?.status === 3) && (
                    <Button className={styles.cardrootinnerButton}>
                      Completed
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid className={styles.ImageGrid} item xs={12} sm={6}>
              <CardMedia
                onClick={() => courseOpen(course)}
                className={styles.media}
                image={course?.image}
                title={course?.heading}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CourseItem;
