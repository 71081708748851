import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page, Get_Page_Alt } from "../../../services/ApiServies";
import errorAction from "../../../store/actions/errorAction";
import keys from "../constantKeys";

const useTermsOfBusiness = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localTermsOfBusiness, setlocalTermsOfBusiness] = useSessionStorage(
    keys.TERMS_OF_BUSINESS,
    null
  );
  const history = useHistory();

  const fetchTermsOfBusiness = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const termsOfBusiness = res.data;
      sessionStorage.removeItem(keys.TERMS_OF_BUSINESS);
      setlocalTermsOfBusiness({
        termsOfBusiness,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "termsofbusiness",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localTermsOfBusiness === null) {
      fetchTermsOfBusiness();
    }
  }, []);

  return { ...localTermsOfBusiness, fetchTermsOfBusiness };
};

export default useTermsOfBusiness;
