import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSessionStorage } from "react-use";
import { Res } from "../..";
import { Get_Page } from "../../../services/ApiServies";
import keys from "../constantKeys";

const usePrivacyPolicy = () => {
  const global_data = useSelector((state) => state.commonReducer);
  const [localPrivacyPolicy, setLocalPrivacyPolicy] = useSessionStorage(
    keys.PRIVACY_POLICY,
    null
  );
  const history = useHistory();

  const fetchPrivacyPolicy = async (fromCreatePage = false) => {
    const getPageSuccess = (res) => {
      const privacyPolicy = res.data;
      sessionStorage.removeItem(keys.PRIVACY_POLICY);
      setLocalPrivacyPolicy({
        privacyPolicy,
      });
      if (fromCreatePage) history.goBack();
    };
    Get_Page(
      Res.apiEndPoints.pagesUrl + "privacypolicy",
      {},
      getPageSuccess,
      global_data?.token
    );
  };

  useEffect(() => {
    if (localPrivacyPolicy === null) {
      fetchPrivacyPolicy();
    }
  }, []);

  return { ...localPrivacyPolicy, fetchPrivacyPolicy };
};

export default usePrivacyPolicy;
