import "@brainhubeu/react-carousel/lib/style.css";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/header/Header";
import { Get_API, Get_Page, Post_API } from "../../../services/ApiServies";
import { Res } from "../../../resources";
import CreateInput from "../../../components/CreateInput";

import Select from "@material-ui/core/Select";
import CustomButton from "../../../components/CustomButton";

import { GLOBAL_DATA } from "../../../store/actionTypes";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(8.625),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  maincontainer: {
    padding: theme.spacing(5),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
    },
  },

  borderclassBottom: {
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1.25),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(4),

    "@media (max-width:800px)": {
      width: "100%",
    },
  },

  media: {
    height: 200,
    width: 250,
    objectFit: "contain",
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(1.25),
    "@media (max-width:800px)": {
      height: 170,
    },
  },

  cardrootinnerFont: {
    fontSize: theme.spacing(1.8),
    margin: theme.spacing(3),
    marginLeft: 0,
    "@media (max-width:800px)": {
      fontSize: theme.spacing(1.11),
    },
  },

  cardrootinnerButton: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(5),
    color: theme.palette.paper,
    marginBottom: theme.spacing(2),
    padding: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.green.dark,
    },
    "@media (max-width:800px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  inputBox: {
    marginBottom: theme.spacing(1),
  },

  pageTitle: {
    paddingBottom: theme.spacing(4.25),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0),
    color: "#0D2860",
    textTransform: "capitalize",
  },
  input: {
    display: "none",
  },
  customInputLabel: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.dark.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
  },
  filePickerBox: {
    display: "flex",
    flexDirection: "row",
  },
  fileSelector: {
    backgroundColor: theme.palette.paper,
    borderRadius: theme.spacing(0.5),
    border: "1px solid",
    borderColor: theme.palette.customGrey.main,
    paddingLeft: 10,
    fontSize: theme.spacing(2),
    paddingTop: 1,
    paddingBottom: 1,
    width: "100%",
  },
  timeBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  imageContainer: {
    border: "1px solid",
    borderColor: theme.palette.customGrey.main,
    display: "flex",
    justifyContent: "center",
    borderRadius: theme.spacing(0.4),
    marginBottom: theme.spacing(1),
  },
}));

function Payment() {
  const global_data = useSelector((state) => state.commonReducer);
  const { type, parentId, contentId } = useParams();
  const styles = makeStyle();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [fileType, setFileType] = useState("video");

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("/images/contentImg.jpg");
  const [loaderState, setLoaderState] = useState(false);
  const [isScorm, setIsScorm] = useState(false);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (!global_data.token) {
      history.push("/login");
    }
  }, []);

  function selectFileType(e) {
    setFileType(e.target.value);
  }
  function onSelectImage(e) {
    const reader = new FileReader();
    setImage(e.target.files[0]);
    reader.onload = () => {
      console.log(reader);
      if (reader?.readyState == 2) {
        setImagePreview(reader?.result);
      }
    };
    reader?.readAsDataURL(e.target.files[0]);
  }

  const saveHandler = () => {
    setLoaderState(true);
  };
  const onScormCheckChange = (e) => {
    setIsScorm(e.target.checked);
  };
  return (
    <div className={styles.pageContainer}>
      <Header />
      <Container style={{ maxWidth: 1130 }}>
        <Box className={styles.maincontainer}>
          <Typography variant="h4" component="h2" className={styles.pageTitle}>
            Payment
          </Typography>

          <Box className={styles.inputBox}>
            <CreateInput
              inputLabe="Title"
              inputType="text"
              placeholder="Type title"
              value={heading}
              onChange={(event) => {
                setHeading(event.target.value.trim());
              }}
            />
          </Box>

          <Box className={styles.inputBox}>
            <CreateInput
              inputLabe="Heading"
              inputType="text"
              placeholder="Type a title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Box>

          <Box className={styles.inputBox}>
            <CreateInput
              inputLabe="Description"
              inputType="textarea"
              placeholder="Type a Description"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Box>
          <Box>
            <Box pb={0.5} className={styles.customInputLabel}>
              Image
            </Box>
            <Box className={styles.imageContainer}>
              <input
                accept="image/*"
                className={styles.input}
                id="imagePicker"
                multiple
                type="file"
                onChange={onSelectImage}
              />
              <img className={styles.media} src={imagePreview} title="" />
            </Box>
            <Box mb={2}>
              <label htmlFor="imagePicker">
                <Button
                  style={{ fontSize: 10, marginRight: 10 }}
                  variant="outlined"
                  color="primary"
                  component="span"
                >
                  Upload
                </Button>
              </label>
              <Button
                style={{ fontSize: 10 }}
                variant="outlined"
                color="primary"
                component="span"
              >
                Delete
              </Button>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box mx={3} width="40%" mt={4}>
              <CustomButton
                buttonType="outlined"
                buttonColor="primary"
                buttonText="Cancel"
                customStyle={{ borderRadius: 40 }}
                clickHandler={() => {
                  history.goBack();
                }}
                dynamicText
              />
            </Box>
            <Box mx={3} width="40%" mt={4}>
              <CustomButton
                buttonType="contained"
                buttonColor="primary"
                buttonText="Save"
                customStyle={{ borderRadius: 40 }}
                clickHandler={saveHandler}
                ajaxLoader={loaderState}
                dynamicText
              />
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Payment;
