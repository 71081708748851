import { GLOBAL_DATA } from "../../actionTypes";

const manipulateContentPackageAction = (
  dispatch,
  data,
  res,
  global_data,
  manipulationType,
  { afterPayment = false, justPayment = false } = {}
) => {
  console.log("pathhhhhh   2");

  let field;
  if (data.type === "course") {
    field = "allCourses";
  }
  if (data.type === "talks") {
    field = "allTalks";
  }

  if (Array.isArray(global_data[field])) {
    let packageList, indexToUpdate;
    if (manipulationType === "delete") {
      packageList = global_data[field].filter((item) => item.id !== data.id);
    } else {
      indexToUpdate = global_data[field].findIndex(
        (item) => item.id === data.id
      );
      packageList = [...global_data[field]];
      if (manipulationType === "subscribe" && !justPayment) {
        packageList[indexToUpdate].status = 0;
        packageList[indexToUpdate].isMyLearning = true;
        packageList[indexToUpdate].subscriptionId =
          res?.data?.object?.subscriptionId;
        if (afterPayment) {
          packageList[indexToUpdate].needToBuy = false;
        }
      } else if (manipulationType === "subscribe" && justPayment) {
        console.log("pathhhhhh   3");

        packageList[indexToUpdate].needToBuy = false;
      } else if (manipulationType === "unsubscribe") {
        packageList[indexToUpdate].status = -1;
        packageList[indexToUpdate].isMyLearning = false;
        packageList[indexToUpdate].subscriptionId = undefined;
      }
    }
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        [field]: packageList,
      },
    });
    return packageList;
  }
};

const subscribeContentPackageAction = (
  dispatch,
  data,
  res,
  global_data,
  { afterPayment = false, justPayment = false } = {}
) =>
  manipulateContentPackageAction(
    dispatch,
    data,
    res,
    global_data,
    "subscribe",
    { afterPayment, justPayment }
  );

const unSubscribeContentPackageAction = (dispatch, data, res, global_data) =>
  manipulateContentPackageAction(
    dispatch,
    data,
    res,
    global_data,
    "unsubscribe"
  );
const deleteContentPackageAction = (dispatch, data, res, global_data) =>
  manipulateContentPackageAction(dispatch, data, res, global_data, "delete");

export {
  subscribeContentPackageAction,
  unSubscribeContentPackageAction,
  deleteContentPackageAction,
};
